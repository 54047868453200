import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import cx from 'classnames';
import Logo from '../img/logo.jpeg';
import logoMexico from '../img/hecho-en-mexico.png';
import PropTypes from 'prop-types';

// Import actions
import * as AduanasActions from '../actions/aduanas-api/aduanas-api-action-creators';

// Import Utilities
import 'react-toastify/dist/ReactToastify.min.css';
import { css } from 'glamor';
import { CustomErrorComponent } from 'custom-error';
import { ToastContainer, toast, Flip } from 'react-toastify';
import { validate } from '../utilities/validates';
import * as ModalActionTypes from '../actions/modal-handling/modal-action-creators';
import { encodeURI, encrypt, vercionBrowser } from '../utilities/commons';

// Import Components
// import Input from '../components/global/input';
// import mainLogo from '../img/MVL-main-logo.jpg';
import FileViewer from 'react-file-viewer';
import Footer from '../components/global/footer';
import ListaReferenciasUsuario from '../components/global/listaReferenciasUsuario';
import Modal from '../components/global/modal';
import ModalPDF from '../components/global/modalPDF';

const mapStateToProps = state => ({
  dashboardDocs: state.dashboard.dashboardDocs,
  error: state.dashboard.error,
  errorDashboardDocs: state.dashboard.errorDashboardDocs,
  isOpenModal: state.modalReducer.isOpen,
  loadingDashboardDocs: state.dashboard.loadingDashboardDocs,
  msg: state.dashboard.msg,
  openList: state.dashboard.openList,
});

class App extends Component {
  constructor() {
    super();
    this.state = {
      content: undefined,
      pass: undefined,
      // referencia : undefined,
      rfc : undefined,
      valueREF: this.getParameterByName('ref'),
      valueRFC: this.getParameterByName('rfc'),
      view : false,
      viewPDF: undefined,
      openModalListaReferencias: false,
    };

    this.refContent = new createRef();
  }

  componentWillMount() {
    const { history } = this.props;
    const { valueRFC, valueREF } = this.state;

    const op = vercionBrowser();

    if ((op[0] === 'IE' || op[0] === 'Trident')) {
      // if (parseInt( op[1], 10 ) <= 7 ) {
      history.push('/download');
      // }
    }

    if (valueRFC !== '') {
      this.setState({
        rfc : validate(valueRFC),
      });
    }

    if (valueREF !== '') {
      this.setState({
        referencia : validate(valueREF),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { errorDashboardDocs, dashboardDocs, msg, error, openList } = nextProps;
    const { view } = this.state;

    if (view && !nextProps.isOpenModal) {
      this.setState({
        view : false,
      });
    }

    if (errorDashboardDocs && errorDashboardDocs.err) {
      this.notify(errorDashboardDocs.message, errorDashboardDocs.err);
    }

    if (dashboardDocs && dashboardDocs.message) {
      this.notify(dashboardDocs.message, dashboardDocs.err);
    }

    if (error && !openList) {
      this.notify(error.message, error.err);
    }

    if (msg) {
      this.notify(msg, 'warn');
    }
  }

  render() {
    const {
      props: {
        dashboardDocs,
        dispatch,
        isOpenModal,
        openList,
        // clientList,
      },
      state:{
        content,
        pass,
        // referencia,
        rfc,
        valueREF,
        valueRFC,
        view,
        viewPDF,
        openModalListaReferencias,
      },
    } = this;

    const validateRFC = cx({
      'formGroup' : true,
      'formGroup-disabled' : !rfc && rfc !== undefined,
    });

    const classBtn = cx({
      'buttonLogin' : true,
      'disabled' : !rfc /* || !referencia */ || !pass,
    });

    const validateReferencia = cx({
      'formGroup' : true,
      /* 'formGroup-disabled' : !referencia && referencia !== undefined, */
    });

    const validatePass = cx({
      'formGroup' : true,
      'formGroup-disabled' : !pass && pass !== undefined,
    });

    const classWrapper = cx({
      'loginMainSection-Wrapper': dashboardDocs && dashboardDocs.length === 0,
      'loginMainSection-Wrapper-center': dashboardDocs && dashboardDocs.length > 0,
    });

    return (
      <Fragment>
        <nav className='navbar navbar-expand navbar-light bg-MWL topbar static-top'>
          <div className='header-logo'>
            <img
              alt='logo'
              className='logo'
              src={ Logo } />
            <img
              alt='Hecho en México'
              className='logoMexico'
              src={ logoMexico } />
          </div>
        </nav>
        {
          viewPDF &&
          <ModalPDF
            closePDF = { () => this.closePDF() }
            component = { viewPDF }/>
        }
        {
          isOpenModal && view && content !== undefined &&
            <Modal
              dispatch = { dispatch }
              component = { content }/>
        }
        {
          isOpenModal && openModalListaReferencias && openList &&
            <Modal
              dispatch = { dispatch }
              component = { <ListaReferenciasUsuario/> } />
        }
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnVisibilityChange={true}
          draggable={true}
          pauseOnHover={true}
        />
        {/* Same as */}
        <ToastContainer />
        <div ref={this.refContent} className='user bg-MWL'>
          <div className='loginMainSection bg-MWL'>
            <div className={ classWrapper } /* className='loginWrapper border-left-red bg-white' */>
              <div className='loginContainer'>
                <div className='accessContainer'>
                  <div className='mainLogo'>
                    <form>
                      <div className={ validateRFC }>
                        <div className='userIcon'>
                          <span className='input-group-text'>
                            <i className='fas fa-user'></i>
                          </span>
                        </div>
                        <input
                          onChange={ () => this.validateFields('rfc') }
                          placeholder='RFC'
                          ref='rfc'
                          defaultValue={valueRFC}
                          type='text'
                        />
                      </div>
                      {
                        !rfc && rfc !== undefined &&
                          <span className='formGroup-error'>Campo Obligatorio</span>
                      }
                      <div className={ validateReferencia }>
                        <div className='userIcon'>
                          <span className='input-group-text'>
                            <i className='fas fa-briefcase'></i>
                          </span>
                        </div>
                        <input
                          // onChange={ () => this.validateFields('referencia') }
                          ref='referencia'
                          type='text'
                          defaultValue={valueREF}
                          placeholder='REFERENCIA'
                        />
                      </div>
                      <span className='formGroup-error'>Campo no obligatorio</span>
                      {
                        /* !referencia && referencia !== undefined &&
                          <span className='formGroup-error'>Campo Obligatorio</span> */
                      }
                      <div className={ validatePass }>
                        <div className='userIcon'>
                          <span className='input-group-text'>
                            <i className='fas fa-key'></i>
                          </span>
                        </div>
                        <input
                          onChange={ () => this.validateFields('pass') }
                          ref='pass'
                          type='password'
                          // defaultValue={valueREF}
                          placeholder='CONTRASEÑA'
                        />
                      </div>
                      {
                        !pass && pass !== undefined &&
                          <span className='formGroup-error'>Campo Obligatorio</span>
                      }
                      <div className='input-checkbox-wrapper'>
                        <input
                          className='input-checkbox'
                          type='checkbox'
                          ref='terminos-condiciones' />
                        <span>He leido y acepto el
                          <a href='https://mwl.com.mx/home/aviso-de-privacidad.html' target='_blank'> aviso de privacidad</a>
                        </span>
                      </div>
                      <div>
                        <button
                          onClick={ env => this.buscar(env) }
                          type='submit'
                          className={ classBtn }
                        >Buscar</button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='filesContainer'>
                  <div className='filesContainerBody'>
                    { this.renderDocumentsTable() }
                  </div>
                  {
                    dashboardDocs && dashboardDocs.length > 0 && this.refs.rfc.value && this.refs.pass.value &&
                    <div className='wrapperBtnReferences'>
                      <button
                        onClick={ () => this.listaReferencias() }
                        type='submit'
                        className='buttonMoreReferences'>Ver más referencias</button>
                    </div>
                  }
                </div>
              </div>
              <div className='contactInformation'>
                <p>Tel: +(52)(55) 29-26-70-11, +(52)(55) 12-22-92-10</p>
                <p>logistica@movilityworldlogistics.com,</p>
                <p>pricing@movilityworldlogistics.com</p>
                <p>Conoce nuestra <a href='https://mwl.com.mx/home/politica-de-calidad.html' target='_blank'>política de calidad</a></p>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </Fragment>
    );
  }

  closePDF = () => {
    this.setState({
      content: undefined,
      viewPDF: undefined,
      filesArr: undefined,
      view : false,
      typeFile: '',
    });
  }

  buscar = env => {
    const { dispatch } = this.props;
    env.preventDefault();

    if (this.refs.referencia.value !== '') {
      if (this.refs['terminos-condiciones'].checked) {
        const rfc = encodeURI(encrypt(this.refs.rfc.value));
        const reference = this.refs.referencia.value;
        const pass = encodeURI(encrypt(this.refs.pass.value));
        dispatch(AduanasActions.dashboardInfo(rfc, reference, pass));
      } else {
        this.notify('Debe de leer y aceptar el aviso de privacidad', 'warn');
      }
    } else {
      if (this.refs['terminos-condiciones'].checked) {
        this.listaReferencias();
      } else {
        this.notify('Debe de leer y aceptar el aviso de privacidad', 'warn');
      }
    }
  }

  listaReferencias = () => {
    const { dispatch } = this.props;

    const rfc = encodeURI(encrypt(this.refs.rfc.value));
    const pass = encodeURI(encrypt(this.refs.pass.value));

    dispatch(AduanasActions.listaReferenciasUsuario(rfc, pass));
    dispatch(ModalActionTypes.openModal(true));

    this.setState({
      openModalListaReferencias: true,
    });
  }

  renderDocumentsTable() {
    const { dashboardDocs } = this.props;
    const listaDocumentos = [];

    if ( dashboardDocs && dashboardDocs.length > 0 ) {
      dashboardDocs.map((documents, index) => {
        const objDocuments = {};
        objDocuments.id = index + 1;
        objDocuments.name = documents.name;
        // objDocuments.rfc = documents.rfc;
        // objDocuments.reference = documents.referenceName;
        objDocuments.option = ([
          <div className='iconGroupDocsTable' key={index}>
            <i
              className='fas fa-eye'
              title='Ver Documento'
              onClick={ () => this.preView(documents) }>
            </i>
            <i
              className='fas fa-download'
              title='Descargar Documento'
              onClick={ () => this.handleDownloadFile(documents) }>
            </i>
          </div>]);
        listaDocumentos.push(objDocuments);
      });
    }

    const data = {
      columns: [
        {
          label: '#',
          field: 'id',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Nombre',
          field: 'name',
          sort: 'asc',
          width: 270,
        },
        /* {
          label: 'RFC',
          field: 'rfc',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Referencia',
          field: 'reference',
          sort: 'asc',
          width: 270,
        }, */
        {
          label: 'Opciones',
          field: 'options',
          sort: 'disabled',
          width: '50px',
        },
      ],
      rows: listaDocumentos,
    };
    if ( dashboardDocs && dashboardDocs.length > 0 ) {
      return (
        <MDBDataTable
          searching={false}
          bordered={true}
          data={data}
          hover={true}
          responsive={true}
          info={false}
          small={true}
          striped={true}
          paging={false} />
      );
    }
  }

  handleDownloadFile(document) {
    const { dispatch } = this.props;
    dispatch(AduanasActions.download(document.path, document.name));
    const name = document.name;
    // window.open(`${window.location.origin.replace('3000', '3002')}/docs/download?name=${name}&pathDocument=${document.path}`);
    window.open(`https://${ window.location.hostname }/api/docs/download?name=${name}&pathDocument=${document.path}`);
  }

  cleanForm = () => {
    this.refs.user.refs.contenUser.value = '';
    this.refs.pass.refs.contentPass.value = '';
  }

  validateFields = (ref) => {
    const field = this.refs[ref].value;

    this.setState({
      [ref] :validate(field),
    });
  }

  getParameterByName = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  toastId = null;

  notify = (message, err) => {
    let type = 'success';
    let color = 'white';

    if ( err === 'warn') {
      type = err;
      color = 'black';
    } else if (err) {
      type = 'error';
    }

    if (! toast.isActive(this.toastId)) {
      this.toastId = toast[type](message, {
        position: 'top-right',
        className: css({
          color,
        }),
        bodyClassName: css({
          color,
        }),
        progressClassName: css({
          color,
        }),
        autoClose: 5000,
        transition: Flip,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  preView = file => {
    const { dispatch } = this.props;
    const { name, referenceName } = file;

    const content = name.split('.');

    const type = content[content.length - 1].toLowerCase();

    let component = undefined;
    let openModal = true;
    let viewPDF = undefined;

    const rfeference = referenceName;

    // const url = `${ window.location.origin.replace('3000', '3002') }/public/uploads/${ this.refs.rfc.value }/${ this.refs.referencia.value }/${ name }`;
    const url = `https://${ window.location.hostname }/api/public/uploads/${ this.refs.rfc.value }/${ rfeference }/${ name }`;

    if (type === 'docx') {
      // eslint-disable-next-line
        component = <FileViewer
        fileType={type}
        filePath={url}
        errorComponent={CustomErrorComponent}
        onError={this.onError}/>;
    } else if ( type === 'png' || type === 'jpeg' || type === 'jpg' || type === 'gif' || type === 'svg') {
      component = 
      // eslint-disable-next-line
        <div className='viewer'>
          <img
            src={url}
            alt={name}/>
        </div>;
    } else if (type === 'pdf') {
      openModal = false;
      /* openModal = false;
      window.open(url); */
      const urlPDF = this.refContent.current.offsetWidth <= 600 ? `http://docs.google.com/gview?url=${url}&embedded=true` : url;
      viewPDF = (<iframe
        style={{ height:'calc(100vh - 80px)', width:'90%' }}
        src={urlPDF}></iframe>);
    } else {
      component = 
      // eslint-disable-next-line
        <div className='viewer errorPreView'>
          {`El archivo ${ name } no se puede visualizar, solo se puede descargar.`}
        </div>;
    }

    this.setState({
      openModalListaReferencias: false,
      content: component,
      fileSelected: name,
      view: openModal,
      viewPDF,
      typeFile: type,
    });

    if (component !== undefined) {
      dispatch(ModalActionTypes.openModal(true));
    }
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(App);
