import { stringify } from 'query-string';
import SERVER_URL from '../constants/global';

const API = {

  loginAduanas(loginInfo) {
    const opts = {};
    return this._post('/auth/login', opts, loginInfo);
  },

  listUsers(token) {
    const opts = {};

    const headers = {
      'token': token,
    };

    return this._get('/users/listUser', opts, headers);
  },

  listaExtenciones(token) {
    const opts = {};

    const headers = {
      'token': token,
    };

    return this._get('/extensions/listExt', opts, headers);
  },

  actualizarUsuario(token, userInfo) {
    const headers = {
      'token': token,
    };

    const userObj = {
      id: userInfo.id,
      name: userInfo.name,
      email: userInfo.email,
      password: userInfo.password,
      rol: userInfo.rol,
      clientAdd: userInfo.clientAdd,
      clientDrop: userInfo.clientDrop,
      clientList: userInfo.clientList,
      clientUpdate: userInfo.clientUpdate,
      clientAssignment: userInfo.clientAssignment,
      extencionsAssignment: userInfo.extencionsAssignment,
      docAdd: userInfo.docAdd,
      docDrop: userInfo.docDrop,
      docList: userInfo.docList,
      docSend: userInfo.docSend,
      docDownload: userInfo.docDownload,
      docView: userInfo.docView,
      referenceAdd: userInfo.referenceAdd,
      referenceDrop: userInfo.referenceDrop,
      referenceList: userInfo.referenceList,
      referenceUpdate: userInfo.referenceUpdate,
      userAdd: userInfo.userAdd,
      userDrop: userInfo.userDrop,
      userList: userInfo.userList,
      userUpdate: userInfo.userUpdate,
      userClick: userInfo.userClick,
    };

    return this._post('/users/updateUser', headers, userObj);
  },

  eliminarUsuario(token, id) {
    const headers = {
      'token': token,
    };

    const clientObj = {
      id,
    };

    return this._post('/users/deleteUser', headers, clientObj);
  },

  listaClientes(token) {
    const opts = {};

    const headers = {
      'token': token,
    };

    return this._get('/clients/list', opts, headers);
  },

  eliminaCliente(token, clientID) {
    const headers = {
      'token': token,
    };

    const clientObj = {
      clientID: clientID,
    };

    return this._post('/clients/delete', headers, clientObj);
  },

  actualizaCliente(token, clientID, clientData) {
    const headers = {
      'token': token,
    };

    const clientObj = {
      clientID: clientData.clientID,
      rfc: clientData.rfc,
      razonSocial: clientData.razonSocial,
      email: clientData.email,
      telefono: clientData.telefono,
      direccion: clientData.direccion,
      cp: clientData.codigoPostal,
      password: clientData.password,
    };

    return this._post('/clients/update', headers, clientObj);
  },

  registraUsuario(token, userInfo) {
    const opts = {
      'token': token,
    };
    
    const userObj = {
      name: userInfo.name,
      email: userInfo.email,
      password: userInfo.password,
      rol: userInfo.rol,
      clientAdd: userInfo.clientAdd,
      clientDrop: userInfo.clientDrop,
      clientList: userInfo.clientList,
      clientUpdate: userInfo.clientUpdate,
      clientAssignment: userInfo.clientAssignment,
      extencionsAssignment: userInfo.extencionsAssignment,
      docAdd: userInfo.docAdd,
      docDrop: userInfo.docDrop,
      docList: userInfo.docList,
      docSend: userInfo.docSend,
      docDownload: userInfo.docDownload,
      docView: userInfo.docView,
      referenceAdd: userInfo.referenceAdd,
      referenceDrop: userInfo.referenceDrop,
      referenceList: userInfo.referenceList,
      referenceUpdate: userInfo.referenceUpdate,
      userAdd: userInfo.userAdd,
      userDrop: userInfo.userDrop,
      userList: userInfo.userList,
      userUpdate: userInfo.userUpdate,
      userClick: userInfo.userClick,
    };

    return this._post('/users/addUser', opts, userObj);
  },

  registraCliente(token, userInfo) {
    const opts = {
      'token': token,
    };
    return this._post('/clients/signup', opts, userInfo);
  },

  registraExtencion(token, extemcionInfo) {
    const opts = {
      'token': token,
    };

    return this._post('/extensions/addExt', opts, extemcionInfo);
  },

  eliminaExtension(token, id) {
    const headers = {
      'token': token,
    };

    const extensionObj = {
      extensionID: id,
    };

    return this._post('/extensions/deleteExt', headers, extensionObj);
  },

  listaReferencias(token, clientID) {
    const opts = {
      clientID: clientID,
    };

    const headers = {
      'token': token,
    };

    return this._get('/reference/list', opts, headers);
  },

  listaTodasReferencias(token) {
    const opts = {
      // clientID: clientID,
    };

    const headers = {
      'token': token,
    };

    return this._get('/reference/all', opts, headers);
  },

  addReference(token, clientID, referenceInfo) {
    const opts = {
      'token': token,
    };

    const referenceObj = {
      clientID: clientID,
      referenceInfo: referenceInfo,
    };

    return this._post('/reference/add', opts, referenceObj);
  },

  eliminaReferencia(token, referenceID) {
    const headers = {
      'token': token,
    };

    const referenceObj = {
      referenceID: referenceID,
    };

    return this._post('/reference/delete', headers, referenceObj);
  },

  actualizaReferencia(token, referenceData) {
    const headers = {
      'token': token,
    };

    const referenceObj = {
      referenceID: referenceData.referenceID,
      name: referenceData.name,
      description: referenceData.description,
      clientID: referenceData.clientID,
    };

    return this._post('/reference/update', headers, referenceObj);
  },

  getReference(token, referenceID) {
    const opts = {
      referenceID: referenceID,
    };

    const headers = {
      'token': token,
    };

    return this._get('/reference/referenceInfo', opts, headers);
  },

  getClientInfo(token, clientID) {
    const opts = {
      clientID: clientID,
    };

    const headers = {
      'token': token,
    };

    return this._get('/clients/clientInfo', opts, headers);
  },

  addDocuments(token, files) {
    const opts = {
      'token': token,
    };

    return this._post('/docs/upload', opts, files, true);
  },

  listDocuments(token, clientID, referenceID) {
    const opts = {
      clientID: clientID,
      referenceID: referenceID,
    };

    const headers = {
      'token': token,
    };

    return this._get('/docs/documentsList', opts, headers);
  },

  deleteDocuments(token, obj) {
    const opts = {
      'token': token,
    };

    return this._post('/docs/deleteDocument', opts, obj);
  },

  download(pathDocument, name) {
    const opts = {
      pathDocument,
      name,
    };

    const headers = {
      pathDocument,
      name,
    };

    return this._get('/docs/download', opts, headers);
  },

  sendEmail(token, obj) {
    const opts = {
      'token': token,
    };

    return this._post('/email/sendEmail', opts, obj);
  },

  sendWhatsApp(token, obj) {
    const opts = {
      'token': token,
    };

    return this._post('/sms/sendWhatsApp', opts, obj);
  },

  dashboardInfo(rfc, reference, pass) {
    const opts = {};

    const dashboardObj = {
      rfc,
      reference,
      pass,
    };

    return this._post('/dashboard/find', opts, dashboardObj);
  },

  listaReferenciasUsuario(rfc, pass) {
    const opts = {};

    const dashboardObj = {
      rfc,
      pass,
    };

    return this._post('/dashboard/listReferences', opts, dashboardObj);
  },

  listaDocumentosUsuario(clientID, referenceID) {
    const opts = {};

    const dashboardObj = {
      clientID,
      referenceID,
    };

    return this._post('/dashboard/listDocumentos', opts, dashboardObj);
  },

  _get(endpoint, jsonDataGet, headers) {
    const apiCallPromise = new Promise((resolve, reject) => {
      fetch(`${SERVER_URL}${endpoint}?${stringify(jsonDataGet)}`, {
        method: 'GET',
        headers: {
          ...headers,
          'Access-Control-Allow-Origin':'*',
          'Content-Type': 'application/json',
        },
      }).then(res => res.json()).then(res => {
        if ( res && res.err ) {
          resolve(res);
        } else {
          resolve(res);
        }
      }).catch(error => {
        reject(error);
      });
    });

    return apiCallPromise;
  },

  _post(endpoint, opts, jsonData, multipart = false) {
    const apiCallPromise = new Promise((resolve, reject) => {
      const headersObj = {
        ...opts,
        'Access-Control-Allow-Origin':'*',
      };

      if ( !multipart ) {
        headersObj['Content-Type'] = 'application/json';
      }
      fetch(`${SERVER_URL}${endpoint}`, {
        method: 'POST',
        body: multipart ? jsonData : JSON.stringify(jsonData),
        headers: headersObj,
      }).then(res => res.json()).then(res => {
        if ( res && res.err ) {
          resolve(res);
        } else {
          resolve(res);
        }
      }).catch(error => {
        reject(error);
      });
    });

    return apiCallPromise;
  },


  /**
   * Generic PUT request.
   *
   * @param {string} endpoint - the endpoint name as identified by the API root
   * @param {object} opts - an options object, as described in the lance library docs
   * @param {object} jsonData - json object to be saved
   * @param {function} factoryFunction - a function to create a new ui model instance to be returned when promise resolves
   *
   * @return {Promise} - request promise
   */
  /* _put(endpoint, opts, jsonData, factoryFunction) {
    opts = addHeaders(opts);

    const apiCallPromise = new Promise((resolve, reject) => {
      getLance().initialize().then(api => {
        return api._lance.update(endpoint, opts, jsonData);
      }).then(lanceModel => {
        resolve(factoryFunction(lanceModel));
      }).catch(error => {
        reject(error);
      });
    });

    return apiCallPromise;
  },
}; */
};

export default API;
