import asyncActionTypes from './aduanas-api-action-types';

import API from '../../api-wrapper/api';

// Metodo para realizar login de usuarios
export function loginAduanas(loginInfo) {
  return {
    type: asyncActionTypes.ADUANAS_LOGIN,
    getPromise: () => API.loginAduanas(loginInfo),
    recreateSelf: () => loginAduanas(loginInfo),
  };
}

export function registraUsuario(token, userInfo) {
  return {
    type: asyncActionTypes.ADUANAS_REGISTRA_USER,
    getPromise: () => API.registraUsuario(token, userInfo),
    recreateSelf: () => registraUsuario(token, userInfo),
  };
}

/* MODULOS PARA Usuarios */

export function listUsers(token) {
  return {
    type: asyncActionTypes.ADUANAS_LISTA_USERS,
    getPromise: () => API.listUsers(token),
    recreateSelf: () => listUsers(token),
  };
}

export function listaExtenciones(token) {
  return {
    type: asyncActionTypes.ADUANAS_LISTA_EXTENCIONES,
    getPromise: () => API.listaExtenciones(token),
    recreateSelf: () => listaExtenciones(token),
  };
}

export function registraCliente(token, userInfo) {
  return {
    type: asyncActionTypes.ADUANAS_REGISTRA_CLIENTE,
    getPromise: () => API.registraCliente(token, userInfo),
    recreateSelf: () => registraCliente(token, userInfo),
  };
}

export function registraExtencion(token, extemcionInfo) {
  return {
    type: asyncActionTypes.ADUANAS_REGISTRA_EXTENCION,
    getPromise: () => API.registraExtencion(token, extemcionInfo),
    recreateSelf: () => registraExtencion(token, extemcionInfo),
  };
}

export function eliminaExtension(token, extensionID) {
  return {
    type: asyncActionTypes.ADUANAS_ELIMINA_EXTENSION,
    getPromise: () => API.eliminaExtension(token, extensionID),
    recreateSelf: () => eliminaExtension(token, extensionID),
  };
}

export function actualizarUsuario(token, clientID, clientData) {
  return {
    type: asyncActionTypes.ADUANAS_ACTUALIZA_USUARIO,
    getPromise: () => API.actualizarUsuario(token, clientID, clientData),
    recreateSelf: () => actualizarUsuario(token, clientID, clientData),
  };
}

export function eliminarUsuario(token, clientID) {
  return {
    type: asyncActionTypes.ADUANAS_ELIMINA_USUARIO,
    getPromise: () => API.eliminarUsuario(token, clientID),
    recreateSelf: () => eliminarUsuario(token, clientID),
  };
}

/* MODULOS PARA CLIENTES */
export function listaClientes(token) {
  return {
    type: asyncActionTypes.ADUANAS_LISTA_CLIENTES,
    getPromise: () => API.listaClientes(token),
    recreateSelf: () => listaClientes(token),
  };
}

export function eliminaCliente(token, clientID) {
  return {
    type: asyncActionTypes.ADUANAS_ELIMINA_CLIENTE,
    getPromise: () => API.eliminaCliente(token, clientID),
    recreateSelf: () => eliminaCliente(token, clientID),
  };
}

export function actualizaCliente(token, clientID, clientData) {
  return {
    type: asyncActionTypes.ADUANAS_ACTUALIZA_CLIENTE,
    getPromise: () => API.actualizaCliente(token, clientID, clientData),
    recreateSelf: () => actualizaCliente(token, clientID, clientData),
  };
}

export function getClientInfo( token, clientID ) {
  return {
    type: asyncActionTypes.ADUANAS_CLIENT_INFO,
    getPromise: () => API.getClientInfo(token, clientID),
    recreateSelf: () => getClientInfo(token, clientID),
  };
}

/* MODULOS PARA CLIENTES */

/* MODULOS PARA REFERENCIAS */

export function listaReferencias( token, clientID ) {
  return {
    type: asyncActionTypes.ADUANAS_LISTA_REFERENCIAS,
    getPromise: () => API.listaReferencias(token, clientID),
    recreateSelf: () => listaReferencias(token, clientID),
  };
}

export function listaTodasReferencias( token ) {
  return {
    type: asyncActionTypes.ADUANAS_LISTA_REFERENCIAS_TODAS,
    getPromise: () => API.listaTodasReferencias(token),
    recreateSelf: () => listaTodasReferencias(token),
  };
}

export function eliminaReferencia(token, referenceID) {
  return {
    type: asyncActionTypes.ADUANAS_ELIMINA_REFERENCIA,
    getPromise: () => API.eliminaReferencia(token, referenceID),
    recreateSelf: () => eliminaReferencia(token, referenceID),
  };
}

export function addReference( token, clientID, referenceInfo ) {
  return {
    type: asyncActionTypes.ADUANAS_ADD_REFERENCIA,
    getPromise: () => API.addReference(token, clientID, referenceInfo),
    recreateSelf: () => addReference(token, clientID, referenceInfo),
  };
}

export function getReference( token, referenceID ) {
  return {
    type: asyncActionTypes.ADUANAS_GET_REFERENCE,
    getPromise: () => API.getReference(token, referenceID),
    recreateSelf: () => getReference(token, referenceID),
  };
}

export function actualizaReferencia(token, referenceData) {
  return {
    type: asyncActionTypes.ADUANAS_ACTUALIZA_REFERENCIA,
    getPromise: () => API.actualizaReferencia(token, referenceData),
    recreateSelf: () => actualizaReferencia(token, referenceData),
  };
}

/* MODULOS PARA REFERENCIAS */

export function addDocuments( token, files ) {
  return {
    type: asyncActionTypes.ADUANAS_ADD_DOCUMENTS,
    getPromise: () => API.addDocuments(token, files),
    recreateSelf: () => addDocuments(token, files),
  };
}

export function listDocuments( token, clientID, referenceID ) {
  return {
    type: asyncActionTypes.ADUANAS_LIST_DOCUMENTS,
    getPromise: () => API.listDocuments(token, clientID, referenceID),
    recreateSelf: () => listDocuments(token, clientID, referenceID),
  };
}

export function deleteDocuments( token, obj ) {
  return {
    type: asyncActionTypes.ADUANAS_DELETE_DOCUMENTS,
    getPromise: () => API.deleteDocuments(token, obj),
    recreateSelf: () => deleteDocuments(token, obj),
  };
}

export function download( path, name ) {
  return {
    type: asyncActionTypes.ADUANAS_DOWNLOAD_DOCUMENTS,
    getPromise: () => API.download(path, name),
    recreateSelf: () => download(path, name),
  };
}

/* MODULO PARA CORREO */

export function sendEmail( token, data ) {
  return {
    type: asyncActionTypes.ADUANAS_SEND_EMAIL,
    getPromise: () => API.sendEmail(token, data),
    recreateSelf: () => sendEmail(token, data),
  };
}

export function sendWhatsApp( token, data ) {
  return {
    type: asyncActionTypes.ADUANAS_SEND_WHATSAPP,
    getPromise: () => API.sendWhatsApp(token, data),
    recreateSelf: () => sendWhatsApp(token, data),
  };
}

export function dashboardInfo(rfc, reference, pass) {
  return {
    type: asyncActionTypes.ADUANAS_DASHBOARD_INFO,
    getPromise: () => API.dashboardInfo(rfc, reference, pass),
    recreateSelf: () => dashboardInfo(rfc, reference, pass),
  };
}

export function listaReferenciasUsuario( rfc, pass ) {
  return {
    type: asyncActionTypes.ADUANAS_LISTA_REFERENCIAS_USUARIO,
    getPromise: () => API.listaReferenciasUsuario(rfc, pass),
    recreateSelf: () => listaReferenciasUsuario(rfc, pass),
  };
}

export function listaDocumentosUsuario( clientID, referenceID ) {
  return {
    type: asyncActionTypes.ADUANAS_DASHBOARD_LIST_DOCUMENS,
    getPromise: () => API.listaDocumentosUsuario(clientID, referenceID),
    recreateSelf: () => listaDocumentosUsuario(clientID, referenceID),
  };
}