import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import Router from './routing/index';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
// import store from './redux/store/index';
import store from './redux/create';

ReactDOM.render(
  <Provider store={store}>
    <Router />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
