// export const SERVER_URL = 'http://localh:3002';
let SERVER_URL = '';

if (window.location.hostname === 'localhost') {
  SERVER_URL = `https://${ window.location.hostname }/api`;
} else {
  SERVER_URL = `https://${ window.location.hostname }/api`;
}

export default SERVER_URL;
