import { resolvePromise } from './async-action-middleware';
import { resolveAllPromises } from './multiple-async-action-middleware';

/**
 * This middleware runs a chain of async actions in sequence.
 *
 * The action object MUST have:
 * - type: an async action type, ie. an object with START, SUCCESS and FAIL sub-types
 * - asyncActionChain: optional, a list of async action objects
 *
 * All individual async action START/SUCCESS/FAIL are propagated.
 * Chain SUCCESS is reached when the last action in the chain finishes.
 */
export default function asyncActionChainMiddleware(store) {
  return (next) => (action) => {
    const { asyncActionChain, type, ...rest } = action;
    if (!asyncActionChain || !(asyncActionChain instanceof Array)) {
      return next(action);
    }

    next({ ...rest, type: type.START });

    const consumeActionStack = (stack) => {
      const currentAsyncAction = stack.pop();
      let currentPromise = null;

      if (currentAsyncAction.asyncActions) {
        // If current action is a multipleAsyncAction object
        currentPromise = resolveAllPromises(currentAsyncAction, next);
      } else {
        currentPromise = resolvePromise(currentAsyncAction, next, store);
      }

      currentPromise
        .then(() => {
          if (stack.length > 0) {
            // Propagates result to the next action in the chain
            consumeActionStack(stack);
          } else {
            // Return last action we propagate with next
            return next({ type: type.SUCCESS });
          }
        });
    };

    // pop() takes the right-most element from the array. Array is reversed so it can work as a stack,
    // consuming actions from the right to the left.
    consumeActionStack(asyncActionChain.reverse());
  };
}
