import modalActionTypes from '../actions/modal-handling/modal-action-types';

const initialState = {
  isOpen: false,
};

const actionsMap = {
  [modalActionTypes.OPEN_MODAL]: (state, action) => {
    return {
      ...state,
      isOpen: action.action,
    };
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  if (!fn) {
    return state;
  }
  return fn(state, action);
}
