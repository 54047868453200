import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getCookie } from '../utilities/helpers';
import { encrypt, encodeURI } from '../utilities/commons';
import { validate, validateEmail } from '../utilities/validates';
import * as AduanasActions from '../actions/aduanas-api/aduanas-api-action-creators';
import { eventBtn } from '../actions/actions';
import cx from 'classnames';
import Header from '../components/global/header';
import PropTypes from 'prop-types';

const mapStateToProps = state => ({
  clientList: state.client.usersList,
  extencionList: state.extencions.extencionList,
  isLogin: state.authentication.isLogin,
  isUserCreated: state.user.isUserCreated,
  loadingSignup: state.authentication.loadingSignup,
  token: state.authentication.token,
  userInfo: state.authentication.userInfo,
});

class AddUser extends Component {
  constructor(props) {
    super(props);

    const formValues = {
      name: '',
      email: '',
      password: '',
      rol: '',
      clientAdd: false,
      clientDrop: false,
      clientList: false,
      clientUpdate: false,
      clientAll: false,
      clientAssignment: [],
      extencionsAssignment: [],
      docAdd: false,
      docDrop: false,
      docList: false,
      docSend: false,
      whatsAppSend: false,
      docDownload: false,
      docView: false,
      docViewAll: false,
      referenceAdd: false,
      referenceDrop: false,
      referenceList: false,
      referenceUpdate: false,
      referenceAll: false,
      userAdd: false,
      userClick: false,
      userDrop: false,
      userList: false,
      userUpdate: false,
      userAll: false,
      All: false,
    };

    this.state = {
      formValues: formValues,
      userInfo: getCookie('userSession'),
      validates: {
        nombre: '',
        email: '',
        password: '',
      },
      addClientSelected: '',
      removeClientSelected: '',
      addExtencionSelected: '',
      removeExtencionSelected: '',
    };
  }

  componentWillMount() {
    const { history, dispatch } = this.props;
    const { userInfo } = this.state;

    if ( userInfo === null ) {
      history.push('/');
      return;
    }

    dispatch(AduanasActions.listaClientes(userInfo.token));
    dispatch(AduanasActions.listaExtenciones(userInfo.token));
  }

  componentWillReceiveProps(nextProps) {
    const { isUserCreated, history } = this.props;
    if (isUserCreated !== nextProps.isUserCreated && nextProps.isUserCreated) {
      history.push('/list-users');
    }
  }

  render() {
    const {
      props: {
        dispatch,
      },
      state: {
        validates,
      },
    } = this;

    const ObjectValues = Object.values(validates);
    let classActive = undefined;

    ObjectValues.map( element => {
      if ( !element || element === '') {
        classActive = false;
      } else {
        classActive = true;
      }
    });

    const classBtn = cx({
      'buttonLogin' : true,
      'disabled' : !classActive,
    });

    return (
      <Fragment>
        <Header dispatch={ dispatch }/>
        <div className='loginMainSection bg-MWL'>
          <div className='addSection border-left-red bg-white mb-5'>
            <div className='addHeader'>
              <h3 className='loginHeader text-red title'>
                Agregar Usuario
              </h3>
            </div>
            <div className='addBody'>
              { this.renderAddClientForm() }
              <div className='addFooter'>
                <button
                  onClick = { this.cancelAddClient }
                  className='buttonLogin'>
                  Cancelar
                </button>
                <button
                  onClick = { this.sendClientForm }
                  className={ classBtn } >
                  Agregar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  addExtension = () => {
    const { history } = this.props;
    history.push('/list-extension/addUser');
  }

  renderAddClientForm() {
    const { clientList, extencionList } = this.props;
    const { validates, formValues, addClientSelected, removeClientSelected, addExtencionSelected, removeExtencionSelected, userInfo } = this.state;

    const formObj = [
      {
        text: 'Email',
        type: 'email',
        key:  'email',
        errorMessage: 'El correo debe tener el siguiente formato ejemplo@ejemplo',
      },
      {
        text: 'Nombre',
        type: 'text',
        key:  'name',
        errorMessage: 'El nombre no debe de estar bacio',
      },
      {
        text: 'Contraseña',
        type: 'password',
        key:  'password',
        errorMessage: 'La contraseña es requerida',
      },
    ];

    const filterclient = clientList.filter( selected => formValues.clientAssignment.every( client => selected.rfc !== client) );
    const filterExtencion = extencionList.filter( selected => formValues.extencionsAssignment.every( extension => selected.extension !== extension ));

    return (
      <div>
        <form autoComplete='off'>
          {
            formObj.map((obj, index)=> {
              const classInput = cx({
                'addformGroup-error-add' : !validates[obj.key] && validates[obj.key] !== '' && validates[obj.key] !== undefined,
              });

              return (
                <div className='addformGroupUser' key={index} >
                  <label className='text-dark'>
                    { obj.text }
                  </label> 
                  <input
                    className={ classInput }
                    type={ obj.type }
                    placeholder={ obj.text }
                    onBlur={ evt => this.validateField(evt, obj.key, obj.lengthField) }
                    onChange={ evt => this.updateInputValue(evt, obj.key) }/>
                  {
                    !validates[obj.key] && validates[obj.key] !== '' && validates[obj.key] !== undefined &&
                      <span className='formGroup-error'>
                        {
                          obj.errorMessage
                        }
                      </span>
                  }
                </div>
              );
            })
          }
          <div>
            <div className='wrap-checbox'>
              <input
                type='checkbox'
                checked={this.state.formValues.All}
                onChange={ this.checkAll }/>
              <label className='text-dark'>Todos los permisos existentes</label>
            </div>
            <div className='permisions'>
              <h4 className='text-red title'>Usuarios</h4>
              <div className='groupCheckBox'>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.userList}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'userList') }/>
                  <label className='text-dark'>Consultar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.userAdd}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'userAdd') }/>
                  <label className='text-dark'>Agregar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.userUpdate}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'userUpdate') }/>
                  <label className='text-dark'>Actualizar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.userDrop}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'userDrop') }/>
                  <label className='text-dark'>Eliminar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.userClick}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'userClick') }/>
                  <label className='text-dark'>Click Derecho</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    checked={this.state.formValues.userAll}
                    onChange={ this.usersAllOptions }
                    type='checkbox'/>
                  <label className='text-dark'>Todos</label>
                </div>
              </div>
            </div>
            <div className='permisions'>
              <h4 className='text-red title'>Clientes</h4>
              <div className='groupCheckBox'>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.clientList}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'clientList') }/>
                  <label className='text-dark'>Consultar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.clientAdd}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'clientAdd') }/>
                  <label className='text-dark'>Agregar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.clientUpdate}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'clientUpdate') }/>
                  <label className='text-dark'>Actualizar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.clientDrop}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'clientDrop') }/>
                  <label className='text-dark'>Eliminar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    checked={this.state.formValues.clientAll}
                    onChange={ this.clientAllOptions }
                    type='checkbox'/>
                  <label className='text-dark'>Todos</label>
                </div>
              </div>
            </div>
            {
              clientList.length &&
              <div className='permisionsClient'>
                <h4 className='text-red title'>Lista de clientes</h4>
                <div className='wraptitleListClient'>
                  <div className='titleListClient'>No asignados</div>
                  <div className='spaceTitleListClient'></div>
                  <div className='titleListClient'>Asignados</div>
                </div>
                <div className='wrappSelectClients'>
                  <div className='listClient'>
                    {
                      filterclient.map( client => {
                        const classClient = cx({
                          'optionClient': true,
                          'selectedClient': client.rfc === addClientSelected,
                        });
                        return (
                          <div
                            key={client.rfc}
                            onClick={ () => this.addClient(client.rfc) }
                            className={classClient}>{ client.rfc }</div>
                        );
                      })
                    }
                  </div>
                  <div className='optionsListClient'>
                    <input
                      type='button'
                      value='>>'
                      onClick = { this.assignmentAll }
                      className={`buttonLogin ${!filterclient.length && 'disabled'}`}/>
                    <input
                      type='button'
                      value='>'
                      onClick = { this.assignment }
                      className={`buttonLogin ${(!filterclient.length || addClientSelected === '') && 'disabled'}`}/>
                    <input
                      type='button'
                      value='<'
                      onClick = { this.removed }
                      className={`buttonLogin ${(!formValues.clientAssignment.length || removeClientSelected === '') && 'disabled'}`}/>
                    <input
                      type='button'
                      value='<<'
                      onClick = { this.removedAll }
                      className={`buttonLogin ${!formValues.clientAssignment.length && 'disabled'}`}/>
                  </div>
                  <div className='listClient'>
                    {
                      formValues.clientAssignment.map( assignment => {
                        const classClient = cx({
                          'optionClient': true,
                          'selectedClient': assignment === removeClientSelected,
                        });

                        return (
                          <div
                            key={assignment}
                            onClick={ () => this.removeClient(assignment) }
                            className={classClient}>{ assignment }</div> );
                      })
                    }
                  </div>
                </div>
              </div>
            }
            <div className='permisions'>
              <h4 className='text-red title'>Referencias</h4>
              <div className='groupCheckBox'>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.referenceList}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'referenceList') }/>
                  <label className='text-dark'>Consultar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.referenceAdd}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'referenceAdd') }/>
                  <label className='text-dark'>Agregar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.referenceUpdate}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'referenceUpdate') }/>
                  <label className='text-dark'>Actualizar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.referenceDrop}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'referenceDrop') }/>
                  <label className='text-dark'>Eliminar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    checked={this.state.formValues.referenceAll}
                    onChange={ this.referenceAllOptions }
                    type='checkbox'/>
                  <label className='text-dark'>Todos</label>
                </div>
              </div>
            </div>
            <div className='permisions'>
              <h4 className='text-red title'>Documentos</h4>
              <div className='groupCheckBox'>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.docList}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'docList') }/>
                  <label className='text-dark'>Consultar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.docAdd}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'docAdd') }/>
                  <label className='text-dark'>Agregar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.docView}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'docView') }/>
                  <label className='text-dark'>Ver</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.docDownload}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'docDownload') }/>
                  <label className='text-dark'>Descargar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.docSend}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'docSend') }/>
                  <label className='text-dark'>Enviar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.whatsAppSend}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'whatsAppSend') }/>
                  <label className='text-dark'>WhatsApp</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    type='checkbox'
                    checked={this.state.formValues.docDrop}
                    onChange={ evt => this.updateInputCheckBoxChecked(evt, 'docDrop') }/>
                  <label className='text-dark'>Eliminar</label>
                </div>
                <div className='wrap-checbox'>
                  <input
                    checked={this.state.formValues.docViewAll}
                    onChange={ this.docAllOptions }
                    type='checkbox'/>
                  <label className='text-dark'>Todos</label>
                </div>
              </div>
            </div>
            {
              <div className='permisionsClient'>
                <h4 className='text-red title'>Lista de exntesiones</h4>
                {
                  userInfo.userInfo.rol === 'admin' ?
                    <a href=''
                      onClick={this.addExtension}
                      className='d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm buttonAddSize'>
                      <i className='fas fa-sm text-white-50'></i> Extensiones</a>
                    : ''
                }
                <div className='wraptitleListClient'>
                  <div className='titleListClient'>No asignadas</div>
                  <div className='spaceTitleListClient'></div>
                  <div className='titleListClient'>Asignadas</div>
                </div>
                <div className='wrappSelectClients'>
                  <div className='listClient'>
                    {
                      filterExtencion.map( extencion => {
                        const classExtencion = cx({
                          'optionClient': true,
                          'selectedClient': extencion.extension === addExtencionSelected,
                        });
                        return (
                          <div
                            key={extencion.extension}
                            onClick={ () => this.addExtencion(extencion.extension) }
                            className={classExtencion}>{ extencion.extension }</div>
                        );
                      })
                    }
                  </div>
                  <div className='optionsListClient'>
                    <input
                      type='button'
                      value='>>'
                      onClick = { this.assignmentAllExtencion }
                      className={`buttonLogin ${!filterExtencion.length && 'disabled'}`}/>
                    <input
                      type='button'
                      value='>'
                      onClick = { this.assignmentExtencion }
                      className={`buttonLogin ${(!filterExtencion.length || addExtencionSelected === '') && 'disabled'}`}/>
                    <input
                      type='button'
                      value='<'
                      onClick = { this.removedExtencion }
                      className={`buttonLogin ${(!formValues.extencionsAssignment.length || removeExtencionSelected === '') && 'disabled'}`}/>
                    <input
                      type='button'
                      value='<<'
                      onClick = { this.removedAllExtencion }
                      className={`buttonLogin ${!formValues.extencionsAssignment.length && 'disabled'}`}/>
                  </div>
                  <div className='listClient'>
                    {
                      formValues.extencionsAssignment.map( assignment => {
                        const classClient = cx({
                          'optionClient': true,
                          'selectedClient': assignment === removeExtencionSelected,
                        });

                        return (
                          <div
                            key={assignment}
                            onClick={ () => this.removeExtencion(assignment) }
                            className={classClient}>{ assignment }</div> );
                      })
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </form>
      </div>
    );
  }

  addExtencion = extencionSelected => {
    this.setState({
      addExtencionSelected: extencionSelected,
      removeExtencionSelected: '',
    });
  }

  removeExtencion = extensionSelected => {
    this.setState({
      removeExtencionSelected: extensionSelected,
      addExtencionSelected: '',
    });
  }

  assignmentExtencion = () => {
    const { addExtencionSelected, formValues } = this.state;

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        extencionsAssignment: [...formValues.extencionsAssignment, addExtencionSelected],
      },
      addExtencionSelected: '',
    }));
  }

  removedExtencion = () => {
    const { removeExtencionSelected, formValues } = this.state;
    const list = formValues.extencionsAssignment.filter( element => element !== removeExtencionSelected);

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        extencionsAssignment: list,
      },
    }));
  }

  assignmentAllExtencion = () => {
    const { extencionList } = this.props;

    const list = [];

    extencionList.forEach(element => 
      list.push(element.extension)
    );

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        extencionsAssignment: list,
      },
    }));
  }

  removedAllExtencion = () => {
    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        extencionsAssignment: [],
      },
      removeExtencionSelected: '',
    }));
  }

  addClient = clientSelected => {
    this.setState({
      addClientSelected: clientSelected,
      removeClientSelected: '',
    });
  }

  checkAll = () => {
    const { All } = this.state.formValues;
    
    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        All: !All,
        userList: !All,
        userAdd: !All,
        userUpdate: !All,
        userDrop: !All,
        userClick: !All,
        userAll: !All,
        clientAdd: !All,
        clientDrop: !All,
        clientList: !All,
        clientUpdate: !All,
        clientAll: !All,
        docAdd: !All,
        docDrop: !All,
        docList: !All,
        docSend: !All,
        whatsAppSend: !All,
        docDownload: !All,
        docView: !All,
        docViewAll: !All,
        referenceAdd: !All,
        referenceDrop: !All,
        referenceList: !All,
        referenceUpdate: !All,
        referenceAll: !All,
      },
    }));
  }

  usersAllOptions = () => {
    const { userAll } = this.state.formValues;

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        userList: !userAll,
        userAdd: !userAll,
        userUpdate: !userAll,
        userDrop: !userAll,
        userClick: !userAll,
        userAll: !userAll,
      },
    }));
  }

  clientAllOptions = () => {
    const { clientAll } = this.state.formValues;

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        clientAdd: !clientAll,
        clientDrop: !clientAll,
        clientList: !clientAll,
        clientUpdate: !clientAll,
        clientAll: !clientAll,
      },
    }));
  }

  docAllOptions = () => {
    const { docViewAll } = this.state.formValues;

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        docAdd: !docViewAll,
        docDrop: !docViewAll,
        docList: !docViewAll,
        docSend: !docViewAll,
        whatsAppSend: !docViewAll,
        docDownload: !docViewAll,
        docView: !docViewAll,
        docViewAll: !docViewAll,
      },
    }));
  }

  referenceAllOptions = () => {
    const { referenceAll } = this.state.formValues;

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        referenceAdd: !referenceAll,
        referenceDrop: !referenceAll,
        referenceList: !referenceAll,
        referenceUpdate: !referenceAll,
        referenceAll: !referenceAll,
      },
    }));
  }

  removeClient = clientSelected => {
    this.setState({
      removeClientSelected: clientSelected,
      addClientSelected: '',
    });
  }

  assignment = () => {
    const { addClientSelected, formValues } = this.state;

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        clientAssignment: [...formValues.clientAssignment, addClientSelected],
      },
      addClientSelected: '',
    }));
  }

  removed = () => {
    const { removeClientSelected, formValues } = this.state;
    const list = formValues.clientAssignment.filter( element => element !== removeClientSelected);

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        clientAssignment: list,
      },
    }));
  }

  assignmentAll = () => {
    const { clientList } = this.props;

    const list = [];

    clientList.forEach(element => 
      list.push(element.rfc)
    );

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        clientAssignment: list,
      },
    }));
  }

  removedAll = () => {
    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        clientAssignment: [],
      },
      removeClientSelected: '',
    }));
  }

  updateInputCheckBoxChecked(evt, key) {
    const inputValue = evt.target.checked;

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        [key]: inputValue,
      },
    }));
  }

  updateInputValue(evt, key) {
    const inputValue = evt.target.value;
    let result = '';

    if (key === 'password') {
      result = encodeURI(encrypt(inputValue));
    } else {
      result = inputValue;
    }


    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        [key]: result,
      },
    }));
  }

  sendClientForm = () => {
    const { dispatch } = this.props;
    const { formValues, userInfo } = this.state;
    const userToken = userInfo && userInfo.token ? userInfo.token : null;

    // Metodo para agregar usuario
    dispatch(AduanasActions.registraUsuario(userToken, formValues));
    dispatch(eventBtn('add'));
  }

  cancelAddClient = () => {
    const { history } = this.props;
    history.push('/list-users');
  }

  validateField(evt, key, lengthField) {
    const inputValue = evt.target.value;
    let validateOption = undefined;

    if (key === 'email') {
      validateOption = validateEmail(inputValue);
    } else {
      validateOption = validate(inputValue, lengthField);
    }

    this.setState(prevState => ({
      validates : {
        ...prevState.validates,
        [key] : validateOption,
      },
    }));
  }
}

AddUser.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AddUser);