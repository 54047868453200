import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { encrypt, encodeURI } from '../utilities/commons';
import { eventBtn } from '../actions/actions';
import { getCookie } from '../utilities/helpers';
import { validate, validateNumber, validateEmail } from '../utilities/validates';
import * as AduanasActions from '../actions/aduanas-api/aduanas-api-action-creators';
import cx from 'classnames';
import Header from '../components/global/header';
import PropTypes from 'prop-types';

const mapStateToProps = state => ({
  isUserCreated: state.authentication.isUserCreated,
  isLogin: state.authentication.isLogin,
  userInfo: state.authentication.userInfo,
  token: state.authentication.token,
  loadingSignup: state.authentication.loadingSignup,
});

class AddClient extends Component {
  constructor(props) {
    super(props);

    const formValues = {
      rfc: '',
      razonSocial: '',
      email: '',
      telefono: '',
      direccion: '',
      cp: '',
      password: '',
    };

    this.state = {
      formValues: formValues,
      userInfo: getCookie('userSession'),
      validates: formValues,
    };
  }

  componentWillMount() {
    const { history } = this.props;
    const { userInfo } = this.state;
    if ( userInfo === null ) {
      history.push('/');
      return;
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isUserCreated, history } = this.props;
    if (isUserCreated !== nextProps.isUserCreated && nextProps.isUserCreated) {
      history.push('/list');
    }
  }

  render() {
    const {
      props: {
        dispatch,
      },
      state: {
        validates,
      },
    } = this;

    const ObjectValues = Object.values(validates);
    let classActive = undefined;

    ObjectValues.map( element => {
      if ( !element || element === '') {
        classActive = false;
      } else {
        classActive = true;
      }
    });

    const classBtn = cx({
      'buttonLogin' : true,
      'disabled' : !classActive,
    });

    return (
      <Fragment>
        <Header dispatch={ dispatch }/>
        <div className='loginMainSection bg-MWL'>
          <div className='addSection border-left-red bg-white mb-5'>
            <div className='addHeader'>
              <h3 className='loginHeader text-red title'>
                Agregar Cliente
              </h3>
            </div>
            <div className='addBody'>
              { this.renderAddClientForm() }
              <div className='addFooter'>
                <button
                  onClick = { this.cancelAddClient }
                  className='buttonLogin'>
                  Cancelar
                </button>
                <button
                  onClick = { this.sendClientForm }
                  className={ classBtn } >
                  Agregar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  renderAddClientForm() {
    const { validates } = this.state;

    const formObj = [
      {
        text: 'RFC',
        type: 'text',
        key:  'rfc',
        lengthField: 13,
        errorMessage: 'El RFC debe contener 13 dígitos',
      },
      {
        text: 'Razon Social',
        type: 'text',
        key:  'razonSocial',
        errorMessage: 'La razon Social no debe de estar bacio',
      },
      {
        text: 'Email',
        type: 'email',
        key:  'email',
        errorMessage: 'El correo debe tener el siguiente formato ejemplo@ejemplo',
      },
      {
        text: 'Contraseña',
        type: 'password',
        key:  'password',
        errorMessage: 'La contraseña es requerida',
      },
      {
        text: 'Telefono',
        type: 'text',
        key:  'telefono',
        lengthField: 10,
        errorMessage: 'El telefono debe contar con 10 digitos',
      },
      {
        text: 'Dirección',
        type: 'text',
        key:  'direccion',
        errorMessage: 'Este campo no debe estar vacio',
      },
      {
        text: 'Codigo Postal',
        type: 'text',
        key:  'cp',
        lengthField: 5,
        errorMessage: 'El codigo postal debe contener 10 digitos',
      },
    ];

    return (
      <div>
        <form autoComplete='off'>
          {
            formObj.map((obj, index)=> {
              const classInput = cx({
                'addformGroup-error-add' : !validates[obj.key] && validates[obj.key] !== '' && validates[obj.key] !== undefined,
              });

              return (
                <div className='addformGroupUser' key={index} >
                  <label className='text-dark'>
                    { obj.text }
                  </label>
                  <input
                    className={ classInput }
                    type={ obj.type }
                    placeholder={ obj.text }
                    onBlur={ evt => this.validateField(evt, obj.key, obj.lengthField) }
                    onChange={ evt => this.updateInputValue(evt, obj.key) }
                  />
                  {
                    !validates[obj.key] && validates[obj.key] !== '' && validates[obj.key] !== undefined &&
                      <span className='formGroup-error'>
                        {
                          obj.errorMessage
                        }
                      </span>
                  }
                </div>
              );
            })
          }
        </form>
      </div>
    );
  }

  updateInputValue(evt, key) {
    const inputValue = evt.target.value;
    let result = '';

    if (key === 'password') {
      result = encodeURI(encrypt(inputValue));
    } else {
      result = inputValue;
    }

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        [key]: result,
      },
    }));
  }

  sendClientForm = () => {
    const { dispatch } = this.props;
    const { formValues, userInfo } = this.state;
    const userToken = userInfo && userInfo.token ? userInfo.token : null;

    // Metodo para agregar usuario
    dispatch(AduanasActions.registraCliente(userToken, formValues));
    dispatch(eventBtn('add'));
  }

  cancelAddClient = () => {
    const { history } = this.props;
    history.push('/list');
  }

  validateField(evt, key, lengthField) {
    const inputValue = evt.target.value;
    let validateOption = undefined;

    if (key === 'email') {
      validateOption = validateEmail(inputValue);
    } else if (key === 'telefono' || key === 'cp') {
      validateOption = validateNumber(inputValue, lengthField);
    } else {
      validateOption = validate(inputValue, lengthField);
    }

    this.setState(prevState => ({
      validates : {
        ...prevState.validates,
        [key] : validateOption,
      },
    }));
  }
}

AddClient.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AddClient);