import keyMirror from 'keymirror';

export const actionableCodes = keyMirror({
  ABORT: null,
  CONTINUE: null,
  FIX: null,
  RETRY: null,
  WARNING: null,
  RESTART: null,
});

export const incidentPrecedence = {
  [actionableCodes.ABORT]: 4,
  [actionableCodes.FIX]: 3,
  [actionableCodes.RETRY]: 2,
  [actionableCodes.CONTINUE]: 1,
};

const errorCodes = keyMirror({
  SERVER_NOT_RESPONDING: null,
  INCOMPATIBLE_BROWSER: null,
  INTERNAL_ERROR: null,
  ROUTE_NOT_FOUND: null,
  CART_EXPIRATION_WARNING: null,
  CART_EXPIRATION_ERROR: null,
  CHECKIN_CART_EXPIRATION_WARNING: null,
  UNKNOWN_API_ERROR: null,
  LOCAL_STORAGE_NOT_SUPPORTED: null,
});

export default errorCodes;
