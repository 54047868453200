import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getCookie } from '../utilities/helpers';
import { validate as validateInput } from '../utilities/validates';
import * as AduanasActions from '../actions/aduanas-api/aduanas-api-action-creators';
import { eventBtn } from '../actions/actions';
import cx from 'classnames';
import Footer from '../components/global/footer';
import Header from '../components/global/header';
import queryString from 'query-string';

const mapStateToProps = state => ({
  referenceList: state.reference.referenceList,
});

class EditReference extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(props.location.search);

    this.state = {
      clientID: queryParams && queryParams.client ? queryParams.client : null,
      clientValue: '',
      description: true,
      name: true,
      referenceID: queryParams && queryParams.reference ? queryParams.reference : null,
      userInfo: getCookie('userSession'),
      validate: undefined,
    };
  }

  componentWillMount() {
    const { dispatch, history } = this.props;
    const { userInfo, clientID } = this.state;
    if ( userInfo === null ) {
      history.push('/');
      return;
    }

    const userToken = userInfo.token;
    dispatch(AduanasActions.listaReferencias(userToken, clientID));
  }

  render() {
    const { props: { referenceList, dispatch }, state : { validate, referenceID, name, description } } = this;

    const classBtn = cx({
      'buttonLogin' : true,
      'disabled' : !validate,
    });

    const classInputName = cx({
      'addformGroup-error-add' : !name,
    });

    const classInputDescription = cx({
      'textAreaModal': true,
      'addformGroup-error-add' : !description,
    });

    const reference = referenceList.filter( reference => reference._id === referenceID );

    return (
      <Fragment>
        <Header dispatch={ dispatch }/>
        <div className='loginMainSection bg-MWL'>
          <div className='addSection border-left-red bg-white mb-5'>
            <div className='addHeader'>
              <h3 className='loginHeader text-red title'>
              Actualizar Referencia
              </h3>
            </div>
            {
              reference[0] &&
                <Fragment>
                  <div className='addBody'>
                    <div className='addformGroupUser' >
                      <label className='text-dark'>
                      ID Referencia
                      </label>
                      <input
                        ref='referenciaId'
                        type='text'
                        disabled='disabled'
                        defaultValue={ reference[0].referenceID }
                        // onChange={ evt => this.inputValidate('') }
                      />
                    </div>
                    <div className='addformGroupUser' >
                      <label className='text-dark'>
                          Nombre
                      </label>
                      <input
                        ref='name'
                        type='text'
                        className={ classInputName }
                        defaultValue={ reference[0].name }
                        onChange={ () => this.inputValidate('name') } />
                      {
                        !name &&
                          <span className='formGroup-error'>
                              Error
                          </span>
                      }
                    </div>
                    <div className='addformGroupUser' >
                      <label className='text-dark'>
                          Descripcion
                      </label>
                      <textarea
                        ref='description'
                        className={ classInputDescription }
                        type='textArea'
                        defaultValue={ reference[0].description }
                        onChange={ () => this.inputValidate('description') } />
                      {
                        !description &&
                          <span className='formGroup-error'>
                              Error
                          </span>
                      }
                    </div>
                    <div className='addFooter'>
                      <button
                        onClick = { this.cancelUpdateClient }
                        className='buttonLogin'>
                          Cancelar
                      </button>
                      <button
                        onClick = { this.updateReference }
                        className={ classBtn } >
                          Actualizar
                      </button>
                    </div>
                  </div>
                </Fragment>
            }
          </div>
        </div>
        <Footer/>
      </Fragment>
    );
  }

  updateReference = () => {
    const { dispatch, /* referenceList, */ history } = this.props;
    const { referenceID, clientID, userInfo } = this.state;

    const userToken = userInfo.token;

    // const reference = referenceList.filter( reference => reference._id === referenceID );

    const obj = {
      description: this.refs['description'].value,
      name: this.refs['name'].value,
      // referenceID: reference[0].referenceID,
      referenceID: referenceID,
      clientID,
    };

    dispatch(AduanasActions.actualizaReferencia(userToken, obj));
    history.push(`/reference?client=${ clientID }`);
    dispatch(eventBtn('update'));
  }

  cancelUpdateClient = () => {
    const { history } = this.props;
    const { clientID } = this.state;
    history.push(`/reference?client=${ clientID }`);
  }

  inputValidate(type) {
    const { referenceID } = this.state;
    const { referenceList } = this.props;

    const reference = referenceList.filter( reference => reference._id === referenceID );

    let validateBtn = false;

    if (reference[0] && this.refs['name'] && this.refs['description']) {
      if (reference[0]['name'] !== this.refs['name'].value || reference[0]['description'] !== this.refs['description'].value) {
        validateBtn = true;
      }
    }

    if (this.refs[type]) {
      this.setState({
        validate: validateBtn,
        [type]: validateInput(this.refs[type].value),
      });
    }
  }
}

export default connect(mapStateToProps)(EditReference);