import React, { Component, Fragment } from 'react';
import { MDBDataTable } from 'mdbreact';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utilities
import 'react-toastify/dist/ReactToastify.min.css';
import { getCookie } from '../utilities/helpers';
import { ToastContainer, toast, Flip } from 'react-toastify';
import { eventBtn } from '../actions/actions';

// Acciones
import * as AduanasActions from '../actions/aduanas-api/aduanas-api-action-creators';
import * as ModalActionTypes from '../actions/modal-handling/modal-action-creators';
import ModalConfirmExtension from '../components/global/modalDeleteExtension';

import Footer from '../components/global/footer';
import Header from '../components/global/header';
import Modal from '../components/global/modal';

const mapStateToProps = state => ({
  actionBtn: state.action.action,
  addExtencion: state.extencions.addExtencion,
  deleteExtension: state.extencions.dropExtencion,
  extencionList: state.extencions.extencionList,
  isOpen: state.modalReducer.isOpen,
  errorDeleteExtension: state.extencions.errorDeleteExtension,
  errorAddExtension: state.extencions.errorAddExtension,
});

class ListExtensions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientValue: '',
      selectedAction: null,
      selectedExtension: null,
      userInfo: getCookie('userSession'),
    };
  }

  componentWillMount() {
    const { history, dispatch } = this.props;
    const { userInfo } = this.state;

    if ( userInfo === null ) {
      history.push('/');
      return;
    }
    dispatch(AduanasActions.listaExtenciones(userInfo.token));
  }

  componentWillReceiveProps(nextProps) {
    const { deleteExtension, dispatch, errorDeleteExtension } = this.props;
    const { userInfo } = this.state;

    if (deleteExtension !== nextProps.deleteExtension && nextProps.deleteExtension) {
      dispatch(AduanasActions.listaExtenciones(userInfo.token));
    }

    if (nextProps.actionBtn !== undefined && nextProps.actionBtn === 'add') {
      let message = '';
      let isErrror = false;

      if (nextProps.addExtencion) {
        message = 'Se agrego satisfactoriamente la extension';
      } else {
        message = 'No se agrego la extension';
        isErrror = true;
      }
      setTimeout(() => {
        this.notify(message, isErrror);
      }, 500);
    }

    if (nextProps.deleteExtension !== deleteExtension && nextProps.deleteExtension) {
      setTimeout(() => {
        this.notify('Se elimimo satisfactoriamente la extension', false);
      }, 500);
    }

    if (nextProps.errorDeleteExtension !== errorDeleteExtension && nextProps.errorDeleteExtension) {
      setTimeout(() => {
        this.notify('No se pudo eliminar la extension', true);
      }, 500);
    }
  }

  render() {
    const {
      isOpen,
      dispatch,
    } = this.props;

    const {
      selectedExtension,
      userInfo,
    } = this.state;

    return (
      <Fragment>
        <Header dispatch={ dispatch }/>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnVisibilityChange={true}
          draggable={true}
          pauseOnHover={true}
        />
        <ToastContainer />
        <div className='loginMainSection bg-MWL'>
          { isOpen &&
            <Modal
              dispatch = { dispatch }
              component = {
                <ModalConfirmExtension
                  value = { selectedExtension }
                  popUpOpen={ this.popUpOpen }
                  action={AduanasActions.eliminaExtension}
                  dispatch={ dispatch } />
              }
            />
          }
          <div className='addSection border-left-red bg-white'>
            <div className='d-sm-flex align-items-center justify-content-between mb-4 text-red'>
              <h3 className='loginHeader title'>Listado de Extensiones</h3>
              {
                userInfo && userInfo.userInfo.userAdd &&
                <a href=''
                  className='d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm buttonAddSize'
                  onClick= { this.addExtension }>
                  <i className='fas fa-user-plus fa-sm text-white-50'></i> +Extension</a>
              }
            </div>
            <div className='addDocs'>
              <hr></hr>
              { this.listExtensions() }
            </div>
            <div className='addFooter'>
              <button
                onClick = { this.cancelUpdateUser }
                className='buttonLogin'>
                Regresar
              </button>
            </div>
          </div>
        </div>
        <Footer/>
      </Fragment>
    );
  }

  toastId = null;

  notify = (message, err) => {
    const { dispatch } = this.props;
    let type = 'success';

    if (err) {
      type = 'error';
    }

    if (! toast.isActive(this.toastId)) {
      this.toastId = toast[type](message, {
        position: 'top-right',
        autoClose: 5000,
        transition: Flip,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    dispatch(eventBtn(undefined));
  };

  cancelUpdateUser = () => {
    const { history } = this.props;
    const rute = location.toString();
    const splitRute = rute.split('/');
    const path = splitRute[splitRute.length - 1];

    if (path === 'addUser') {
      history.push('/add-user');
    } else {
      history.push(`/update-user?user=${path}`);
    }
  }

  popUpOpen = () => {
    const { dispatch } = this.props;
    dispatch(eventBtn('delete'));
  }

  addExtension = () => {
    const { history } = this.props;
    history.push('/add-extension');
  }

  extensionEvent(action, extencion) {
    const { dispatch } = this.props;

    this.setState({
      selectedAction: action,
      selectedExtension: extencion,
    });
    dispatch(eventBtn(undefined));
    dispatch(ModalActionTypes.openModal(true));
  }

  listExtensions() {
    const { extencionList } = this.props;
    const { userInfo } = this.state;
  
    const listUsers = [];
  
    if ( extencionList && extencionList.length > 0 ) {
      extencionList.map((extencion, index) => {
        const objUser = {};
        objUser.id = index + 1;
        objUser.name = extencion.extension;
        objUser.email = extencion.description;
        objUser.option = ([
          <div className='iconGroupExtensionTable' key={index}>
            {/* <i
              className={`fas fa-pencil-alt ${userInfo && !userInfo.userInfo.userUpdate && 'enableElement'}`}
              title='Editar Extencion'
              onClick = { () => { this.props.history.push(`/update-user?user=${extencion._id}`); } }
            >
            </i> */}
            <i
              className={`far fa-trash-alt ${userInfo && !userInfo.userInfo.userDrop && 'enableElement'}`}
              title='Eliminar Extencion'
              onClick = { () => this.extensionEvent('delete', extencion) }>
            </i>
          </div>]);
        listUsers.push(objUser);
      });
    }
    const data = {
      columns: [
        {
          label: '#',
          field: 'id',
          sort: 'asc',
          width: 50,
        },
        {
          label: 'Exntesion',
          field: 'extension',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'Description',
          field: 'description',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'Opciones',
          field: 'options',
          sort: 'disabled',
          width: '50px',
        },
      ],
      rows: listUsers,
    };
  
    if (userInfo) {
      if (userInfo.userInfo && !userInfo.userInfo.userUpdate && !userInfo.userInfo.userDrop) {
        data.columns.splice((data.columns.length - 1), 1);
        data.rows.map( element => delete element.option);
      }
    }
    
  
    return (
      <MDBDataTable
        bordered={true}
        data={data}
        entriesLabel='Items'
        hover={true}
        infoLabel={['Mostrando', 'a', 'de', 'usuarios']}
        paginationLabel={['Anterior', 'Siguiente']}
        responsive={true}
        searchLabel='Buscar Extensiones'
        small={true}
        striped={true}
      />
    );
  }
}

ListExtensions.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ListExtensions);