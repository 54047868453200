import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MDBDataTable } from 'mdbreact';

// Utilities
import 'react-toastify/dist/ReactToastify.min.css';
import { getCookie } from '../utilities/helpers';
import { ToastContainer, toast, Flip } from 'react-toastify';

// Acciones
import * as AduanasActions from '../actions/aduanas-api/aduanas-api-action-creators';
import * as ModalActionTypes from '../actions/modal-handling/modal-action-creators';
import { eventBtn } from '../actions/actions';

// Componentes
import Footer from '../components/global/footer';
import Header from '../components/global/header';
import Modal from '../components/global/modal';
import ModalConfirmUser from '../components/global/modalConfirmUser';

const mapStateToProps = state => ({
  actionBtn: state.action.action,
  userDeleted: state.deleteUser.user,
  userList: state.user.usersList,
  errAdd: state.user.err,
  errDeleted: state.deleteUser.err,
  errUpdate: state.updateUser.err,
  isOpen: state.modalReducer.isOpen,
  msgAdd: state.user.msg,
  msgDeleted: state.deleteUser.msg,
  msgUpdate: state.updateUser.msg,
  statusDelete: state.deleteUser.status,
});

class ListUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientValue: '',
      selectedAction: null,
      selectedUser: null,
      userInfo: getCookie('userSession'),
    };
  }

  componentWillMount() {
    const { dispatch, history } = this.props;
    const { userInfo } = this.state;
    if ( userInfo === null ) {
      history.push('/');
      return;
    }

    const userToken = userInfo.token;
    dispatch(AduanasActions.listUsers(userToken));
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, history, statusDelete, userDeleted } = this.props;
    const { userInfo } = this.state;
    if ( userInfo === null ) {
      history.push('/');
      return;
    }

    const userToken = userInfo.token;

    if (statusDelete !== nextProps.statusDelete || userDeleted !== nextProps.userDeleted) {
      dispatch(AduanasActions.listUsers(userToken));
    }

    if (nextProps.msgDeleted !== undefined && nextProps.actionBtn === 'delete') {
      setTimeout( () => {
        this.notify(nextProps.msgDeleted, nextProps.errDeleted);
      }, 500);
    }

    if (nextProps.msgUpdate !== undefined && nextProps.actionBtn === 'update') {
      setTimeout( () => {
        this.notify(nextProps.msgUpdate, nextProps.errUpdate);
      }, 500);
    }

    if (nextProps.msgAdd !== undefined && nextProps.actionBtn === 'add') {
      setTimeout( () => {
        this.notify(nextProps.msgAdd, nextProps.errAdd);
      }, 500);
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(eventBtn(undefined));
  }

  render() {
    const {
      isOpen,
      dispatch,
    } = this.props;

    const {
      selectedUser,
      userInfo,
    } = this.state;

    return (
      <Fragment>
        <Header dispatch={ dispatch }/>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnVisibilityChange={true}
          draggable={true}
          pauseOnHover={true}
        />
        <ToastContainer />
        <div className='loginMainSection bg-MWL'>
          { isOpen &&
            <Modal
              dispatch = { dispatch }
              component = {
                <ModalConfirmUser
                  value = { selectedUser }
                  popUpOpen={ this.popUpOpen }
                  action={AduanasActions.eliminarUsuario}
                  dispatch={ dispatch } />
              }
            />
          }
          <div className='addSection border-left-red bg-white'>
            <div className='d-sm-flex align-items-center justify-content-between mb-4 text-red'>
              <h3 className='loginHeader title'>Listado de Usuarios</h3>
              {
                userInfo && userInfo.userInfo.userAdd &&
                <a href=''
                  className='d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm buttonAddSize'
                  onClick= { this.addUser }>
                  <i className='fas fa-user-plus fa-sm text-white-50'></i> Agregar Usuario</a>
              }
            </div>
            <div className='addDocs'>
              <hr></hr>
              { this.listClientsTable()}
            </div>
          </div>
        </div>
        <Footer/>
      </Fragment>
    );
  }

  toastId = null;

  notify = (message, err) => {
    let type = 'success';

    if (err) {
      type = 'error';
    }

    if (! toast.isActive(this.toastId)) {
      this.toastId = toast[type](message, {
        position: 'top-right',
        autoClose: 5000,
        transition: Flip,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  popUpOpen = () => {
    const { dispatch } = this.props;
    dispatch(eventBtn('delete'));
  }

  updateInputValue(evt) {
    this.setState({
      clientValue: evt.target.value,
    });
  }

  addUser = () => {
    const { history } = this.props;
    history.push('/add-user');
  }

  userEvent(action, user) {
    const { dispatch } = this.props;

    this.setState({
      selectedAction: action,
      selectedUser: user,
    });
    dispatch(eventBtn(undefined));
    dispatch(ModalActionTypes.openModal(true));
  }

  listClientsTable() {
    const { userList } = this.props;
    const { userInfo } = this.state;

    const listUsers = [];

    if ( userList && userList.length > 0 ) {
      userList.map((user, index) => {
        const objUser = {};
        objUser.id = index + 1;
        objUser.name = user.name;
        objUser.email = user.email;
        objUser.rol = user.rol;
        objUser.option = ([
          <div className='iconGroupUserTable' key={index}>
            <i
              className={`fas fa-pencil-alt ${userInfo && !userInfo.userInfo.userUpdate && 'enableElement'}`}
              title='Editar Usuario'
              onClick = { () => { this.props.history.push(`/update-user?user=${user._id}`); } }
            >
            </i>
            <i
              className={`far fa-trash-alt ${userInfo && !userInfo.userInfo.userDrop && 'enableElement'}`}
              title='Eliminar Usuario'
              onClick = { () => this.userEvent('delete', user) }>
            </i>
          </div>]);
        listUsers.push(objUser);
      });
    }
    const data = {
      columns: [
        {
          label: '#',
          field: 'id',
          sort: 'asc',
          width: 50,
        },
        {
          label: 'Name',
          field: 'name',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Email',
          field: 'email',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'Rol',
          field: 'rol',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'Opciones',
          field: 'options',
          sort: 'disabled',
          width: 50,
        },
      ],
      rows: listUsers,
    };

    if (userInfo) {
      if (userInfo.userInfo && !userInfo.userInfo.userUpdate && !userInfo.userInfo.userDrop) {
        data.columns.splice((data.columns.length - 1), 1);
        data.rows.map( element => delete element.option);
      }
    }
    

    return (
      <MDBDataTable
        bordered={true}
        data={data}
        entriesLabel='Número de Usuarios'
        hover={true}
        infoLabel={['Mostrando', 'a', 'de', 'usuarios']}
        paginationLabel={['Anterior', 'Siguiente']}
        responsive={true}
        searchLabel='Buscar Usuario'
        small={true}
        striped={true}
      />
    );
  }
}

ListUsers.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ListUsers);
