// import errorActionTypes from '../actions/error-handling/error-action-types';
// import errorCodes from '../error-handling/error-codes';
// import { getIncidentFromFatalError, getIncidentFromAPI, getErrorMessage } from '../error-handling/incident-wrapper';

/**
 * This middleware run a collection of async action in parallel.
 * It reaches SUCCESS state if all async actions do, and reaches FAIL if any of
 * the async anctions do.
 *
 * The action object must have:
 * - type: an async action type, ie. an object with START, SUCCESS and FAIL sub-types
 * - asyncActions: the array of async actions objects.
 *
 * The propagated success action has:
 * - results: a list of action objects for each async action resolved. Each object carries the SUCCESS type,
 * its result and the original async action payload.
 */
export default function multipleAsyncActionMiddleware() {
  return (next) => (action) => {
    const { asyncActions } = action;

    if (!asyncActions) {
      return next(action);
    }

    return resolveAllPromises(action, next);
  };
}

/**
 * Returns the single promise that wraps all the multiple async action promises
 * Exporting for use in the async action chain middleware.
 *
 * @param  {Object}   action - multiple async action object
 * @param  {Function} next
 * @return {Promise}
 */
export function resolveAllPromises(action, next) {
  const { asyncActions, type, ...rest } = action;

  next({ ...rest, type: type.START });

  /*
   * Building the results array before resolving all promises.
   * Each object in the results array have its own "result" property and include payload of the corresponding async action.
   * The "result" property will be injected when the promises resolve below.
   */
  const promises = asyncActions.map(action => action.getPromise());
  const results = asyncActions.map(action => ({ ...action, type: action.type.SUCCESS, result: null }));

  // If error, prevent async action SUCCESS
  let errorOccured = false;

  return Promise.all(promises)
    .catch(error => {
      console.log(`%c${type.START}: an error occurred in an async action.`, 'color: red');  // eslint-disable-line no-console
      console.log(error); // eslint-disable-line no-console

      // const wrappedIncident = getIncidentFromAPI(error);

      if (action.recreateSelf) {
        // next({ type: errorActionTypes.SAVE_ACTION, actionObject: action });
      }

      // next({ ...rest, incident: wrappedIncident, type: type.FAIL });

      errorOccured = true;
    })
    .then(allResults => {
      if (allResults && !errorOccured) {
        // Notice that order of values returned by Promise.all is determined by order of the promises array.
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/all
        allResults.forEach((response, i) => {
          results[i].result = response;
        });

        next({ ...rest, results, type: type.SUCCESS });
      }
    })
    .catch(error => {
      console.log(`%c${type.START}: an error occurred AFTER an async action.`, 'color: red'); // eslint-disable-line no-console
      console.log(error); // eslint-disable-line no-console
      /*
      next({
        type: errorActionTypes.GLOBAL_ERROR,
        incident: getIncidentFromFatalError(errorCodes.INTERNAL_ERROR, type.START, getErrorMessage(error, action)),
      }); */
    });
}
