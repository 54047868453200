import aduanasActionTypes from '../actions/aduanas-api/aduanas-api-action-types';

const initialState = {
  err: undefined,
  msg: undefined,
};

const actionsMap = {
  [aduanasActionTypes.ADUANAS_SEND_EMAIL.START]: (state) => {
    return {
      ...state,
      err: undefined,
      msg: undefined,
    };
  },

  [aduanasActionTypes.ADUANAS_SEND_EMAIL.SUCCESS]: (state, action) => {
    return {
      ...state,
      err: !action.result.sendEmail,
      msg: action.result.msg,
    };
  },

  [aduanasActionTypes.ADUANAS_SEND_EMAIL.FAIL]: (state, action) => {
    return {
      ...state,
      err: !action.result.sendEmail,
      msg: action.result.msg,
    };
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  if (!fn) {
    return state;
  }
  return fn(state, action);
}
