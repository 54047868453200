import { createStore, compose, applyMiddleware } from 'redux';
import createLogger from 'redux-logger';

import asyncActionMiddleware from './async-action-middleware';
import multipleAsyncActionMiddleware from './multiple-async-action-middleware';
import debuggingMiddleware from './debugging-middleware';
import asyncActionChainMiddleware from './async-action-chain-middleware';

import reducer from '../reducers/index';

const createRedux = (data) => {
  const middlewares = [
    asyncActionMiddleware,
    multipleAsyncActionMiddleware,
    asyncActionChainMiddleware,
    debuggingMiddleware,
  ];

  if (process.env.ACTION_LOGGING) {
    middlewares.push(createLogger({
      collapsed: true,
      logErrors: process.env.ACTION_LOGGING,
    }));
  }

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const enhancers = composeEnhancers(
    applyMiddleware(...middlewares),
  );

  const store = createStore(reducer, data, enhancers);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

const initialState = {};

const store = createRedux(initialState);

export default store;
