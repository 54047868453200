import errorActionTypes from '../actions/error-handling/error-action-types';
import errorCodes from '../error-handling/error-codes';
import { getIncidentFromFatalError, getIncidentFromAPI, getErrorMessage } from '../error-handling/incident-wrapper';

/**
 *
 * An async action has three states: START, SUCCESS and FAIL.
 * Its type property is an object with those three properties.
 *
 * An async action must have a promise property (the asynchonous task to be run).
 */
const queue = [];
let isPromiseRunning = false;
export default function asyncActionMiddleware(store) {
  return (next) => (action) => {
    const { getPromise } = action;
    if (!getPromise) {
      return next(action);
    } else {
      return resolvePromise(action, next, store);
    }
  };
}

/**
 * Returns the promise queue
 * Exporting for use in the async action chain middleware.
 *
 * @param  {Object}   action - multiple async action object
 * @param  {Function} next
 * @param  {Object}   store - store functions (dispatch, getState)
 * @return {Promise}
 */
export function resolvePromise(action, next, store) {
  const { type, serialize, ...rest } = action;

  // start action to display loaders
  next({ ...rest, type: type.START });
  // check if there's a promise running
  if (!isPromiseRunning && queue.length === 0) {
    isPromiseRunning = true; // make sure nothing is happening after
    // run async action
    return runPromise(next, action, store);
  } else {
    // prevent action from running.
    if (serialize === true) {
      queue.push(action);
    } else {
      return runPromise(next, action, store);
    }
  }
}

function runPromise(next, action, store) {
  const { getPromise, type, ...rest } = action;

  // If error, prevent async action SUCCESS
  let errorOccured = false;
  return getPromise(store.getState())
    .catch(error => {
      console.log(`%c${type.START}: an error occurred in an async action.`, 'color: red'); // eslint-disable-line no-console
      console.error(error); // eslint-disable-line no-console
      const wrappedIncident = getIncidentFromAPI(error, type.START);
      if (action.recreateSelf) {
        next({ type: errorActionTypes.SAVE_ACTION, actionObject: action });
      }

      next({ ...rest, type: type.FAIL, incident: wrappedIncident });

      errorOccured = true;

      runQueue(next);
    })
    .then(result => {
      if (!errorOccured) {
        if (result.err && result.message) {
          next({ ...rest, result, type: type.FAIL });
        } else {
          next({ ...rest, result, type: type.SUCCESS });
          runQueue(next);
        }
        if (typeof action.onSuccess === 'function') {
          action.onSuccess(store.getState());
        }
      }
    }).catch((error) => {
      console.log(`%c${type.START}: an error occurred AFTER an async action.`, 'color: red'); // eslint-disable-line no-console
      console.error(error); // eslint-disable-line no-console

      next({
        type: errorActionTypes.GLOBAL_ERROR,
        incident: getIncidentFromFatalError(errorCodes.INTERNAL_ERROR, type.START, getErrorMessage(error, action)),
      });

      runQueue(next);
    });
}

function runQueue(next) {
  isPromiseRunning = false;
  if (queue.length > 0) {
    const action = queue.shift();
    runPromise(next, action);
  }
}
