import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getCookie } from '../utilities/helpers';
import { encrypt, encodeURI } from '../utilities/commons';
import { validate, validateEmail } from '../utilities/validates';
import * as AduanasActions from '../actions/aduanas-api/aduanas-api-action-creators';
import { eventBtn } from '../actions/actions';
import cx from 'classnames';
import Footer from '../components/global/footer';
import Header from '../components/global/header';
import PropTypes from 'prop-types';
import queryString from 'query-string';

const mapStateToProps = state => ({
  clientList: state.client.usersList,
  extencionList: state.extencions.extencionList,
  userList: state.user.usersList,
  isOpen: state.modalReducer.isOpen,
  statusDelete: state.deleteUser.status,
});

class UpdateUser extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(props.location.search);

    const formValues = {
      id: '',
      name: '',
      email: '',
      password: '',
      rol: '',
      clientAdd: false,
      clientDrop: false,
      clientList: false,
      clientUpdate: false,
      clientAssignment:[],
      extencionsAssignment: [],
      docAdd: false,
      docDrop: false,
      docList: false,
      docSend: false,
      whatsAppSend: false,
      docDownload: false,
      docView: false,
      referenceAdd: false,
      referenceDrop: false,
      referenceList: false,
      referenceUpdate: false,
      userAdd: false,
      userDrop: false,
      userList: false,
      userUpdate: false,
      userClick: false,
    };

    this.state = {
      formValues: formValues,
      userID: queryParams && queryParams.user ? queryParams.user : null,
      clientValue: '',
      codigoPostal: true,
      password: true,
      direccion: true,
      email: true,
      razonSocial: true,
      rfc: true,
      telefono: true,
      userInfo: getCookie('userSession'),
      validates: {
        nombre: '',
        email: '',
        password: '',
        rol: '',
      },
      addClientSelected: '',
      removeClientSelected: '',
      addExtencionSelected: '',
      removeExtencionSelected: '',
    };
  }

  componentWillMount() {
    const { history, dispatch } = this.props;
    const { userInfo } = this.state;
    if ( userInfo === null ) {
      history.push('/');
      return;
    }

    const userToken = userInfo.token;

    dispatch(AduanasActions.listUsers(userToken));
    dispatch(AduanasActions.listaClientes(userToken));
    dispatch(AduanasActions.listaExtenciones(userToken));
  }

  componentWillReceiveProps(nextProps) {
    const { isUserCreated, history, userList } = this.props;
    const { userID } = this.state;
    if (isUserCreated !== nextProps.isUserCreated && nextProps.isUserCreated) {
      history.push('/list-users');
    }

    if (userList !== nextProps.userList) {
      const { formValues } = this.state;
      const ObjectValues = Object.keys(formValues);
      const user = nextProps.userList.filter( user => user._id === userID );
  
      if (user[0]) {
        ObjectValues.map( key => {
          if ( formValues[key] !== user[0][key]) {
            const value = key === 'id' ? user[0]._id : user[0][key];
            this.setState(prevState => ({
              formValues: {
                ...prevState.formValues,
                [key]: value,
              },
            }));
          }
        });
      }
    }
  }

  render() {
    const {
      props: {
        dispatch,
        userList,
      },
      state: {
        formValues,
        userID,
      }, 
    } = this;

    const user = userList && userList.filter( user => user._id === userID );
    const ObjectValues = Object.keys(formValues);

    let classActive = false;
    
    if (userList && user[0]) {
      ObjectValues.filter( key => {
        if ( formValues[key] !== user[0][key] && key !== 'id') {
          classActive = true;
        }
      });
    }

    const classBtn = cx({
      'buttonLogin' : true,
      'disabled' : !classActive,
    });


    return (
      <Fragment>
        <Header dispatch={ dispatch }/>
        <div className='loginMainSection bg-MWL'>
          <div className='addSection border-left-red bg-white mb-5'>
            <div className='addHeader'>
              <h3 className='loginHeader text-red title'>
                Actualizar Usuario
              </h3>
            </div>
            <div className='addBody'>
              { this.renderUpdateUserForm() }
              <div className='addFooter'>
                <button
                  onClick = { this.cancelUpdateUser }
                  className='buttonLogin'>
                  Cancelar
                </button>
                <button
                  onClick = { this.updateUser }
                  className={ classBtn } >
                  Actualizar
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </Fragment>
    );
  }

  renderUpdateUserForm() {
    const { validates, userID, formValues, addClientSelected, removeClientSelected, addExtencionSelected, removeExtencionSelected, userInfo } = this.state;

    const { userList, clientList, extencionList } = this.props;

    const user = userList && userList.filter( user => user._id === userID );

    const formObj = [
      {
        text: 'Email',
        type: 'email',
        key:  'email',
        errorMessage: 'El correo debe tener el siguiente formato ejemplo@ejemplo',
      },
      {
        text: 'Nombre',
        type: 'text',
        key:  'name',
        errorMessage: 'El nombre no debe de estar bacio',
      },
      {
        text: 'Contraseña',
        type: 'password',
        key:  'password',
        errorMessage: 'La contraseña es requerida',
      },
    ];

    const filterclient = clientList.filter( selected => formValues.clientAssignment.every( client => selected.rfc !== client) );
    const filterExtencion = extencionList.filter( selected => formValues.extencionsAssignment.every( extension => selected.extension !== extension ));

    return (
      <div>
        {
          userList && user[0] &&
          <form autoComplete='off'>
            {
              formObj.map((obj, index)=> {
                const classInput = cx({
                  'addformGroup-error-add' : !validates[obj.key] && validates[obj.key] !== '' && validates[obj.key] !== undefined,
                });
                return (
                  <div className='addformGroupUser' key={index} >
                    <label className='text-dark'>
                      { obj.text }
                    </label>
                    {
                      obj.key === 'password' &&
                      <Fragment>
                        <p className='textPass'>* Por seguridad la contraseña actual no se muestra.</p>
                        <p className='textPass'>* Este campo puede estar vacio si no desea actualizarlo.</p>
                      </Fragment>
                    }
                    <input
                      className={ classInput }
                      type={ obj.type }
                      placeholder={ obj.text }
                      defaultValue={ user[0][obj.key] }
                      onBlur={ evt => this.validateField(evt, obj.key, obj.lengthField) }
                      onChange={ evt => this.updateInputValue(evt, obj.key) }/>
                    {
                      !validates[obj.key] && validates[obj.key] !== '' && validates[obj.key] !== undefined &&
                        <span className='formGroup-error'>
                          {
                            obj.errorMessage
                          }
                        </span>
                    }
                  </div>
                );
              })
            }
            <div>
              <div className='permisions'>
                <h4 className='text-red title'>Usuarios</h4>
                <div className='groupCheckBox'>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].userList}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'userList') }/>
                    <label className='text-dark'>Consultar</label>
                  </div>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].userAdd}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'userAdd') }/>
                    <label className='text-dark'>Agregar</label>
                  </div>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].userUpdate}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'userUpdate') }/>
                    <label className='text-dark'>Actualizar</label>
                  </div>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].userDrop}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'userDrop') }/>
                    <label className='text-dark'>Eliminar</label>
                  </div>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].userClick}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'userClick') }/>
                    <label className='text-dark'>Click Derecho</label>
                  </div>
                </div>
              </div>
              <div className='permisions'>
                <h4 className='text-red title'>Clientes</h4>
                <div className='groupCheckBox'>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].clientList}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'clientList') }/>
                    <label className='text-dark'>Consultar</label>
                  </div>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].clientAdd}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'clientAdd') }/>
                    <label className='text-dark'>Agregar</label>
                  </div>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].clientUpdate}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'clientUpdate') }/>
                    <label className='text-dark'>Actualizar</label>
                  </div>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].clientDrop}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'clientDrop') }/>
                    <label className='text-dark'>Eliminar</label>
                  </div>
                </div>
              </div>
              {
                clientList.length &&
                <div className='permisionsClient'>
                  <h4 className='text-red title'>Lista de clientes</h4>
                  <div className='wraptitleListClient'>
                    <div className='titleListClient'>No asignados</div>
                    <div className='spaceTitleListClient'></div>
                    <div className='titleListClient'>Asignados</div>
                  </div>
                  <div className='wrappSelectClients'>
                    <div className='listClient'>
                      {
                        filterclient.map( client => {
                          const classClient = cx({
                            'optionClient': true,
                            'selectedClient': client.rfc === addClientSelected,
                          });
                          return (
                            <div
                              key={client.rfc}
                              onClick={ () => this.addClient(client.rfc) }
                              className={classClient}>{ client.rfc }</div>
                          );
                        })
                      }
                    </div>
                    <div className='optionsListClient'>
                      <input
                        type='button'
                        value='>>'
                        onClick = { this.assignmentAll }
                        className={`buttonLogin ${!filterclient.length && 'disabled'}`}/>
                      <input
                        type='button'
                        value='>'
                        onClick = { this.assignment }
                        className={`buttonLogin ${(!filterclient.length || addClientSelected === '') && 'disabled'}`}/>
                      <input
                        type='button'
                        value='<'
                        onClick = { this.removed }
                        className={`buttonLogin ${(!formValues.clientAssignment.length || removeClientSelected === '') && 'disabled'}`}/>
                      <input
                        type='button'
                        value='<<'
                        onClick = { this.removedAll }
                        className={`buttonLogin ${!formValues.clientAssignment.length && 'disabled'}`}/>
                    </div>
                    <div className='listClient'>
                      {
                        formValues.clientAssignment.map( assignment => {
                          const classClient = cx({
                            'optionClient': true,
                            'selectedClient': assignment === removeClientSelected,
                          });

                          return (
                            <div
                              key={assignment}
                              onClick={ () => this.removeClient(assignment) }
                              className={classClient}>{ assignment }</div> );
                        })
                      }
                    </div>
                  </div>
                </div>
              }
              <div className='permisions'>
                <h4 className='text-red title'>Referencias</h4>
                <div className='groupCheckBox'>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].referenceList}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'referenceList') }/>
                    <label className='text-dark'>Consultar</label>
                  </div>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].referenceAdd}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'referenceAdd') }/>
                    <label className='text-dark'>Agregar</label>
                  </div>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].referenceUpdate}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'referenceUpdate') }/>
                    <label className='text-dark'>Actualizar</label>
                  </div>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].referenceDrop}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'referenceDrop') }/>
                    <label className='text-dark'>Eliminar</label>
                  </div>
                </div>
              </div>
              <div className='permisions'>
                <h4 className='text-red title'>Documentos</h4>
                <div className='groupCheckBox'>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].docList}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'docList') }/>
                    <label className='text-dark'>Consultar</label>
                  </div>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].docView}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'docView') }/>
                    <label className='text-dark'>Ver</label>
                  </div>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].docAdd}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'docAdd') }/>
                    <label className='text-dark'>Agregar</label>
                  </div>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].docDownload}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'docDownload') }/>
                    <label className='text-dark'>Descargar</label>
                  </div>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].docSend}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'docSend') }/>
                    <label className='text-dark'>Enviar</label>
                  </div>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].whatsAppSend}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'whatsAppSend') }/>
                    <label className='text-dark'>WhatsApp</label>
                  </div>
                  <div className='wrap-checbox'>
                    <input
                      defaultChecked={user[0].docDrop}
                      type='checkbox'
                      onChange={ evt => this.updateInputCheckBoxChecked(evt, 'docDrop') }/>
                    <label className='text-dark'>Eliminar</label>
                  </div>
                </div>
              </div>
              {
                <div className='permisionsClient'>
                  <h4 className='text-red title'>Lista de exntesiones</h4>
                  {
                    userInfo.userInfo.rol === 'admin' ?
                      <a href=''
                        onClick={this.addExtension}
                        className='d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm buttonAddSize'>
                        <i className='fas fa-sm text-white-50'></i> Extensiones</a>
                      : ''
                  }
                  <div className='wraptitleListClient'>
                    <div className='titleListClient'>No asignadas</div>
                    <div className='spaceTitleListClient'></div>
                    <div className='titleListClient'>Asignadas</div>
                  </div>
                  <div className='wrappSelectClients'>
                    <div className='listClient'>
                      {
                        filterExtencion.map( extencion => {
                          const classExtencion = cx({
                            'optionClient': true,
                            'selectedClient': extencion.extension === addExtencionSelected,
                          });
                          return (
                            <div
                              key={extencion.extension}
                              onClick={ () => this.addExtencion(extencion.extension) }
                              className={classExtencion}>{ extencion.extension }</div>
                          );
                        })
                      }
                    </div>
                    <div className='optionsListClient'>
                      <input
                        type='button'
                        value='>>'
                        onClick = { this.assignmentAllExtencion }
                        className={`buttonLogin ${!filterExtencion.length && 'disabled'}`}/>
                      <input
                        type='button'
                        value='>'
                        onClick = { this.assignmentExtencion }
                        className={`buttonLogin ${(!filterExtencion.length || addExtencionSelected === '') && 'disabled'}`}/>
                      <input
                        type='button'
                        value='<'
                        onClick = { this.removedExtencion }
                        className={`buttonLogin ${(!formValues.extencionsAssignment.length || removeExtencionSelected === '') && 'disabled'}`}/>
                      <input
                        type='button'
                        value='<<'
                        onClick = { this.removedAllExtencion }
                        className={`buttonLogin ${!formValues.extencionsAssignment.length && 'disabled'}`}/>
                    </div>
                    <div className='listClient'>
                      {
                        formValues.extencionsAssignment.map( assignment => {
                          const classClient = cx({
                            'optionClient': true,
                            'selectedClient': assignment === removeExtencionSelected,
                          });

                          return (
                            <div
                              key={assignment}
                              onClick={ () => this.removeExtencion(assignment) }
                              className={classClient}>{ assignment }</div> );
                        })
                      }
                    </div>
                  </div>
                </div>
              }
            </div>
          </form>
        }
      </div>
    );
  }

  addExtension = () => {
    const { history } = this.props;
    const { userID } = this.state;

    history.push(`/list-extension/${userID}`);
  }

  addExtencion = extencionSelected => {
    this.setState({
      addExtencionSelected: extencionSelected,
      removeExtencionSelected: '',
    });
  }

  removeExtencion = extensionSelected => {
    this.setState({
      removeExtencionSelected: extensionSelected,
      addExtencionSelected: '',
    });
  }

  assignmentExtencion = () => {
    const { addExtencionSelected, formValues } = this.state;

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        extencionsAssignment: [...formValues.extencionsAssignment, addExtencionSelected],
      },
      addExtencionSelected: '',
    }));
  }

  removedExtencion = () => {
    const { removeExtencionSelected, formValues } = this.state;
    const list = formValues.extencionsAssignment.filter( element => element !== removeExtencionSelected);

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        extencionsAssignment: list,
      },
    }));
  }

  assignmentAllExtencion = () => {
    const { extencionList } = this.props;

    const list = [];

    extencionList.forEach(element => 
      list.push(element.extension)
    );

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        extencionsAssignment: list,
      },
    }));
  }

  removedAllExtencion = () => {
    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        extencionsAssignment: [],
      },
      removeExtencionSelected: '',
    }));
  }

  addClient = clientSelected => {
    this.setState({
      addClientSelected: clientSelected,
      removeClientSelected: '',
    });
  }

  removeClient = clientSelected => {
    this.setState({
      removeClientSelected: clientSelected,
      addClientSelected: '',
    });
  }

  assignment = () => {
    const { addClientSelected, formValues } = this.state;

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        clientAssignment: [...formValues.clientAssignment, addClientSelected],
      },
      addClientSelected: '',
    }));
  }

  removed = () => {
    const { removeClientSelected, formValues } = this.state;
    const list = formValues.clientAssignment.filter( element => element !== removeClientSelected);

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        clientAssignment: list,
      },
    }));
  }

  assignmentAll = () => {
    const { clientList } = this.props;

    const list = [];

    clientList.forEach(element => 
      list.push(element.rfc)
    );

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        clientAssignment: list,
      },
    }));
  }

  removedAll = () => {
    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        clientAssignment: [],
      },
      removeClientSelected: '',
    }));
  }

  updateInputCheckBoxChecked(evt, key) {
    const inputValue = evt.target.checked;

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        [key]: inputValue,
      },
    }));
  }

  validateField(evt, key, lengthField) {
    const inputValue = evt.target.value;
    let validateOption = undefined;

    if (key === 'email') {
      validateOption = validateEmail(inputValue);
    } else {
      validateOption = validate(inputValue, lengthField);
    }

    this.setState(prevState => ({
      validates : {
        ...prevState.validates,
        [key] : validateOption,
      },
    }));
  }

  updateInputValue(evt, key) {
    const inputValue = evt.target.value;
    let result = '';

    if (key === 'password') {
      result = encodeURI(encrypt(inputValue));
    } else {
      result = inputValue;
    }

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        [key]: result,
      },
    }));
  }

  updateUser = () => {
    const { dispatch, history } = this.props;
    const { formValues, userInfo } = this.state;
    const userToken = userInfo && userInfo.token ? userInfo.token : null;

    // Metodo para agregar usuario
    dispatch(AduanasActions.actualizarUsuario(userToken, formValues));
    history.push('/list-users');
    dispatch(eventBtn('update'));
  }

  cancelUpdateUser = () => {
    const { history } = this.props;
    history.push('/list-users');
  }
}

UpdateUser.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(UpdateUser);