import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import * as AduanasActions from '../../actions/aduanas-api/aduanas-api-action-creators';
import * as ModalActionTypes from '../../actions/modal-handling/modal-action-creators';

const mapStateToProps = state => ({
  referenceList: state.dashboard.referenceList,
  clientID: state.dashboard.clientID,
});

class ListaReferenciasUsuario extends Component {
  render() {
    return (
      <div className='listaReferenciasUsuario'>
        {
          this.tablaReferencias()
        }
      </div>
    );
  }

  tablaReferencias = () => {
    const { referenceList } = this.props;
    const listaReferencias = [];

    if ( referenceList && referenceList.length > 0 ) {
      referenceList.map((reference, index) => {
        const objReference = {};
        objReference.id = reference.referenceID;
        objReference.name = reference.name;
        objReference.option = ([
          <div className='iconGroupClientTable' key={index}>
            <i
              className='fas fa-eye'
              title='ver Documentos'
              onClick = { () => this.verDocumentos(reference._id) }>
            </i>
          </div>]);
        listaReferencias.push(objReference);
      });
    }
    const data = {
      columns: [
        {
          label: '#',
          field: 'id',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Descripción',
          field: 'desc',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'Opciones',
          field: 'options',
          sort: 'disabled',
          width: '50px',
        },
      ],
      rows: listaReferencias,
    };
    if (referenceList && referenceList.length > 0) {
      return (
        <MDBDataTable
          bordered={true}
          data={data}
          entriesLabel='Número de Referencias'
          hover={true}
          infoLabel={['Mostrando', 'a', 'de', 'referencias']}
          paginationLabel={['Anterior', 'Siguiente']}
          responsive={true}
          searchLabel='Buscar Referencia'
          small={true}
          striped={true}
        />
      );
    }
  }

  verDocumentos = (referenceID) => {
    const { dispatch, clientID } = this.props;

    dispatch(ModalActionTypes.openModal(false));
    dispatch(AduanasActions.listaDocumentosUsuario( clientID, referenceID ));
  }
}

export default connect(mapStateToProps)(ListaReferenciasUsuario);