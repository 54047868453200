import React, { Component, Fragment } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import { connect } from 'react-redux';
import { encrypt, encodeURI, vercionBrowser } from '../utilities/commons';
import { eventBtn } from '../actions/actions';
import { setCookie, getCookie } from '../utilities/helpers';
import { ToastContainer, toast, Flip } from 'react-toastify';
import { validate, validateEmail } from '../utilities/validates';
import * as AduanasActions from '../actions/aduanas-api/aduanas-api-action-creators';
import cx from 'classnames';
import Footer from '../components/global/footer';
import Logo from '../img/logo.jpeg';
import logoMexico from '../img/hecho-en-mexico.png';
import Moment from 'moment';
import PropTypes from 'prop-types';

const mapStateToProps = state => ({
  isLogin: state.authentication.isLogin,
  msgLogin: state.authentication.msg,
  errLogin: state.authentication.err,
  userInfo: state.authentication.userInfo,
  token: state.authentication.token,
  loadingLogin: state.authentication.loadingLogin,
});

class Login extends Component {
  constructor() {
    super();

    this.state = {
      email: undefined,
      openPopoUp: false,
      password: undefined,
    };
  }

  componentWillMount() {
    const { dispatch, history } = this.props;

    const op = vercionBrowser();

    if ((op[0] === 'IE' || op[0] === 'Trident')) {
      // if (parseInt( op[1], 10 ) <= 7 ) {
      history.push('/download');
      // }
    }

    dispatch(eventBtn(undefined));
  }

  componentDidMount() {
    const { history } = this.props;
    if ( getCookie('userSession') ) {
      history.push('/list');
    }
  }

  componentWillReceiveProps(nextProps) {
    const { openPopoUp } = this.state;
    
    if (nextProps.msgLogin !== undefined && nextProps.errLogin !== undefined && openPopoUp) {
      this.notify(nextProps.msgLogin, nextProps.errLogin);
    }
  }

  componentDidUpdate(prevProps) {
    const { token, userInfo, history } = this.props;
    const expires = new Date();
    expires.setTime(expires.getTime() + (30 * 60 * 1000));

    if (prevProps.token !== token  && !getCookie('userSession')) {
      // Aquí va la acción a realizar después de que el login es exitoso
      setCookie('userSession', JSON.stringify({
        userInfo: userInfo,
        token: token,
        timeExpiration: Moment(expires).utc().format('HHmm.ss'),
      }), null, expires);

      // Redireccionamiento a Lista de Usuarios
      history.push('/list');
    }
  }

  render() {
    const {
      state:{
        email, password,
      },
    } = this;

    const validateBtn = cx({
      'buttonLogin' : true,
      'disabled' : !email || !password,
    });

    const validateInputEmail = cx({
      'formGroup' : true,
      'addformGroup-error' : !email && email !== undefined,
    });

    const validateInputPass = cx({
      'formGroup' : true,
      'addformGroup-error' : !password && password !== undefined,
    });

    return (
      <Fragment>
        <nav className='navbar navbar-expand navbar-light bg-MWL topbar static-top'>
          <div className='header-logo'>
            <img
              alt='logo'
              className='logo'
              src={ Logo } />
            <img
              alt='Hecho en México'
              className='logoMexico'
              src={ logoMexico } />
          </div>
        </nav>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnVisibilityChange={true}
          draggable={true}
          pauseOnHover={true}
        />
        {/* Same as */}
        <ToastContainer />
        <div className='loginMainSection bg-MWL'>
          <div className='signingSection border-left-red bg-white'>
            <div className='signingHeader'>
              <h3 className='loginHeader text-red title'>
                Acceso
              </h3>
            </div>
            <div className='signingBody'>
              <form
                onSubmit={ this.handleFormSubmit }
                autoComplete='off' >
                <div className={ validateInputEmail }>
                  <div className='userIcon'>
                    <span className='input-group-text'>
                      <i className='fas fa-user'></i>
                    </span>
                  </div>
                  <input
                    onBlur={ () => this.validateEmail('email') }
                    name='email'
                    placeholder='username'
                    ref='email'
                    type='text'
                  />
                </div>
                {
                  !email && email !== undefined &&
                    <span className='formGroup-error'>Campo Obligatorio</span>
                }
                <div className={ validateInputPass }>
                  <div className='userIcon'>
                    <span className='input-group-text'>
                      <i className='fas fa-key'></i>
                    </span>
                  </div>
                  <input
                    onBlur={ () => this.validatePass('password') }
                    onChange={ () => this.validatePass('password') }
                    name='password'
                    placeholder='password'
                    ref='password'
                    type='password'
                  />
                </div>
                {
                  !password && password !== undefined &&
                    <span className='formGroup-error'>Campo Obligatorio</span>
                }
                <input
                  type='submit'
                  className={ validateBtn }
                  value='Login'
                />
              </form>
            </div>
          </div>
        </div>
        <Footer/>
      </Fragment>
    );
  }

  handleFormSubmit = async e => {
    e.preventDefault();
    const {
      dispatch,
    } = this.props;

    const loginObj = {
      email : encodeURI(encrypt(this.refs.email.value)),
      password : encodeURI(encrypt(this.refs.password.value)),
    };

    this.setState({
      openPopoUp: true,
    });

    dispatch(AduanasActions.loginAduanas(loginObj));
  };

  validatePass = ref => {
    const field = this.refs[ref].value;

    this.setState({
      [ref] : validate(field),
    });
  }

  validateEmail = ref => {
    const field = this.refs[ref].value;

    this.setState({
      [ref] : validateEmail(field),
    });
  }

  toastId = null;

  notify = (message, err) => {
    let type = 'success';

    if (err) {
      type = 'error';
    }

    if (! toast.isActive(this.toastId)) {
      this.toastId = toast[type](message, {
        position: 'top-right',
        autoClose: 5000,
        transition: Flip,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isLogin: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  token: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  loadingLogin: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Login);