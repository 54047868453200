import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getCookie } from '../utilities/helpers';
import { validate, validateCharacters } from '../utilities/validates';
import * as AduanasActions from '../actions/aduanas-api/aduanas-api-action-creators';
import { eventBtn } from '../actions/actions';
import cx from 'classnames';
import Header from '../components/global/header';
import PropTypes from 'prop-types';
import queryString from 'query-string';

const mapStateToProps = state => ({
  errorCreateReference: state.reference.errorCreateReference,
  isReferenceCreated: state.reference.isReferenceCreated,
  loadingCreateReference: state.reference.loadingCreateReference,
  loadingSignup: state.authentication.loadingSignup,
  referenceList: state.reference.referenceListAll,
  token: state.authentication.token,
  userInfo: state.authentication.userInfo,
});

class AddReference extends Component {
  constructor(props) {
    super(props);

    this.sendReferenceForm = this.sendReferenceForm.bind(this);
    this.cancelAddReference = this.cancelAddReference.bind(this);

    const queryParams = queryString.parse(props.location.search);

    const formValues = {
      description: '',
      name: '',
      referenceID: '',
    };

    this.state = {
      clientID: queryParams && queryParams.client ? queryParams.client : null,
      formValues: formValues,
      messageId: 'Id de Referencia Requerido',
      userInfo: getCookie('userSession'),
      validates: formValues,
    };
  }

  componentWillMount() {
    const { history, dispatch } = this.props;
    const { userInfo, clientID } = this.state;
    if ( userInfo === null ) {
      history.push('/');
      return;
    }
    dispatch(AduanasActions.listaTodasReferencias(userInfo.token));
    dispatch(AduanasActions.listaReferencias(userInfo.token, clientID));
  }

  componentWillReceiveProps(nextProps) {
    const { isReferenceCreated, history } = this.props;
    const { clientID } = this.state;
    if (isReferenceCreated !== nextProps.isReferenceCreated && nextProps.isReferenceCreated) {
      history.push(`/reference?client=${clientID}`);
    }
  }

  render() {
    const {
      props: {
        dispatch,
      },
      state: {
        validates,
      },
    } = this;

    const ObjectValues = Object.values(validates);
    let classActive = undefined;

    ObjectValues.map( element => {
      if ( !element || element === '') {
        classActive = false;
      } else {
        classActive = true;
      }
    });

    const classBtn = cx({
      'buttonLogin' : true,
      'disabled' : !classActive,
    });

    return (
      <Fragment>
        <Header dispatch={ dispatch }/>
        <div className='loginMainSection bg-MWL'>
          <div className='addSection border-left-red bg-white'>
            <div className='addHeader text-red'>
              <h3 className='loginHeader title'>
                Agregar Referencia
              </h3>
            </div>
            <div className='addBody'>
              { this.renderAddClientForm() }
              <div className='addFooter'>
                <button
                  onClick = { this.cancelAddReference }
                  className='buttonLogin'>
                  Cancelar
                </button>
                <button
                  onClick = { this.sendReferenceForm }
                  className={ classBtn } >
                  Agregar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  renderAddClientForm() {
    const { validates, messageId, formValues } = this.state;
    const { referenceList } = this.props;
    const ids = referenceList.length && this.createAvailableIds(referenceList);

    const formObj = [
      {
        text: 'Referencia',
        type: '',
        key:  'referenceID',
        errorMessage: messageId,
      },
      {
        text: 'Referencia',
        type: 'text',
        key:  'referenceID',
        errorMessage: messageId,
      },
      {
        text: 'Nombre',
        type: 'text',
        key:  'name',
        errorMessage: 'Nombre de Referencia Requerido',
      },
      {
        text: 'Descripción',
        type: 'text',
        key:  'description',
        errorMessage: 'Descripción de Referencia Requerido',
      },
    ];

    return (
      <div>
        <form autoComplete='off'>
          {
            formObj.map((obj, index)=> {
              const classInput = cx({
                'addformGroup-error-add' : !validates[obj.key] && validates[obj.key] !== '' && validates[obj.key] !== undefined,
              });

              return (
                <div className='addformGroupUser' key={index} >
                  {
                    obj.type.length ?
                      obj.key === 'referenceID' ?
                        formValues.select === 'Otro' &&
                        <Fragment>
                          <label className='text-dark'>
                            { obj.text }
                          </label>
                          <input
                            className={ classInput }
                            type={ obj.type }
                            placeholder={ obj.text }
                            onBlur={ evt => this.validateField(evt, obj.key, obj.lengthField) }
                            onChange={ evt => this.updateInputValue(evt, obj.key) }/>
                        </Fragment>
                        :
                        <Fragment>
                          <label className='text-dark'>
                            { obj.text }
                          </label>
                          <input
                            className={ classInput }
                            type={ obj.type }
                            placeholder={ obj.text }
                            onBlur={ evt => this.validateField(evt, obj.key, obj.lengthField) }
                            onChange={ evt => this.updateInputValue(evt, obj.key) }/>
                        </Fragment>
                      :
                      ids && formValues.select !== 'Otro' &&
                        <Fragment>
                          <label className='text-dark'>
                            { obj.text }
                          </label>
                          <select onChange={ evt => {
                            this.updateInputValue(evt, 'select');
                            this.validateField(evt, obj.key, obj.lengthField);
                          }}>
                            <option>Selecciona una Referencia</option>
                            {
                              ids.map( id => <option key={ id }>{ 'MWL' + id }</option>)
                            }
                            <option>Otro</option>
                          </select>
                        </Fragment>
                  }
                  {
                    !validates[obj.key] && validates[obj.key] !== '' && validates[obj.key] !== undefined &&
                      <span className='formGroup-error'>
                        {
                          obj.errorMessage
                        }
                      </span>
                  }
                </div>
              );
            })
          }
        </form>
      </div>
    );
  }

  createAvailableIds = ids => {
    const idsUnavailable = [];
    const idsAvailable = [];
    // eslint-disable-next-line no-unused-vars
    let n = undefined;

    for (let i = 0; i < ids.length; i++) {
      const algo = ids[i].referenceID.replace('MWL', '');
      if (!isNaN(parseInt(algo, 10))) {
        idsUnavailable.push(parseInt(algo, 10));
      }
    }
    n = idsUnavailable[0] ? idsUnavailable[0] : 1;    
    idsAvailable.push( (idsUnavailable[ idsUnavailable.length - 1 ]) + 1);

    /* if (ids.length) {
      for (let i = 0; i < 5; i++ ) {
        if (idsUnavailable.indexOf(n) === -1) {
          const res = `${n}`;
          if (res.length < 5) {
            let wrap = '';
            for (let j = 0; j < (5 - res.length); j++) {
              wrap = `${wrap}0`;
            }
            idsAvailable.push(`${wrap}${n}`);
          } else {
            idsAvailable.push(n);
          }
        } else {
          i--;
        }
        n += 1;
      }
    } */

    return idsAvailable;
  }

  updateInputValue(evt, key) {
    const inputValue = evt.target.value;

    if (key === 'select') {
      this.setState(prevState => ({
        formValues: {
          ...prevState.formValues,
          'referenceID': inputValue,
        },
      }));
    }

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        [key]: inputValue,
      },
    }));
  }

  sendReferenceForm() {
    const { dispatch } = this.props;
    const { formValues, userInfo, clientID } = this.state;
    const userToken = userInfo && userInfo.token ? userInfo.token : null;
    // Metodo para agregar referencias
    dispatch(AduanasActions.addReference(userToken, clientID, formValues));
    dispatch(eventBtn('add'));
  }

  cancelAddReference() {
    const { history } = this.props;
    const { clientID } = this.state;
    history.push(`/reference?client=${clientID}`);
  }

  validateField(evt, key, lengthField) {
    const { messageId } = this.state;
    const inputValue = evt.target.value;
    let validateOption = undefined;

    let msg = '';

    if (key === 'referenceID') {
      validateOption = validateCharacters(inputValue);

      if (!validateOption) {
        msg = 'No debe de contener ninguno caracter especial.';
      } else if ( messageId !== 'Id de Referencia Requerido' ) {
        msg = 'Id de Referencia Requerido.';
      }
      if (inputValue === '' || inputValue === 'Otro') {
        validateOption = false;
        msg = 'Id de Referencia Requerido.';
      }

      this.setState({
        messageId : msg,
      });
    } else {
      validateOption = validate(inputValue, lengthField);
    }

    this.setState(prevState => ({
      validates : {
        ...prevState.validates,
        [key] : validateOption,
      },
    }));
  }
}

AddReference.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AddReference);