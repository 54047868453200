import { EVENT } from '../actions/actions-types';

const initialState = {
  action: undefined,
};

const ActionPopUp = (state = initialState, action) => {
  switch (action.type) {
    case EVENT:
      return {
        ...state,
        action: action.data,
      };
    default:
      return state;
  }
};

export default ActionPopUp;