/**
 * Do you want new async actions?
 * Add new codes here.
 *
 * This module will generate async action types for all of them.
 */
const allAsyncActionTypesCodes = [
  'ADUANAS_ACTUALIZA_CLIENTE',
  'ADUANAS_ACTUALIZA_REFERENCIA',
  'ADUANAS_ACTUALIZA_USUARIO',
  'ADUANAS_ADD_DOCUMENTS',
  'ADUANAS_ADD_REFERENCIA',
  'ADUANAS_CLIENT_INFO',
  'ADUANAS_DASHBOARD_INFO',
  'ADUANAS_DASHBOARD_LIST_DOCUMENS',
  'ADUANAS_DELETE_DOCUMENTS',
  'ADUANAS_DOWNLOAD_DOCUMENTS',
  'ADUANAS_ELIMINA_CLIENTE',
  'ADUANAS_ELIMINA_EXTENSION',
  'ADUANAS_ELIMINA_REFERENCIA',
  'ADUANAS_ELIMINA_USUARIO',
  'ADUANAS_GET_REFERENCE',
  'ADUANAS_LIST_DOCUMENTS',
  'ADUANAS_LISTA_CLIENTES',
  'ADUANAS_LISTA_EXTENCIONES',
  'ADUANAS_LISTA_REFERENCIAS_TODAS',
  'ADUANAS_LISTA_REFERENCIAS_USUARIO',
  'ADUANAS_LISTA_REFERENCIAS',
  'ADUANAS_LISTA_USERS',
  'ADUANAS_LOGIN',
  'ADUANAS_REGISTRA_CLIENTE',
  'ADUANAS_REGISTRA_EXTENCION',
  'ADUANAS_REGISTRA_USER',
  'ADUANAS_SEND_EMAIL',
  'ADUANAS_SEND_WHATSAPP',
];

/**
 * An async action type is not a string literal, as regular action types.
 * It's an object with START, SUCCESS and FAIL properties, each of it is a string literal
 * representing a typical redux action type.
 */
export function createAsyncActionType(name) {
  if (typeof name !== 'string') {
    console.warn(`Multiple async action middleware expected a string, instead recieved ${typeof name}`); // eslint-disable-line no-console
  }
  return {
    START: name,
    SUCCESS: name + '_SUCCESS',
    FAIL: name + '_FAIL',
  };
}

/**
 * Generated map has the shape
 * {
 *   code: {
 *    START: 'code_START',
 *    SUCCESS: 'code_SUCCESS',
 *    FAIL: 'code_FAIL'
 *   }
 * }
 */
const asyncActionTypes = {};
for (const code of allAsyncActionTypesCodes) {
  asyncActionTypes[code] = createAsyncActionType(code);
}

/**
 * A helper function for error handling.
 * Returns an array of all FAIL action codes.
 */
export function getAllAsyncFailCodes() {
  return Object.getOwnPropertyNames(asyncActionTypes).map(type => {
    return asyncActionTypes[type].FAIL;
  });
}

export default asyncActionTypes;
