import errorCodes, { actionableCodes } from './error-codes';

export function getIncidentFromFatalError(errorCode, actionType, errorMsg) {
  return {
    isFatal: true,
    code: errorCode,
    actionable: actionableCodes.ABORT,
    actionType,
    errorMsg,
  };
}

export function getIncidentFromAPI(apiError, actionType) {
  const errorCode = apiError && apiError.code ? apiError.code : errorCodes.UNKNOWN_API_ERROR;
  const actionable = apiError && apiError.actionable ? apiError.actionable : actionableCodes.ABORT;
  const isFatal = apiError && apiError.isFatal ? apiError.isFatal : false;
  const errorMsg = apiError && apiError.msg;
  const extraInfo = apiError && apiError.extraInfo;
  return {
    isFatal: isFatal,
    code: errorCode,
    actionable: actionable,
    status: apiError.responseStatus,
    actionType,
    errorMsg,
    extraInfo,
  };
}

export function getErrorMessage(error, action) {
  let errorMsg = error;

  if (error && error.message) {
    errorMsg = error.message;

    if (error.description) { errorMsg += ` | ${error.description}`; }
    if (error.number) { errorMsg += ` | ${error.number}`; }
    if (error.fileName) { errorMsg += ` | ${error.fileName}`; }
    if (error.lineNumber) { errorMsg += ` | ${error.lineNumber}`; }
    if (error.columnNumber) { errorMsg += ` | ${error.columnNumber}`; }
    if (error.stack) { errorMsg += ` | ${error.stack}`; }
  }

  errorMsg += ` | action: ${JSON.stringify(action)}`;
  errorMsg += ` | ${window.location.href}`;

  return errorMsg;
}
