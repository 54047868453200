import aduanasActionTypes from '../actions/aduanas-api/aduanas-api-action-types';

const initialState = {
  documentsList: [],
  err: undefined,
  errorDeleteDocument: {},
  errorListDocuments: {},
  errorUploadDocuments: {},
  isDocumentAdded: false,
  isDocumentDelete: false,
  loadingDeleteDocument: false,
  loadingListDocuments: false,
  loadingUploadDocuments: false,
  msg: undefined,
};

const actionsMap = {
  [aduanasActionTypes.ADUANAS_ADD_DOCUMENTS.START]: (state) => {
    return {
      ...state,
      errorUploadDocuments: {},
      isDocumentAdded: false,
      loadingUploadDocuments: true,
    };
  },

  [aduanasActionTypes.ADUANAS_ADD_DOCUMENTS.SUCCESS]: (state, action) => {
    return {
      ...state,
      msg: action.result.message,
      errorUploadDocuments: {},
      isDocumentAdded: action.result.success,
      loadingUploadDocuments: false,
    };
  },

  [aduanasActionTypes.ADUANAS_ADD_DOCUMENTS.FAIL]: (state, action) => {
    return {
      ...state,
      msg: action.result.message,
      err: action.result.err,
      errorUploadDocuments: action.result,
      isDocumentAdded: false,
      loadingUploadDocuments: false,
    };
  },

  [aduanasActionTypes.ADUANAS_LIST_DOCUMENTS.START]: (state) => {
    return {
      ...state,
      documentsList: [],
      errorListDocuments: {},
      loadingListDocuments: true,
    };
  },

  [aduanasActionTypes.ADUANAS_LIST_DOCUMENTS.SUCCESS]: (state, action) => {
    return {
      ...state,
      documentsList: action.result,
      errorListDocuments: {},
      loadingListDocuments: false,
    };
  },

  [aduanasActionTypes.ADUANAS_LIST_DOCUMENTS.FAIL]: (state, action) => {
    return {
      ...state,
      documentsList: [],
      errorListDocuments: action.result,
      loadingListDocuments: false,
    };
  },

};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  if (!fn) {
    return state;
  }
  return fn(state, action);
}
