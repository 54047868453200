import aduanasActionTypes from '../actions/aduanas-api/aduanas-api-action-types';

const initialState = {
  errAdd: undefined,
  errorCreateReference: {},
  errorListReference: {},
  errorListReferenceAll: {},
  errorReferenceInfo: {},
  errUpdate: undefined,
  isReferenceCreated: false,
  loadingCreateReference: false,
  loadingReference: false,
  loadingReferenceInfo: false,
  msgAdd: undefined,
  msgUpdate: undefined,
  referenceInfo: {},
  referenceList: [],
  referenceListAll: [],
  isUpdateReference: undefined,
};

const actionsMap = {
  [aduanasActionTypes.ADUANAS_LISTA_REFERENCIAS.START]: (state) => {
    return {
      ...state,
      loadingReference: true,
      referenceList: [],
      errorListReference: {},
    };
  },

  [aduanasActionTypes.ADUANAS_LISTA_REFERENCIAS.SUCCESS]: (state, action) => {
    return {
      ...state,
      loadingReference: false,
      referenceList: action.result.referenceList,
      errorListReference: {},
    };
  },

  [aduanasActionTypes.ADUANAS_LISTA_REFERENCIAS.FAIL]: (state, action) => {
    return {
      ...state,
      loadingReference: false,
      referenceList: [],
      errorListReference: action.result,
    };
  },
  [aduanasActionTypes.ADUANAS_LISTA_REFERENCIAS_TODAS.START]: (state) => {
    return {
      ...state,
      loadingReference: true,
      referenceListAll: [],
      errorListReferenceAll: {},
    };
  },

  [aduanasActionTypes.ADUANAS_LISTA_REFERENCIAS_TODAS.SUCCESS]: (state, action) => {
    return {
      ...state,
      loadingReference: false,
      referenceListAll: action.result.referenceList,
      errorListReferenceAll: {},
    };
  },

  [aduanasActionTypes.ADUANAS_LISTA_REFERENCIAS_TODAS.FAIL]: (state, action) => {
    return {
      ...state,
      loadingReference: false,
      referenceListAll: [],
      errorListReferenceAll: action.result,
    };
  },

  [aduanasActionTypes.ADUANAS_ADD_REFERENCIA.START]: (state) => {
    return {
      ...state,
      errAdd: undefined,
      errorCreateReference: {},
      isReferenceCreated: false,
      loadingCreateReference: true,
      msgAdd: undefined,
      msgUpdate: undefined,
    };
  },

  [aduanasActionTypes.ADUANAS_ADD_REFERENCIA.SUCCESS]: (state, action) => {
    return {
      ...state,
      errAdd: undefined,
      errorCreateReference: {},
      isReferenceCreated: action.result.success,
      loadingCreateReference: false,
      msgAdd: action.result.message,
    };
  },

  [aduanasActionTypes.ADUANAS_ADD_REFERENCIA.FAIL]: (state, action) => {
    return {
      ...state,
      errAdd: action.result.err,
      errorCreateReference: action.result,
      isReferenceCreated: false,
      loadingCreateReference: false,
      msgAdd: action.result.message,
    };
  },

  [aduanasActionTypes.ADUANAS_ACTUALIZA_REFERENCIA.START]: (state) => {
    return {
      ...state,
      isUpdateReference: false,
      errUpdate: undefined,
      msgUpdate: undefined,
      msgAdd: undefined,
    };
  },

  [aduanasActionTypes.ADUANAS_ACTUALIZA_REFERENCIA.SUCCESS]: (state, action) => {
    return {
      ...state,
      errUpdate: undefined,
      isUpdateReference: action.result.update,
      msgUpdate: action.result.message,
    };
  },

  [aduanasActionTypes.ADUANAS_ACTUALIZA_REFERENCIA.FAIL]: (state, action) => {
    return {
      ...state,
      errUpdate: action.result.err,
      isUpdateReference: action.result.update,
      msgUpdate: action.result.message,
    };
  },

  [aduanasActionTypes.ADUANAS_GET_REFERENCE.START]: (state) => {
    return {
      ...state,
      errorReferenceInfo: {},
      loadingReferenceInfo: true,
      referenceInfo: {},
    };
  },

  [aduanasActionTypes.ADUANAS_GET_REFERENCE.SUCCESS]: (state, action) => {
    return {
      ...state,
      errorReferenceInfo: {},
      loadingReferenceInfo: false,
      referenceInfo: action.result,
    };
  },

  [aduanasActionTypes.ADUANAS_GET_REFERENCE.FAIL]: (state/* , action */) => {
    return {
      ...state,
      errorReferenceInfo: {},
      loadingReferenceInfo: false,
      referenceInfo: {},
    };
  },

};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  if (!fn) {
    return state;
  }
  return fn(state, action);
}
