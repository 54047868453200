import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openModal } from '../../actions/modal-handling/modal-action-creators';
// Utilities
import { getCookie } from '../../utilities/helpers';

class ModalConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: getCookie('userSession'),
    };
  }
  render() {
    const { _id, name } = this.props.value;

    return (
      <div className='modalInput'>
        <h2>{`¿Desea eliminar al usuario ${name}?`}</h2>
        <div className='wrapperButtonModal'>
          <button
            onClick={ () => this.deleteUser(_id) }
            className='addListBtn'>
            Eliminar
          </button>
          <button
            onClick={ () => this.cancel() }
            className='addListBtn'>
            Cancelar
          </button>
        </div>
      </div>
    );
  }

  cancel = () => {
    const { dispatch } = this.props;

    dispatch(openModal(false));
  }

  deleteUser = id => {
    const { dispatch, action, popUpOpen } = this.props;
    const { userInfo } = this.state;
    const userToken = userInfo.token;
    if ( userToken ) {
      dispatch(action(userToken, id));
    }
    dispatch(openModal(false));
    popUpOpen();
  }
}

export default connect(null)(ModalConfirm);