import React, { Component } from 'react';
import { connect } from 'react-redux';
// Utilities
import { getCookie } from '../../utilities/helpers';

import { openModal } from '../../actions/modal-handling/modal-action-creators';
import { deleteDocuments } from '../../actions/aduanas-api/aduanas-api-action-creators';

class ModalDeleteDocument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: getCookie('userSession'),
    };
  }

  render() {
    const { _id, name } = this.props.value;
    return (
      <div className='modalInput'>
        <h2>{`¿Desea eliminar el documento ${name}?`}</h2>
        <div className='wrapperButtonModal'>
          <button
            onClick={ () => this.deleteDocument(_id) }
            className='addListBtn'>
            Eliminar
          </button>
          <button
            onClick={ () => this.cancel() }
            className='addListBtn'>
            Cancelar
          </button>
        </div>
      </div>
    );
  }

  cancel = () => {
    const { dispatch } = this.props;

    dispatch(openModal(false));
  }

  deleteDocument = () => {
    const { dispatch, value, referenceInfo, popUpOpen  } = this.props;
    const { userInfo } = this.state;
    const userToken = userInfo.token;

    const obj = {
      clientID: referenceInfo.clientId,
      referenceID: referenceInfo._id,
      documentID: value._id,
    };

    dispatch(deleteDocuments(userToken, obj));
    dispatch(openModal(false));
    popUpOpen();
  }
}

export default connect(null)(ModalDeleteDocument);