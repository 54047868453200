import React, { Component } from 'react';
import { openModal } from '../../actions/modal-handling/modal-action-creators';

class Modal extends Component {
  componentWillMount() {
    document.body.classList.add('Modal-open--body');
  }

  componentWillUnmount() {
    document.body.classList.remove('Modal-open--body');
  }

  render() {
    const { component } = this.props;

    return (
      <div className='modal'>
        <div className='modal-wrapper'>
          <div className='modal-icon-close'>
            <div onClick={ () => this.closeModal() } className='modal-close'>
              X
            </div>
          </div>
          <div className='modal-wrapper-component'>
            { component }
          </div>
        </div>
      </div>
    );
  }
  closeModal = () => {
    const { dispatch } = this.props;
    dispatch(openModal(false));
  }
}
export default Modal;