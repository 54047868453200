import React from 'react';
import Moment from 'moment';

const Footer = () => {
  return (
    <footer className='lg-12 bg-elegant-color-dark p-2'>
      <h6>Conoce nuestra <a href='https://mwl.com.mx/home/politica-de-calidad.html' target='_blank'>política de Calidad</a></h6>
      <h6 className=''>
        {`Todos los derechos reservados, ${ Moment().format('YYYY') }.`}
      </h6>
      <div className='sello'>
        <img src='https://seal.godaddy.com/images/3/es/siteseal_gd_3_h_l_m.gif' />
        <span id='siteseal'><script type='text/javascript' 
          src='https://seal.godaddy.com/getSeal?sealID=mSLTo95QKD6ktZvQH1R88YacYJZVoK1XIlNWzJMcFOwfJXLoZDokwo2ZhdLW'></script></span>
      </div>
    </footer>
  );
};

export default Footer;