import aduanasActionTypes from '../actions/aduanas-api/aduanas-api-action-types';

const initialState = {
  clientID: undefined,
  dashboardDocs: [],
  error: false,
  errorDashboardDocs: {},
  loadingDashboardDocs: false,
  msg: undefined,
  openList: false,
  referenceList: [],
};

const actionsMap = {
  [aduanasActionTypes.ADUANAS_DASHBOARD_INFO.START]: (state) => {
    return {
      ...state,
      dashboardDocs: [],
      errorDashboardDocs: {},
      loadingDashboardDocs: true,
      msg: undefined,
    };
  },

  [aduanasActionTypes.ADUANAS_DASHBOARD_INFO.SUCCESS]: (state, action) => {
    let result = undefined;

    if (action.result.length === 0) {
      result = 'No hay Documentos en esta referencia';
    }

    return {
      ...state,
      dashboardDocs: action.result,
      errorDashboardDocs: {},
      loadingDashboardDocs: false,
      msg: result,
    };
  },

  [aduanasActionTypes.ADUANAS_DASHBOARD_INFO.FAIL]: (state, action) => {
    return {
      ...state,
      dashboardDocs: [],
      errorDashboardDocs: action.result,
      loadingDashboardDocs: false,
      msg: undefined,
    };
  },

  [aduanasActionTypes.ADUANAS_DASHBOARD_LIST_DOCUMENS.START]: (state) => {
    return {
      ...state,
      dashboardDocs: [],
      errorDashboardDocs: {},
      loadingDashboardDocs: true,
      msg: undefined,
    };
  },

  [aduanasActionTypes.ADUANAS_DASHBOARD_LIST_DOCUMENS.SUCCESS]: (state, action) => {
    let result = undefined;

    if (action.result.length === 0) {
      result = 'No hay Documentos en esta referencia';
    }

    return {
      ...state,
      dashboardDocs: action.result,
      errorDashboardDocs: {},
      loadingDashboardDocs: false,
      msg: result,
    };
  },

  [aduanasActionTypes.ADUANAS_DASHBOARD_LIST_DOCUMENS.FAIL]: (state, action) => {
    return {
      ...state,
      dashboardDocs: [],
      errorDashboardDocs: action.result,
      loadingDashboardDocs: false,
      msg: undefined,
    };
  },
  [aduanasActionTypes.ADUANAS_LISTA_REFERENCIAS_USUARIO.START]: (state) => {
    return {
      ...state,
      dashboardDocs: [],
      error: false,
      openList: false,
      msg: undefined,
    };
  },

  [aduanasActionTypes.ADUANAS_LISTA_REFERENCIAS_USUARIO.SUCCESS]: (state, action) => {
    let validateError  = false;
    let validateMsg  = undefined;
    let validateList = action.result.referenceList;
    let validateOpenList = true;

    if (!action.result.referenceList.length) {
      validateError = true;
      validateMsg = 'El usuario no cuenta con referencias';
      validateList = [];
      validateOpenList = false;
    }

    return {
      ...state,
      error: validateError,
      openList: validateOpenList,
      referenceList: validateList,
      clientID: action.result.clienteID,
      msg: validateMsg,
    };
  },

  [aduanasActionTypes.ADUANAS_LISTA_REFERENCIAS_USUARIO.FAIL]: (state, action) => {
    return {
      ...state,
      error: action.result,
      openList: false,
      msg: undefined,
    };
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  if (!fn) {
    return state;
  }
  return fn(state, action);
}
