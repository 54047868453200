export default function debuggingMiddleware(store) {
  return (next) => (action) => {
    const state = store.getState();                   // eslint-disable-line no-unused-vars

    /**
     * Want to debug the state? Uncomment these lines.
     */

    // debugger;
    // console.log('debuggingMiddleware :: STATE: '); // eslint-disable-line no-console
    // console.log(state);                            // eslint-disable-line no-console

    return next(action);
  };
}
