import aduanasActionTypes from '../actions/aduanas-api/aduanas-api-action-types';

const initialState = {
  clientInfo: {},
  errorClientInfo: {},
  loadingClientInfo: false,
  loadingUsers: false,
  usersList: [],
};

const actionsMap = {
  [aduanasActionTypes.ADUANAS_LISTA_CLIENTES.START]: (state) => {
    return {
      ...state,
      loadingUsers: true,
    };
  },

  [aduanasActionTypes.ADUANAS_LISTA_CLIENTES.SUCCESS]: (state, action) => {
    return {
      ...state,
      loadingUsers: false,
      usersList: action.result.usersList,
    };
  },

  [aduanasActionTypes.ADUANAS_LISTA_CLIENTES.FAIL]: (state) => {
    return {
      ...state,
      loadingUsers: false,
      usersList: [],
    };
  },

  [aduanasActionTypes.ADUANAS_CLIENT_INFO.START]: (state) => {
    return {
      ...state,
      clientInfo: {},
      errorClientInfo: {},
      loadingClientInfo: true,
    };
  },

  [aduanasActionTypes.ADUANAS_CLIENT_INFO.SUCCESS]: (state, action) => {
    return {
      ...state,
      clientInfo: action.result,
      errorClientInfo: {},
      loadingClientInfo: false,
    };
  },

  [aduanasActionTypes.ADUANAS_CLIENT_INFO.FAIL]: (state, action) => {
    return {
      ...state,
      clientInfo: {},
      errorClientInfo: action.result,
      loadingClientInfo: false,
    };
  },

};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  if (!fn) {
    return state;
  }
  return fn(state, action);
}
