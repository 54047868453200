import React, { Component, Fragment } from 'react';
/* import { MDBDataTable } from 'mdbreact'; */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utilities
import 'react-toastify/dist/ReactToastify.min.css';
import { getCookie } from '../utilities/helpers';
import { ToastContainer/* , toast, Flip */ } from 'react-toastify';
/* import { encrypt, encodeURI } from '../utilities/commons'; */

// Acciones
import * as AduanasActions from '../actions/aduanas-api/aduanas-api-action-creators';
/* import * as ModalActionTypes from '../actions/modal-handling/modal-action-creators'; */
import ModalConfirmUser from '../components/global/modalConfirmUser';
import cx from 'classnames';
import { eventBtn } from '../actions/actions';

import Footer from '../components/global/footer';
import Header from '../components/global/header';
import Modal from '../components/global/modal';

const mapStateToProps = state => ({
  addExtencion: state.extencions.addExtencion,
});

class AddExtensions extends Component {
  constructor(props) {
    super(props);

    const formValues = {
      ext: '',
      desc: '',
    };

    this.state = {
      formValues: formValues,
      userInfo: getCookie('userSession'),
      validates: formValues,
    };
  }

  componentWillMount() {
    const { history } = this.props;
    const { userInfo } = this.state;
    if ( userInfo === null ) {
      history.push('/');
      return;
    }
  }

  componentWillReceiveProps(nextProps) {
    const { addExtencion } = this.props;

    if (nextProps.addExtencion !== addExtencion) {
      nextProps.history.goBack();
    }
  }

  render() {
    const {
      isOpen,
      dispatch,
    } = this.props;

    const {
      selectedUser,
      /* userInfo, */
    } = this.state;

    const classBtn = cx({
      'buttonLogin' : true,
      // 'disabled' : !classActive,
    });    

    return (
      <Fragment>
        <Header dispatch={ dispatch }/>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnVisibilityChange={true}
          draggable={true}
          pauseOnHover={true}
        />
        <ToastContainer />
        <div className='loginMainSection bg-MWL'>
          { isOpen &&
            <Modal
              dispatch = { dispatch }
              component = {
                <ModalConfirmUser
                  value = { selectedUser }
                  popUpOpen={ this.popUpOpen }
                  action={AduanasActions.eliminarUsuario}
                  dispatch={ dispatch } />
              }
            />
          }
          <div className='addSection border-left-red bg-white'>
            <div className='d-sm-flex align-items-center justify-content-between mb-4 text-red'>
              <h3 className='loginHeader title'>Agregar Extensiones</h3>
            </div>
            <div className='addBody'>
              { this.renderAddExtensionForm() }
              <div className='addFooter'>
                <button
                  onClick = { this.cancelAddExtension }
                  className='buttonLogin'>
                  Cancelar
                </button>
                <button
                  className={ classBtn }
                  onClick = { this.sendExtForm }>
                  Agregar
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </Fragment>
    );
  }

  cancelAddExtension = () => {
    const { history } = this.props;
    history.goBack();
  }

  sendExtForm = () => {
    const { dispatch } = this.props;
    const { formValues, userInfo } = this.state;
    const userToken = userInfo && userInfo.token ? userInfo.token : null;

    // Metodo para agregar usuario
    dispatch(AduanasActions.registraExtencion(userToken, formValues));
    dispatch(eventBtn('add'));
  }

  updateInputValue(evt, key) {
    const inputValue = evt.target.value;

    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        [key]: inputValue,
      },
    }));
  }

  renderAddExtensionForm = () => {
    const { validates } = this.state;
    const formObj = [
      {
        text: 'Extension',
        type: 'text',
        key:  'ext',
        errorMessage: 'No debe de ser vacío este campo',
      },
      {
        text: 'Descripción',
        type: 'text',
        key:  'desc',
        errorMessage: 'No debe de ser vacío este campo',
      },
    ];
    return (
      <div>
        <form autoComplete='off'>
          {
            formObj.map((obj, index)=> {
              const classInput = cx({
                'addformGroup-error-add' : !validates[obj.key] && validates[obj.key] !== '' && validates[obj.key] !== undefined,
              });

              return (
                <div className='addformGroupUser' key={index} >
                  <label className='text-dark'>
                    { obj.text }
                  </label>
                  <input
                    className={ classInput }
                    type={ obj.type }
                    placeholder={ obj.text }
                    // onBlur={ evt => this.validateField(evt, obj.key, obj.lengthField) }
                    onChange={ evt => this.updateInputValue(evt, obj.key) }
                  />
                  {
                    !validates[obj.key] && validates[obj.key] !== '' && validates[obj.key] !== undefined &&
                      <span className='formGroup-error'>
                        {
                          obj.errorMessage
                        }
                      </span>
                  }
                </div>
              );
            })
          }
        </form>
      </div>
    );
  }
}

AddExtensions.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AddExtensions);