import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { MDBDataTable } from 'mdbreact';
import moment from 'moment';

// Utilities
import 'react-toastify/dist/ReactToastify.min.css';
import { getCookie } from '../utilities/helpers';
import { ToastContainer, toast, Flip } from 'react-toastify';

// Acciones
import { eventBtn } from '../actions/actions';
import * as AduanasActions from '../actions/aduanas-api/aduanas-api-action-creators';
import * as ModalActionTypes from '../actions/modal-handling/modal-action-creators';

// Componentes
import Footer from '../components/global/footer';
import Header from '../components/global/header';
import Modal from '../components/global/modal';
import ModalConfirm from '../components/global/modalConfirmRefrence';

const mapStateToProps = state => ({
  actionBtn: state.action.action,
  clientInfo: state.client.clientInfo,
  errAdd: state.reference.errAdd,
  errDeleted: state.deleteReference.err,
  errUpdate: state.reference.errUpdate,
  isOpen: state.modalReducer.isOpen,
  msgAdd: state.reference.msgAdd,
  msgDeleted: state.deleteReference.msg,
  msgUpdate: state.reference.msgUpdate,
  referenceDeleted: state.deleteReference.reference,
  referenceList: state.reference.referenceList,
  statusDelete: state.deleteReference.status,
  isUpdateReference: state.reference.isUpdateReference,
});

class ListReference extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(props.location.search);
    this.state = {
      clientID: queryParams && queryParams.client ? queryParams.client : null,
      userInfo: getCookie('userSession'),
      clientValue: '',
      selectedClient: null,
      selectedAction: null,
    };
  }

  componentWillMount() {
    const { dispatch, history } = this.props;
    const { userInfo, clientID } = this.state;
    if ( userInfo === null ) {
      history.push('/');
      return;
    }

    const userToken = userInfo.token;
    dispatch(AduanasActions.getClientInfo(userToken, clientID));
    dispatch(AduanasActions.listaReferencias(userToken, clientID));
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, referenceDeleted, statusDelete, isUpdateReference } = this.props;
    const { userInfo, clientID } = this.state;
    const userToken = userInfo.token;

    if (statusDelete !== nextProps.statusDelete || referenceDeleted !== nextProps.referenceDeleted) {
      dispatch(AduanasActions.listaReferencias(userToken, clientID));
    }

    if (nextProps.actionBtn === 'delete') {
      setTimeout(() => {
        this.notify(nextProps.msgDeleted, nextProps.errDeleted);
      }, 500);
    }

    if (nextProps.actionBtn === 'update' && isUpdateReference !== nextProps.isUpdateReference) {
      setTimeout(() => {
        this.notify(nextProps.msgUpdate, nextProps.errUpdate);
      }, 500);
    }

    if (nextProps.actionBtn === 'add') {
      setTimeout(() => {
        this.notify(nextProps.msgAdd, nextProps.errAdd);
      }, 500);
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(eventBtn(undefined));
  }

  render() {
    const {
      isOpen,
      dispatch,
    } = this.props;

    const {
      selectedClient,
      userInfo,
    } = this.state;

    return (
      <Fragment>
        <Header dispatch={ dispatch }/>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnVisibilityChange={true}
          draggable={true}
          pauseOnHover={true}
        />
        {/* Same as */}
        <ToastContainer />
        <div className='loginMainSection bg-MWL'>
          { isOpen &&
            <Modal
              dispatch = { dispatch }
              component = {
                <ModalConfirm
                  value = { selectedClient }
                  popUpOpen={ this.popUpOpen }
                  dispatch={ dispatch }
                /> }
            />
          }
          <div className='addSection border-left-red bg-white'>
            <div className='d-sm-flex align-items-center justify-content-between mb-4 text-red'>
              <h3 className='loginHeader title'>Listado de Referencias</h3>
              {
                userInfo && userInfo.userInfo.referenceAdd &&
                <a href='#'
                  className='d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm buttonAddSize'
                  onClick= { this.addReference }>
                  <i className='fas fa-list-alt fa-sm text-white-50'></i> Agregar Referencia</a>
              }
            </div>
            <div className='addDocs'>
              <hr></hr>
              { this.listReferenceTable() }
            </div>
            <div className='btns-back'>
              <button
                onClick={ () => this.backPage() }
                className='addListBtn'>
                  Regresar
              </button>
            </div>
          </div>
        </div>
        <Footer/>
      </Fragment>
    );
  }

  renderReferenceList() {
    const { referenceList, history } = this.props;
    const { clientID } = this.state;

    return (
      <div className='clientListBox'>
        <ul>
          {
            referenceList.map((reference, i) => {
              return (
                <div className = 'companySection' key={ i } >
                  <li className='clientGroup'>
                    <span>{ reference.referenceID }: { reference.name }</span>
                    <div className='iconGroup'>
                      <i
                        className='fas fa-plus-circle'
                        onClick = { () => { history.push(`/api/docs?reference=${reference._id}`); } }>
                      </i>
                      <i
                        className='fas fa-pencil-alt'
                        onClick = { () => { history.push(`/api/update-reference?reference=${reference._id}&client=${ clientID }`); } }>
                      </i>
                      <i
                        className='far fa-trash-alt'
                        onClick = { () => this.deleteReference('delete', reference) }>
                      </i>
                    </div>
                  </li>
                </div>
              );
            })
          }
        </ul>
      </div>
    );
  }

  listReferenceTable() {
    const { referenceList, clientInfo } = this.props;
    const { clientID, userInfo } = this.state;
    const listaReferencias = [];
    if ( referenceList && referenceList.length > 0 && clientInfo ) {
      referenceList.map((reference, index) => {
        const objReference = {};
        objReference.id = reference.referenceID;
        objReference.name = reference.name;
        objReference.rfc = clientInfo.rfc;
        objReference.razonSocial = clientInfo.razonSocial;
        objReference.fecha = moment(reference.created_at).format('DD/MM/YYYY');
        objReference.option = ([
          <div className='iconGroupClientTable' key={index}>
            <i
              className={`fas fa-plus-circle ${userInfo && !userInfo.userInfo.docList && 'enableElement'}`}
              title='Agregar Documentos'
              onClick = { () => { this.props.history.push(`/docs?reference=${reference._id}`); } }>
            </i>
            <i
              className={`fas fa-pencil-alt ${userInfo && !userInfo.userInfo.referenceUpdate && 'enableElement'}`}
              title='Editar Referencia'
              onClick = { () => { this.props.history.push(`/update-reference?reference=${reference._id}&client=${ clientID }`); } }
            >
            </i>
            <i
              className={`far fa-trash-alt ${userInfo && !userInfo.userInfo.referenceDrop && 'enableElement'}`}
              title='Eliminar Referencia'
              onClick = { () => this.deleteReference('delete', reference) }>
            </i>
          </div>]);
        listaReferencias.push(objReference);
      });
    }
    const data = {
      columns: [
        {
          label: '#',
          field: 'id',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Nombre',
          field: 'name',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'RFC',
          field: 'rfc',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Razón Social',
          field: 'razonSocial',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'Fecha de Creación',
          field: 'fecha',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'Opciones',
          field: 'options',
          sort: 'disabled',
          width: '50px',
        },
      ],
      rows: listaReferencias,
    };

    if (userInfo) {
      if (!userInfo.userInfo.docList && !userInfo.userInfo.referenceUpdate && !userInfo.userInfo.referenceDrop) {
        data.columns.splice((data.columns.length - 1), 1);
        data.rows.map( element => delete element.option);
      }
    }

    if (referenceList && referenceList.length > 0 && clientInfo) {
      return (
        <MDBDataTable
          bordered={true}
          data={data}
          entriesLabel='Número de Referencias'
          hover={true}
          infoLabel={['Mostrando', 'a', 'de', 'referencias']}
          paginationLabel={['Anterior', 'Siguiente']}
          responsive={true}
          searchLabel='Buscar Referencia'
          small={true}
          striped={true}
        />
      );
    }
  }

  popUpOpen = () => {
    const { dispatch } = this.props;
    dispatch(eventBtn('delete'));
  }

  updateInputValue(evt) {
    this.setState({
      clientValue: evt.target.value,
    });
  }

  addReference = () => {
    const { history } = this.props;
    const { clientID } = this.state;
    history.push(`/add-reference?client=${clientID}`);
  }

  deleteReference = (action, client) => {
    const { dispatch } = this.props;

    this.setState({
      selectedAction: action,
      selectedClient: client,
    });
    dispatch(eventBtn(undefined));
    dispatch(ModalActionTypes.openModal(true));
  }

  backPage = () => {
    const { history } = this.props;

    history.push('/list');
  }

  toastId = null;

  notify = (message, err) => {
    let type = 'success';

    if (err) {
      type = 'error';
    }

    if (!toast.isActive(this.toastId)) {
      this.toastId = toast[type](message, {
        position: 'top-right',
        autoClose: 5000,
        transition: Flip,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
}

ListReference.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ListReference);