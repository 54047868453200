import keyMirror from 'keymirror';

const errorActionTypes = keyMirror({
  CLOSE_ERROR_BAR: null,
  CLOSE_ERROR_MODAL: null,
  GLOBAL_ERROR: null,
  OPEN_ERROR_BAR: null,
  OPEN_ERROR_BAR_ACTIONABLE: null,
  SAVE_ACTION: null,
  ON_ERROR: null,
  RESET_ERRORS: null,
  WARNINGS_CAUGHT: null,
});

export default errorActionTypes;
