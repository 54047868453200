import { combineReducers } from 'redux';
import action from './action-reducer';
import authentication from './authentication-reducer';
import client from './clients-reducer';
import dashboard from './dashboard-reducer';
import deleteClient from './deleteClient-reducer';
import deleteDocuments from './deleteDocument-reducer';
import deleteReference from './deleteReference-reducer';
import deleteUser from './deleteUser-reducer';
import document from './documents-reducer';
import email from './email-reducer';
import whatsApp from './whatsapp-reducer';
import modalReducer from './modal-reducer';
import reference from './reference-reducer';
import updateClient from './actualizarCliente-reducer';
import updateUser from './actualizarUsuario-reducer';
import user from './users-reducer';
import extencions from './extencions-reducer';

export default combineReducers({
  action,
  authentication,
  client,
  dashboard,
  deleteClient,
  deleteDocuments,
  deleteReference,
  deleteUser,
  document,
  email,
  whatsApp,
  extencions,
  modalReducer,
  reference,
  updateClient,
  updateUser,
  user,
});