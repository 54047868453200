export const validate = (text, lengthField) => {
  if (text != '' && text !== null & text !== undefined && ( lengthField ? ( text.length === lengthField ) : true )) {
    return true;
  } else {
    return false;
  }
};


export const validateNumber = (number, lengthField) => {
  if (!isNaN(number) && number.length > 0 && ( lengthField ? ( number.length === lengthField ) : true )) {
    return true;
  } else {
    return false;
  }
};

export const validateEmail = email => {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email) && email.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const validateCharacters = refrence => {
  if (/^[|°¬!"#$%&()=?'¿*+~'~[{^}`“”─;,.:·<>_̣_\¡]/.test(refrence) && refrence.length > 0) {
    return false;
  } else {
    return true;
  }
};