import React, { Component } from 'react';
import { connect } from 'react-redux';
// Utilities
import { getCookie } from '../../utilities/helpers';

import { openModal } from '../../actions/modal-handling/modal-action-creators';
import { eliminaExtension } from '../../actions/aduanas-api/aduanas-api-action-creators';

class ModalDeleteExtension extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: getCookie('userSession'),
    };
  }

  render() {
    const { _id, extension } = this.props.value;
    
    return (
      <div className='modalInput'>
        <h2>{`¿Desea eliminar la extension ${extension}?`}</h2>
        <div className='wrapperButtonModal'>
          <button
            onClick={ () => this.deleteExtension(_id) }
            className='addListBtn'>
            Eliminar
          </button>
          <button
            onClick={ () => this.cancel() }
            className='addListBtn'>
            Cancelar
          </button>
        </div>
      </div>
    );
  }

  cancel = () => {
    const { dispatch } = this.props;

    dispatch(openModal(false));
  }

  deleteExtension = () => {
    const { dispatch, value, popUpOpen  } = this.props;
    const { userInfo } = this.state;
    const userToken = userInfo.token;

    dispatch(eliminaExtension(userToken, value._id));
    dispatch(openModal(false));
    popUpOpen();
  }
}

export default connect(null)(ModalDeleteExtension);