import aduanasActionTypes from '../actions/aduanas-api/aduanas-api-action-types';

const initialState = {
  userInfo: {},
  erroruserInfo: {},
  loadinguserInfo: false,
  loadingUsers: false,
  usersList: undefined,

  msg: undefined,
  err: undefined,
  isUserCreated: false,
  loadingSignup: false,
};

const actionsMap = {
  [aduanasActionTypes.ADUANAS_LISTA_USERS.START]: (state) => {
    return {
      ...state,
      loadingUsers: true,
    };
  },

  [aduanasActionTypes.ADUANAS_LISTA_USERS.SUCCESS]: (state, action) => {
    return {
      ...state,
      loadingUsers: false,
      usersList: action.result.usersList,
    };
  },

  [aduanasActionTypes.ADUANAS_LISTA_USERS.FAIL]: (state) => {
    return {
      ...state,
      loadingUsers: false,
      usersList: [],
    };
  },

  [aduanasActionTypes.ADUANAS_REGISTRA_USER.START]: (state) => {
    return {
      ...state,
      loadingSignup: true,
      isUserCreated: false,
    };
  },

  [aduanasActionTypes.ADUANAS_REGISTRA_USER.SUCCESS]: (state, action) => {
    return {
      ...state,
      isUserCreated: action.result.success,
      loadingSignup: false,
      msg: action.result.message,
    };
  },

  [aduanasActionTypes.ADUANAS_REGISTRA_USER.FAIL]: (state, action) => {
    return {
      ...state,
      err: action.result.err,
      isUserCreated: false,
      loadingSignup: false,
      msg: action.result.message,
    };
  },

  [aduanasActionTypes.ADUANAS_CLIENT_INFO.START]: (state) => {
    return {
      ...state,
      userInfo: {},
      erroruserInfo: {},
      loadinguserInfo: true,
    };
  },

  [aduanasActionTypes.ADUANAS_CLIENT_INFO.SUCCESS]: (state, action) => {
    return {
      ...state,
      userInfo: action.result,
      erroruserInfo: {},
      loadinguserInfo: false,
    };
  },

  [aduanasActionTypes.ADUANAS_CLIENT_INFO.FAIL]: (state, action) => {
    return {
      ...state,
      userInfo: {},
      erroruserInfo: action.result,
      loadinguserInfo: false,
    };
  },

};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  if (!fn) {
    return state;
  }
  return fn(state, action);
}
