import aduanasActionTypes from '../actions/aduanas-api/aduanas-api-action-types';

const initialState = {
  err: undefined,
  errorDeleteDocument: {},
  isDocumentDelete: false,
  loadingDeleteDocument: false,
  msg: undefined,
};

const actionsMap = {
  [aduanasActionTypes.ADUANAS_DELETE_DOCUMENTS.START]: (state) => {
    return {
      ...state,
      errorDeleteDocument: {},
      isDocumentDelete: false,
      loadingDeleteDocument: true,
    };
  },

  [aduanasActionTypes.ADUANAS_DELETE_DOCUMENTS.SUCCESS]: (state, action) => {
    return {
      ...state,
      errorDeleteDocument: {},
      isDocumentDelete: action.result,
      loadingDeleteDocument: true,
      msg: action.result.message,
    };
  },

  [aduanasActionTypes.ADUANAS_DELETE_DOCUMENTS.FAIL]: (state, action) => {
    return {
      ...state,
      err: true,
      errorDeleteDocument: action.result,
      isDocumentDelete: false,
      loadingDeleteDocument: false,
      msg: 'El documento no se pudo eliminar',
    };
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  if (!fn) {
    return state;
  }
  return fn(state, action);
}
