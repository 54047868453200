import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { MDBDataTable } from 'mdbreact';
import createFileList from 'create-file-list';

// Components
import Footer from '../components/global/footer';
import Header from '../components/global/header';
import Modal from '../components/global/modal';
import ModalPDF from '../components/global/modalPDF';
import ModalDeleteDocument from '../components/global/modalDeleteDocument';

// Actions
import { eventBtn } from '../actions/actions';
import * as AduanasActions from '../actions/aduanas-api/aduanas-api-action-creators';
import * as ModalActionTypes from '../actions/modal-handling/modal-action-creators';

// Utilities
import 'react-toastify/dist/ReactToastify.min.css';
import { getCookie } from '../utilities/helpers';
import { ToastContainer, toast, Flip } from 'react-toastify';
import cx from 'classnames';
import fileExtension from '../utilities/fileExtension';

import FileViewer from 'react-file-viewer';
import { CustomErrorComponent } from 'custom-error';
// import logger from 'logging-library';

const mapStateToProps = state => ({
  actionBtn: state.action.action,
  clientInfo: state.client.clientInfo,
  clientList: state.client.usersList,
  correo: state.email.msg,
  whatsApp: state.whatsApp.msg,
  documentsList: state.document.documentsList,
  errAddDocument: state.document.err,
  errCorreo: state.email.err,
  errWhatsApp: state.whatsApp.err,
  errDeleted: state.deleteDocuments.err,
  errorListDocuments: state.document.errorListDocuments,
  errorReferenceInfo: state.reference.errorReferenceInfo,
  errorUploadDocuments: state.document.errorUploadDocuments,
  extencionList: state.extencions.extencionList,
  isDocumentAdded: state.document.isDocumentAdded,
  isOpenModal: state.modalReducer.isOpen,
  loadingListDocuments: state.document.loadingListDocuments,
  loadingReferenceInfo: state.reference.loadingReferenceInfo,
  loadingUploadDocuments: state.document.loadingUploadDocuments,
  msgAddDocument: state.document.msg,
  msgDeleted: state.deleteDocuments.msg,
  referenceInfo: state.reference.referenceInfo,
  token: state.authentication.token,
  userInfo: state.authentication.userInfo,
});

class AddDocs extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(props.location.search);

    this.state = {
      content: undefined,
      filesArr: undefined,
      fileSelected: '',
      imageURL: '',
      listDeniedExtensions: [],
      referenceID: queryParams && queryParams.reference ? queryParams.reference : null,
      selectedDocument: null,
      typeFile: '',
      update: false,
      userInfo: getCookie('userSession'),
      view : false,
      viewPDF: undefined,
    };

    this.refFiles = new createRef();
    this.refContent = new createRef();
  }

  componentWillMount() {
    const { dispatch, history } = this.props;
    const { userInfo, referenceID } = this.state;
    if ( userInfo === null ) {
      history.push('/');
      return;
    }
    const userToken = userInfo.token;
    dispatch(AduanasActions.getReference(userToken, referenceID));
    dispatch(AduanasActions.listaExtenciones(userToken));
  }

  componentWillReceiveProps(nextProps) {
    const {
      dispatch,
      isDocumentAdded,
      referenceInfo,
    } = this.props;

    const { userInfo, view } = this.state;
    const userToken = userInfo.token;

    if (view && !nextProps.isOpenModal) {
      this.setState({
        view : false,
      });
    }

    if (nextProps.correo !== undefined && nextProps.actionBtn === 'email') {
      setTimeout(() => {
        this.notify(nextProps.correo, nextProps.errCorreo);
      }, 500);
    }

    if (nextProps.whatsApp !== undefined && nextProps.actionBtn === 'whatsApp') {
      setTimeout(() => {
        this.notify(nextProps.whatsApp, nextProps.errWhatsApp);
      }, 500);
    }

    if (nextProps.msgDeleted !== undefined && nextProps.actionBtn === 'delete') {
      this.setState({
        update: true,
      });
      
      setTimeout(() => {
        this.notify(nextProps.msgDeleted, nextProps.errDeleted);
      }, 500);
    }

    if ( nextProps.referenceInfo && nextProps.referenceInfo.clientId && referenceInfo.clientId !== nextProps.referenceInfo.clientId) {
      dispatch(AduanasActions.getClientInfo(userToken, nextProps.referenceInfo.clientId));
      dispatch(AduanasActions.listaClientes(userToken));
    }

    if ( isDocumentAdded !== nextProps.isDocumentAdded ) {
      this.setState({
        filesArr: undefined,
      });

      dispatch(AduanasActions.listDocuments(userToken, nextProps.referenceInfo.clientId, nextProps.referenceInfo._id));

      if (nextProps.actionBtn === 'addDoc') {
        setTimeout(() => {
          this.notify(nextProps.msgAddDocument, nextProps.errAddDocument);
        }, 500);
      }
      // history.push(`/reference?client=${referenceInfo.clientId}`);
    }


    if ( nextProps.referenceInfo.clientId && nextProps.referenceInfo._id) {
      if ( referenceInfo.clientId !== nextProps.referenceInfo.clientId && referenceInfo._id !== nextProps.referenceInfo._id ) {
        dispatch(AduanasActions.listDocuments(userToken, nextProps.referenceInfo.clientId, nextProps.referenceInfo._id));
      }
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const userToken = nextState.userInfo.token;

    setTimeout( () => {
      if ( nextState.update && nextProps.actionBtn === 'delete') {
        nextProps.dispatch(eventBtn(undefined));
        nextProps.dispatch(AduanasActions.listDocuments(userToken, nextProps.referenceInfo.clientId, nextProps.referenceInfo._id));
  
        this.setState({
          update: false,
        });
      }
    }, 200);

    if (nextState.listDeniedExtensions !== this.state.listDeniedExtensions && nextState.listDeniedExtensions.length) {
      setTimeout(() => {
        this.notify(`No tiene permisos para subir archivos con las extensiones ${nextState.listDeniedExtensions.join(', ')}`, true);
      }, 500);

      this.setState({
        listDeniedExtensions: [],
      });
    }
  }

  render() {
    const { filesArr, selectedDocument, view, content, userInfo, viewPDF } = this.state;
    const {
      // clientInfo,
      clientList,
      dispatch,
      extencionList,
      isOpenModal,
      referenceInfo,
      documentsList,
    } = this.props;

    const client = clientList.filter( client => client._id === referenceInfo.clientId );

    const classSendEmail = cx({
      'addListBtn': true,
      'uploadDesabled': documentsList.length === 0,
    });

    const classSendWhatsApp = cx({
      'addListBtn': true,
      'uploadDesabled': documentsList.length === 0,
    });

    const classUpload = cx({
      'uploadDesabled': !filesArr,
      'btn': true,
      'btn-sm': true,
      'btn-primary': true,
      'shadow-sm': true,
      'buttonAddSize': true,
      'auto-width': true,
    });

    const extencions = [];

    extencionList.forEach( extension => {
      const element = extension.extension;

      if (element.indexOf('.') !== -1) {
        extencions.push(element);
      } else {
        extencions.push(`.${element}`);
      }
    });

    return (
      <Fragment>
        <Header dispatch={ dispatch }/>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnVisibilityChange={true}
          draggable={true}
          pauseOnHover={true}
        />
        {/* Same as */}
        <ToastContainer />
        {
          isOpenModal && view && content !== undefined &&
            <Modal
              dispatch = { dispatch }
              component = { content }/>
        }
        {
          viewPDF &&
          <ModalPDF
            closePDF = { () => this.closePDF() }
            component = { viewPDF }/>
        }
        {
          isOpenModal && !view &&
            <Modal
              dispatch = { dispatch }
              component = {
                <ModalDeleteDocument
                  value={ selectedDocument }
                  popUpOpen={ this.popUpOpen }
                  referenceInfo={ referenceInfo }
                  dispatch={ dispatch } />} />
        }
        <div ref={this.refContent} className='loginMainSection bg-MWL'>
          <div className='addSection border-left-red bg-white'>
            <div className='addHeader text-red'>
              <h3 className='loginHeader title'>
                Agregar Documentos
              </h3>
            </div>
            <div className='addDocs'>
              <div className='addformGroup'>
                <p className='text-dark'>{ client[0] && `Cliente: ${client[0].razonSocial} - ${client[0].rfc}` }</p>
                <p className='text-dark'>{ `Referencia: ${referenceInfo.referenceID} - ${referenceInfo.name}` }</p>
                <form encType='multipart/form-data'>
                  <div className='browseSection'>
                    {
                      userInfo && userInfo.userInfo.docAdd &&
                      <div className='browseInput'>
                        <input
                          ref={this.refFiles}
                          className='customInput'
                          type='file'
                          placeholder= 'Seleccionar Archivos'
                          multiple = { true }
                          // accept = '.jpg, .jpeg, .png, .doc, .docx, .pdf'
                          accept = {extencions.join(', ')}
                          onChange={ evt => this.onFileLoad(evt) }
                        />
                        <label className='customLabel'>{ filesArr && filesArr.length ? 'Archivos Seleccionados' : 'Seleccionar Archivos' } </label>
                      </div>
                    }
                    { filesArr && filesArr.length ? this.renderLoadedFile() : null }
                    {
                      userInfo && userInfo.userInfo.docAdd &&
                      <a
                        href='#'
                        className={ classUpload }
                        onClick={ ev => this.handleAddDocuments(ev) } >
                        <i className='fas fa-upload fa-sm text-white-50'></i> Agregar Documento(s)</a>
                    }
                  </div>
                </form>
                { this.renderDocumentsTable() }
              </div>
              <div className='btns'>
                {
                  userInfo && userInfo.userInfo.docSend &&
                  <button
                    onClick={ () => this.sendEmail() }
                    className={ classSendEmail }>
                      Enviar Correo
                  </button>
                }
                {
                  userInfo && userInfo.userInfo.whatsAppSend &&
                  <button
                    onClick={ () => this.sendWhatsApp() }
                    className={ classSendWhatsApp }>
                      Enviar WhatsApp
                  </button>
                }
                {
                  userInfo && !userInfo.userInfo.whatsAppSend &&
                  <button
                    onClick={ () => this.cancel() }
                    className='addListBtn'>
                      Regresar
                  </button>
                }
              </div>
              {
                userInfo && userInfo.userInfo.whatsAppSend &&
                <div  className='btnsBack'>
                  <button
                    onClick={ () => this.cancel() }
                    className='addListBtn'>
                      Regresar
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
        <Footer/>
      </Fragment>
    );
  }

  /* onError(e) {
    // logger.logError(e, 'error in file-viewer');
  } */

  renderDocumentsTable() {
    const { documentsList } = this.props;
    const { userInfo } = this.state;
    const listaDocumentos = [];
    let existInList = false;

    if ( documentsList && documentsList.length > 0 ) {
      documentsList.map((documents, index) => {
        const documentName = documents.name;
        const splitDocumentName = documentName.split('.');
        const existExtensionFile = userInfo.userInfo.extencionsAssignment.indexOf(splitDocumentName[splitDocumentName.length - 1]) !== -1;

        if (existExtensionFile) {
          existInList = existExtensionFile;
        }

        const objDocuments = {};
        objDocuments.id = index + 1;
        objDocuments.name = documentName;
        // objDocuments.rfc = documents.rfc;
        // objDocuments.reference = documents.referenceName;
        objDocuments.option = ([
          <div className='iconGroupDocsTable' key={index}>
            {
              (userInfo.userInfo.rol === 'admin') || (existExtensionFile) ?
                <i
                  className={`fas fa-eye ${userInfo && !userInfo.userInfo.docView && 'enableElement'}`}
                  title='Ver Documento'
                  onClick={ () => this.preView(documents) }>
                </i>
                : <i></i>
            }
            {
              (userInfo.userInfo.rol === 'admin') || (existExtensionFile) ?
                <i
                  className={`fas fa-download ${userInfo && !userInfo.userInfo.docDownload && 'enableElement'}`}
                  title='Descargar Documento'
                  onClick={ () => this.handleDownloadFile(documents) }>
                </i>
                : <i></i>
            }
            {
              (userInfo && userInfo.userInfo.docDrop && existExtensionFile) || userInfo.userInfo.rol === 'admin' ?
                <i
                  className={`far fa-trash-alt ${userInfo && !userInfo.userInfo.docDrop && 'enableElement'}`}
                  title='Eliminar Documento'
                  onClick = { () => this.handleDeleteDocument(documents) }>
                </i>
                : <i></i>
            }
            {
              ((!existInList && userInfo.userInfo.rol !== 'admin') ||
               (!userInfo.userInfo.docDrop && !existExtensionFile && userInfo.userInfo.rol !== 'admin') ||
               (userInfo.userInfo.docDrop && !existExtensionFile && userInfo.userInfo.rol !== 'admin')) &&
                <span>N/A</span>
            }
          </div>]);
        listaDocumentos.push(objDocuments);
      });
    }

    const data = {
      columns: [
        {
          label: '#',
          field: 'id',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Nombre',
          field: 'name',
          sort: 'asc',
          width: 270,
        },
        /* {
          label: 'RFC',
          field: 'rfc',
          sort: 'asc',
          width: 150,
        }, */
        /* {
          label: 'Referencia',
          field: 'reference',
          sort: 'asc',
          width: 270,
        }, */
        {
          label: 'Opciones',
          field: 'options',
          sort: 'disabled',
          width: '50px',
        },
      ],
      rows: listaDocumentos,
    };

    if (userInfo) {
      if ((!userInfo.userInfo.docView && !userInfo.userInfo.docDownload && !userInfo.userInfo.docDrop) || (!existInList && userInfo.userInfo.rol !== 'admin' && !userInfo.userInfo.docDrop) || (!existInList && userInfo.userInfo.rol !== 'admin')) {
        data.columns.splice((data.columns.length - 1), 1);
        data.rows.map( element => delete element.option);
      }
    }

    if ( documentsList && documentsList.length > 0 ) {
      return (
        <MDBDataTable
          bordered={true}
          data={data}
          entriesLabel='Número de Documentos'
          hover={true}
          infoLabel={['Mostrando', 'a', 'de', 'documentos']}
          paginationLabel={['Anterior', 'Siguiente']}
          responsive={true}
          searchLabel='Buscar Documentos'
          small={true}
          striped={true}
        /> );
    }
  }

  preView = file => {
    const { dispatch, referenceInfo, clientList } = this.props;
    const { name } = file;

    const content = name.split('.');

    const type = content[content.length - 1].toLowerCase();

    const client = clientList.filter( client => client._id === referenceInfo.clientId );

    let component = undefined;
    let viewPDF = undefined;
    const openModal = true;

    // const url = `${ window.location.origin.replace('3000', '3002') }/public/uploads/${ client[0].rfc }/${ referenceInfo.referenceID }/${ name }`;
    const url = `https://${ window.location.hostname }/api/public/uploads/${ client[0].rfc }/${ referenceInfo.referenceID }/${ name }`;

    if (type === 'docx') {
      // eslint-disable-next-line
        component = <FileViewer
        fileType={type}
        filePath={url}
        errorComponent={CustomErrorComponent}
        onError={this.onError}/>;
    } else if ( type === 'png' || type === 'jpeg' || type === 'jpg' || type === 'gif' || type === 'svg') {
      component = 
      // eslint-disable-next-line
        <div className='viewer'>
          <img
            src={url}
            alt={name}/>
        </div>;
    } else if (type === 'pdf') {
      /* openModal = false;
      window.open(url); */
      const urlPDF = this.refContent.current.offsetWidth <= 600 ? `https://docs.google.com/gview?url=${url}&embedded=true` : url;
      viewPDF = (<iframe
        style={{ height:'calc(100vh - 80px)', width:'90%' }}
        src={urlPDF}></iframe>);
    } else {
      component = 
      // eslint-disable-next-line
        <div className='viewer errorPreView'>
          {`El archivo ${ name } no se puede visualizar, solo se puede descargar.`}
        </div>;
    }

    this.setState({
      content: component,
      fileSelected: name,
      view: openModal,
      viewPDF,
      typeFile: type,
    });

    if (component !== undefined) {
      dispatch(ModalActionTypes.openModal(true));
    }
  }

  closePDF = () => {
    this.setState({
      content: undefined,
      isOpenModal: false,
      viewPDF: undefined,
      filesArr: undefined,
      view : false,
      typeFile: '',
      fileSelected: '',
    });
  }

  handleDownloadFile(document) {
    const { dispatch, referenceInfo } = this.props;
    const { userInfo } = this.state;
    const name = document.name;
    const rfc = document.rfc;
    const reference = referenceInfo.referenceID;
    const splitDocumentName = name.split('.');

    if ((userInfo.userInfo.rol === 'admin') || (userInfo.userInfo.extencionsAssignment.indexOf(splitDocumentName[splitDocumentName.length - 1]) !== -1)) {
      dispatch(AduanasActions.download(document.path, name));

      // window.open(`${ window.location.origin.replace('3000', '3002') }/docs/download?name=${name}&pathDocument=public%2Fuploads%2F${rfc}%2F${reference}`);
      window.open(`https://${ window.location.hostname }/api/docs/download?name=${name}&pathDocument=public%2Fuploads%2F${rfc}%2F${reference}`);
    }
  }

  handleAddDocuments = ev => {
    ev.preventDefault();

    const {
      dispatch,
      referenceInfo,
      clientList,
    } = this.props;

    const {
      userInfo,
      filesArr,
    } = this.state;

    const data = new FormData();
    for (let i = 0; i < filesArr.length; i++ ) {
      data.append('file', filesArr[i]);
    }
    const userToken = userInfo && userInfo.token ? userInfo.token : null;

    const client = clientList.filter( client => client._id === referenceInfo.clientId );
    if ( client && client[0] && client[0]._id && client[0].rfc && referenceInfo && referenceInfo._id && userToken && filesArr && filesArr.length > 0 ) {
      data.append('totalFiles', filesArr.length);
      data.append('clientID', client[0]._id);
      data.append('referenceID', referenceInfo._id);
      data.append('rfc', client[0].rfc);
      data.append('referenceName', referenceInfo.referenceID);
      // Metodo para agregar documentos
      dispatch(AduanasActions.addDocuments(userToken, data));
      dispatch(eventBtn('addDoc'));
      /* this.setState({
        update: true,
      }); */
    }
  }

  onFileLoad(evt) {
    const { userInfo } = this.state;
    const files = [];
    const listDeniedExtensions = [];
    const filesOriginal = evt.target.files;

    Object.keys(filesOriginal).forEach( file => {
      const splitDocumentName = filesOriginal[file].name.split('.');
      if ((userInfo.userInfo.rol === 'admin') || (userInfo.userInfo.extencionsAssignment.indexOf(splitDocumentName[splitDocumentName.length - 1]) !== -1)) {
        const document = filesOriginal[file];
        const blob = document.slice(0, document.size);
        const name = filesOriginal[file].name.replaceAll(' ', '_');
        let newName = '';
        
        for (let i = 0; i < name.length; i++) {
          let lyrics = '';
          switch (name.charAt(i)) {
            case 'á': case 'Á':
              lyrics = name.charAt(i) === name.charAt(i).toLowerCase() ? 'a' : 'A';
              break;
            case 'é': case 'É':
              lyrics = name.charAt(i) === name.charAt(i).toLowerCase() ? 'e' : 'E';
              break;
            case 'í': case 'Í':
              lyrics = name.charAt(i) === name.charAt(i).toLowerCase() ? 'i' : 'i';
              break;
            case 'ó': case 'Ó':
              lyrics = name.charAt(i) === name.charAt(i).toLowerCase() ? 'o' : 'O';
              break;
            case 'ú': case 'Ú':
              lyrics = name.charAt(i) === name.charAt(i).toLowerCase() ? 'u' : 'U';
              break;
            case 'ñ': case 'Ñ':
              lyrics = name.charAt(i) === name.charAt(i).toLowerCase() ? 'n' : 'N';
              break;
            default:
              lyrics = name.charAt(i);
              break;
          }
          newName = `${newName}${lyrics}`;
        }

        const newFile = new File([ blob ], newName, { type: filesOriginal[file].type });

        files.push(newFile);
      } else {
        listDeniedExtensions.push(splitDocumentName[splitDocumentName.length - 1]);
      }
    });

    const f = createFileList(files);
    this.refFiles.current.files  = f;

    this.setState({
      filesArr: f,
      listDeniedExtensions,
    });
  }

  renderLoadedFile = () => {
    const { filesArr } = this.state;
    const filesArray = [];

    for (let index = 0; index < filesArr.length; index ++ ) {
      filesArray.push(filesArr[index]);
    }

    return (
      <div className='loadedFileSection'>
        <div className='loadedFileSection-wrapper'>
          { filesArray.map((files, index) => {
            const fileType = fileExtension(files.name)[1];
            return (
              <div className='loadedFileCard' key = { index } >
                <div className='loadedFileSection-container'>
                  {
                    fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg' ?
                      <i className='fas fa-file-image'></i>
                      : <i className='fas fa-file'></i>
                  }
                  <label> { files.name } </label>
                  <i
                    className='fas fa-times'
                    onClick={ (evt) => this.removeFile(evt, index) }
                  ></i>
                </div>
              </div>
            );
          })
          }
        </div>
        { filesArr.length >= 2 ?
          <div className='removeFilesSection'>
            <button
              onClick= { () => this.removeAll() }
              className='removeAllBtn'>
              Remover Todos
            </button>
          </div> : null
        }
      </div>
    );
  }

  popUpOpen = () => {
    const { dispatch } = this.props;
    dispatch(eventBtn('delete'));
  }

  handleDeleteDocument = documentID => {
    const {
      dispatch,
    } = this.props;

    this.setState({
      selectedDocument : documentID,
    });

    dispatch(ModalActionTypes.openModal(true));
  }

  removeFile(evt, fileIndex) {
    const fileArrUpdated = [...this.state.filesArr];

    fileArrUpdated.splice(fileIndex, 1);

    this.setState({
      filesArr: fileArrUpdated,
    });
  }

  removeAll() {
    this.setState({
      filesArr: [],
    });
  }

  sendWhatsApp = () => {
    const { dispatch, clientList, referenceInfo, documentsList } = this.props;
    const { userInfo } = this.state;
    const userToken = userInfo.token;

    const client = clientList.filter( client => client._id === referenceInfo.clientId );
    const documents = [];
    documentsList.map( nameDocument => documents.push(nameDocument.name));
    const host = window.location.origin.indexOf('localhost') === -1 ? window.location.origin : 'https://prueba.com';
    const url = `${host}/user?rfc=${client[0].rfc}&ref=${referenceInfo.referenceID}`;
    
    let obj = `Estimado Usuario:
    \nEl administrador subio nuevos archivos en la referencia ${ referenceInfo.referenceID }.
    \nLos archivos se listan a continuación:
    \n*${ documents.join(',') }*
    \nEstarán disponibles para su consulta y descarga en: 
    \n${url}
    \n**Nombre de usuario y contraseña serán requeridos**
    \nNo es necesario responder este mensaje.
    \nAtte,
    \nCustomerCare MWL SERVICES
    `;
    
    obj = obj.replace(',', '');

    const data = {
      phone: client[0].telefono,
      mesage: obj,
    };

    dispatch(AduanasActions.sendWhatsApp(userToken, data));
    dispatch(eventBtn('whatsApp'));
  }

  sendEmail = () => {
    const { dispatch, clientList, referenceInfo, documentsList } = this.props;
    const { userInfo } = this.state;

    const userToken = userInfo.token;

    const documents = documentsList.map( nameDocument => `<li>${nameDocument.name}</li>`);
    const client = clientList.filter( client => client._id === referenceInfo.clientId );

    const urlImg = `http://${ window.location.hostname }:3000`;

    let obj = `<p style='color: black;'>Estimado Usuario;</p>
               <p style='color: black;'>Se ha generado la referencia operativa detallada en el titulo del presente correo.</p>
               <p style='color: black;'>El administrador subio nuevos archivos en la referencia ${ referenceInfo.referenceID }.</p>
               <p style='color: black;'>Los archivos se listan a continuación:</p>
               <ul style='color: black;'>${ documents }</ul>
               <p style='color: black;'>Estarán disponibles para su consulta y descarga en: </p>
               <a href='${window.location.origin}/user?rfc=${client[0].rfc}&ref=${referenceInfo.referenceID}'>${window.location.origin}/user?rfc=${client[0].rfc}&ref=${referenceInfo.referenceID}</a>
               <p style='color: black; font-weight: bold;'>**Nombre de usuario y contraseña serán requeridos**</p>
               <p style='color: black;'>No es necesario responder este correo.</p>
               <p style='color: black;'>Atte,</p>
               <p style='color: black;'>CustomerCare MWL SERVICES</p>
               <p>
                 <img style='width: 160px;height: 40px;' src='${urlImg}/static/media/logo.09ed5e28.jpeg' alt='MWL'/>
               </p>`;

    obj = obj.replace(',', '');

    const data = {
      email: client[0].email,
      mesage: obj,
      subject: `DESCARGA DE DOCUMENTACION REF. ${ referenceInfo.referenceID } - ${referenceInfo.name }`,
    };

    dispatch(AduanasActions.sendEmail(userToken, data));
    dispatch(eventBtn('email'));
  }

  cancel = () => {
    const { referenceInfo, history } = this.props;

    history.push(`/reference?client=${referenceInfo.clientId}`);
  }

  toastId = null;

  notify = (message, err) => {
    const { dispatch } = this.props;
    let type = 'success';

    if (err) {
      type = 'error';
    }

    if (! toast.isActive(this.toastId)) {
      this.toastId = toast[type](message, {
        position: 'top-right',
        autoClose: 5000,
        transition: Flip,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    dispatch(eventBtn(undefined));
  };
}


export default connect(mapStateToProps)(AddDocs);
