import React from 'react';
import Header from '../components/global/header';

const AvisoPrivacidad = () => {
  return (
    <div>
      <Header/>
      <div className='download bg-MWL'>
        <div className='download-text'>
          <h1>
            El sitio no es soportado por IE, por favor usar:
            <ul>
              <li>
                Edge
              </li>
              <li>
                <a href='https://www.mozilla.org/es-MX/firefox/new/'>Firefox</a>
              </li>
              <li>
                <a href='https://www.google.com.mx/chrome/?brand=CHBD&gclid=EAIaIQobChMI44Oo4JyB5AIVjYTICh2ZCQE6EAAYASAAEgLJnPD_BwE&gclsrc=aw.ds'>Google Chrome</a>
              </li>
            </ul>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default AvisoPrivacidad;