import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MDBDataTable } from 'mdbreact';
import moment from 'moment';
import _ from 'lodash';

// Utilities
import 'react-toastify/dist/ReactToastify.min.css';
import { getCookie } from '../utilities/helpers';
import { ToastContainer, toast, Flip } from 'react-toastify';

// Acciones
import { eventBtn } from '../actions/actions';
import * as AduanasActions from '../actions/aduanas-api/aduanas-api-action-creators';
import * as ModalActionTypes from '../actions/modal-handling/modal-action-creators';

// Componentes
import Footer from '../components/global/footer';
import Header from '../components/global/header';
import Modal from '../components/global/modal';
import ModalConfirm from '../components/global/modalConfirmRefrence';

const mapStateToProps = state => ({
  referenceListAll: state.reference.referenceListAll,
  clientList: state.client.usersList,

  actionBtn: state.action.action,
  clientInfo: state.client.clientInfo,
  errAdd: state.reference.errAdd,
  errDeleted: state.deleteReference.err,
  errUpdate: state.reference.errUpdate,
  isOpen: state.modalReducer.isOpen,
  msgAdd: state.reference.msgAdd,
  msgDeleted: state.deleteReference.msg,
  msgUpdate: state.reference.msgUpdate,
  referenceDeleted: state.deleteReference.reference,
  referenceList: state.reference.referenceList,
  statusDelete: state.deleteReference.status,
  isUpdateReference: state.reference.isUpdateReference,
});

class ListReferenceAll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: getCookie('userSession'),
      clientValue: '',
      selectedClient: null,
      selectedAction: null,
      listaReferenciasTodas:[],
      clientesTodos:[],
    };
  }

  componentWillMount() {
    const { dispatch, history } = this.props;
    const { userInfo } = this.state;
    if ( userInfo === null ) {
      history.push('/');
      return;
    }
    
    const userToken = userInfo.token;
    // dispatch(AduanasActions.getClientInfo(userToken, clientID));
    dispatch(AduanasActions.listaClientes(userToken));
    dispatch(AduanasActions.listaTodasReferencias(userToken));
  }

  componentWillReceiveProps(nextProps) {
    const { referenceListAll, clientList, statusDelete, dispatch } = this.props;
    const { userInfo } = this.state;
   
    if (nextProps.referenceListAll !== referenceListAll) {
      this.setState({
        listaReferenciasTodas: nextProps.referenceListAll,
      });
    }

    if (nextProps.clientList !== clientList) {
      this.setState({
        clientesTodos: nextProps.clientList,
      });
    }

    if (statusDelete !== nextProps.statusDelete) {
      if (nextProps.statusDelete) {
        const userToken = userInfo.token;
        dispatch(AduanasActions.listaTodasReferencias(userToken));
      }
    }
  }

  render() {
    const {
      isOpen,
      dispatch,
    } = this.props;

    const {
      selectedClient,
    } = this.state;

    return (
      <Fragment>
        <Header dispatch={ dispatch }/>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnVisibilityChange={true}
          draggable={true}
          pauseOnHover={true}
        />
        {/* Same as */}
        <ToastContainer />
        <div className='loginMainSection bg-MWL'>
          { isOpen &&
            <Modal
              dispatch = { dispatch }
              component = {
                <ModalConfirm
                  value = { selectedClient }
                  popUpOpen={ this.popUpOpen }
                  dispatch={ dispatch }
                /> }
            />
          }
          <div className='addSection border-left-red bg-white'>
            <div className='d-sm-flex align-items-center justify-content-between mb-4 text-red'>
              <h3 className='loginHeader title'>Consulta de Referencias</h3>
            </div>
            <hr></hr>
            <div className='addformGroup'>
              <label className='text-dark'>
                 Año
              </label>
              <select
                className='anio'
                onChange={ (e) => this.updateTable(e) }
              >
                <option>Selecciona una Año</option>
                { this.listYearsSelect() }
              </select>
            </div>
            <div className='addDocs'>
              
              { this.listReferenceTable() }
            </div>
            <div className='btns-back'>
              <button
                onClick={ () => this.backPage() }
                className='addListBtn'>
                  Regresar
              </button>
            </div>
          </div>
        </div>
        <Footer/>
      </Fragment>
    );
  }

  addReference = (clientID) => {
    const { history } = this.props;
    history.push(`/add-reference?client=${clientID}`);
  }

  deleteReference = (action, client) => {
    const { dispatch } = this.props;

    this.setState({
      selectedAction: action,
      selectedClient: client,
    });
    dispatch(eventBtn(undefined));
    dispatch(ModalActionTypes.openModal(true));
  }

  updateTable(e) {
    const inputValue = e.target.value;
    const { referenceListAll } = this.props;
    if ( inputValue.length <= 4 ) {
      const referencesbyYear = referenceListAll.filter(items => moment(items.created_at).format('YYYY') === inputValue);
      this.setState({
        listaReferenciasTodas: referencesbyYear,
      });
    } else {
      this.setState({
        listaReferenciasTodas: referenceListAll,
      });
    }
  }

  /*   renderReferenceList() {
    const { referenceList, history } = this.props;
    const { clientID } = this.state;
    return (
      <div className='clientListBox'>
        <ul>
          {
            referenceList.map((reference, i) => {
              return (
                <div className = 'companySection' key={ i } >
                  <li className='clientGroup'>
                    <span>{ reference.referenceID }: { reference.name }</span>
                    <div className='iconGroup'>
                      <i
                        className='fas fa-plus-circle'
                        onClick = { () => { history.push(`/docs?reference=${reference._id}`); } }>
                      </i>
                      <i
                        className='fas fa-pencil-alt'
                        onClick = { () => { history.push(`/update-reference?reference=${reference._id}&client=${ clientID }`); } }>
                      </i>
                      <i
                        className='far fa-trash-alt'
                        onClick = { () => this.deleteReference('delete', reference) }>
                      </i>
                    </div>
                  </li>
                </div>
              );
            })
          }
        </ul>
      </div>
    );
  } */

  listYearsSelect() {
    const { referenceListAll } = this.props;
    const listYears = [];

    if ( referenceListAll && referenceListAll.length > 0 ) {
      referenceListAll.map((reference) => {
        const objReference = {};
        objReference.date = moment(reference.created_at).format('YYYY');
        listYears.push(objReference); 
      });
    }
    const filteredArr = _.uniqWith(listYears, _.isEqual);
    
    return (
      filteredArr.map((year, i) =>
        <option key={i}>{year.date}</option>
      )
    );
  }

  listReferenceTable() {
    const { listaReferenciasTodas, clientesTodos, userInfo } = this.state;
    const listaReferencias = [];
    if ( listaReferenciasTodas && listaReferenciasTodas.length > 0 ) {
      listaReferenciasTodas.map((reference, index) => {
        clientesTodos.forEach((cliente)=>{
          if (reference.clientId === cliente._id) {
            const objReference = {};
            objReference.id = index + 1;
            objReference.referencia = reference.referenceID;
            objReference.date = moment(reference.created_at).format('DD/MM/YYYY');
            objReference.rfc = cliente.rfc;
            objReference.razon = cliente.razonSocial;
            objReference.option = ([
              <div className='iconGroupClientTable' key={index}>
                <i
                  className={`fas fa-plus-circle ${userInfo && !userInfo.userInfo.docList && 'enableElement'}`}
                  title='Agregar Documentos'
                  onClick = { () => { this.props.history.push(`/docs?reference=${reference._id}`); } }>
                </i>
                <i
                  className={`fas fa-pencil-alt ${userInfo && !userInfo.userInfo.referenceUpdate && 'enableElement'}`}
                  title='Editar Referencia'
                  onClick = { () => { this.props.history.push(`/update-reference?reference=${reference._id}&client=${ reference.clientId }`); } }
                >
                </i>
                <i
                  className={`far fa-trash-alt ${userInfo && !userInfo.userInfo.referenceDrop && 'enableElement'}`}
                  title='Eliminar Referencia'
                  onClick = { () => this.deleteReference('delete', reference) }>
                </i>
                <i
                  className={`far fa-folder ${userInfo && !userInfo.userInfo.referenceAdd && 'enableElement'}`}
                  title='Agregar Referencia'
                  onClick = { () => this.addReference(reference.clientId) }>
                </i>
              </div>]);
            listaReferencias.push(objReference); 
          }
        });
      });
    }
    const data = {
      columns: [
        {
          label: '#',
          field: 'id',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Referencia',
          field: 'referencia',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Fecha de Creación',
          field: 'date',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'RFC',
          field: 'rfc',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Razón Social',
          field: 'razon',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Opciones',
          field: 'options',
          sort: 'disabled',
          width: '50px',
        },
      ],
      rows: listaReferencias,
    };

    if (userInfo) {
      if (!userInfo.userInfo.docList && !userInfo.userInfo.referenceUpdate && !userInfo.userInfo.referenceDrop && !userInfo.userInfo.referenceAdd) {
        data.columns.splice((data.columns.length - 1), 1);
        data.rows.map( element => delete element.option);
      }
    }

    if (listaReferenciasTodas && listaReferenciasTodas.length > 0) {
      return (
        <MDBDataTable
          bordered={true}
          data={data}
          entriesLabel='Número de Referencias'
          hover={true}
          infoLabel={['Mostrando', 'a', 'de', 'referencias']}
          paginationLabel={['Anterior', 'Siguiente']}
          responsive={true}
          searchLabel='Buscar Referencia'
          small={true}
          striped={true}
        />
      );
    }
  }

  popUpOpen = () => {
    const { dispatch } = this.props;
    dispatch(eventBtn('delete'));
  }

  backPage = () => {
    const { history } = this.props;

    history.push('/list');
  }

  toastId = null;

  notify = (message, err) => {
    let type = 'success';

    if (err) {
      type = 'error';
    }

    if (!toast.isActive(this.toastId)) {
      this.toastId = toast[type](message, {
        position: 'top-right',
        autoClose: 5000,
        transition: Flip,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
}

ListReferenceAll.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ListReferenceAll);