import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MDBDataTable } from 'mdbreact';

// Utilities
import 'react-toastify/dist/ReactToastify.min.css';
import { getCookie } from '../utilities/helpers';
import { ToastContainer, toast, Flip } from 'react-toastify';

// Acciones
import * as AduanasActions from '../actions/aduanas-api/aduanas-api-action-creators';
import * as ModalActionTypes from '../actions/modal-handling/modal-action-creators';
import { eventBtn } from '../actions/actions';

// Componentes
import Footer from '../components/global/footer';
import Header from '../components/global/header';
import Modal from '../components/global/modal';
import ModalConfirm from '../components/global/modalConfirm';

const mapStateToProps = state => ({
  actionBtn: state.action.action,
  clientDeleted: state.deleteClient.client,
  clientList: state.client.usersList,
  errAdd: state.authentication.err,
  errDeleted: state.deleteClient.err,
  errUpdate: state.updateClient.err,
  isOpen: state.modalReducer.isOpen,
  msgAdd: state.authentication.msg,
  msgDeleted: state.deleteClient.msg,
  msgUpdate: state.updateClient.msg,
  statusDelete: state.deleteClient.status,
});

class ListClients extends Component {
  constructor(props) {
    super(props);

    this.addClient = this.addClient.bind(this);

    this.state = {
      clientValue: '',
      selectedAction: null,
      selectedClient: null,
      userInfo: getCookie('userSession'),
    };
  }

  componentWillMount() {
    const { dispatch, history } = this.props;
    const { userInfo } = this.state;
    if ( userInfo === null ) {
      history.push('/');
      return;
    }

    const userToken = userInfo.token;
    dispatch(AduanasActions.listaClientes(userToken));
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, history, statusDelete, clientDeleted } = this.props;
    const { userInfo } = this.state;
    if ( userInfo === null ) {
      history.push('/');
      return;
    }

    const userToken = userInfo.token;
    if (statusDelete !== nextProps.statusDelete || clientDeleted !== nextProps.clientDeleted) {
      dispatch(AduanasActions.listaClientes(userToken));
    }

    if (nextProps.msgDeleted !== undefined && nextProps.actionBtn === 'delete') {
      setTimeout( () => {
        this.notify(nextProps.msgDeleted, nextProps.errDeleted);
      }, 500);
    }

    if (nextProps.msgUpdate !== undefined && nextProps.actionBtn === 'update') {
      setTimeout( () => {
        this.notify(nextProps.msgUpdate, nextProps.errUpdate);
      }, 500);
    }

    if (nextProps.msgAdd !== undefined && nextProps.actionBtn === 'add') {
      setTimeout( () => {
        this.notify(nextProps.msgAdd, nextProps.errAdd);
      }, 500);
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(eventBtn(undefined));
  }

  render() {
    const {
      isOpen,
      dispatch,
    } = this.props;

    const {
      selectedClient,
      userInfo,
    } = this.state;

    return (
      <Fragment>
        <Header dispatch={ dispatch }/>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnVisibilityChange={true}
          draggable={true}
          pauseOnHover={true}
        />
        {/* Same as */}
        <ToastContainer />
        <div className='loginMainSection bg-MWL'>
          { isOpen &&
            <Modal
              dispatch = { dispatch }
              component = {
                <ModalConfirm
                  value = { selectedClient }
                  popUpOpen={ this.popUpOpen }
                  action={AduanasActions.eliminaCliente}
                  dispatch={ dispatch } />
              }
            />
          }
          <div className='addSection border-left-red bg-white'>
            <div className='d-sm-flex align-items-center justify-content-between mb-4 text-red'>
              <h3 className='loginHeader title'>Listado de Clientes</h3>
              {
                userInfo && userInfo.userInfo.clientAdd ?
                  <a href='#'
                    className='d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm buttonAddSize'
                    onClick= { this.addClient }>
                    <i className='fas fa-user-plus fa-sm text-white-50'></i> Agregar Cliente</a>
                  : ''
              }
            </div>
            <div className='addDocs'>
              <hr></hr>
              { this.listClientsTable()}
            </div>
          </div>
        </div>
        <Footer/>
      </Fragment>
    );
  }

  toastId = null;

  notify = (message, err) => {
    let type = 'success';

    if (err) {
      type = 'error';
    }

    if (! toast.isActive(this.toastId)) {
      this.toastId = toast[type](message, {
        position: 'top-right',
        autoClose: 5000,
        transition: Flip,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  popUpOpen = () => {
    const { dispatch } = this.props;
    dispatch(eventBtn('delete'));
  }

  updateInputValue(evt) {
    this.setState({
      clientValue: evt.target.value,
    });
  }

  addClient() {
    const { history } = this.props;
    history.push('/add');
  }

  clientEvent(action, client) {
    const { dispatch } = this.props;

    this.setState({
      selectedAction: action,
      selectedClient: client,
    });
    dispatch(eventBtn(undefined));
    dispatch(ModalActionTypes.openModal(true));
  }

  listClientsTable() {
    const { clientList } = this.props;
    const { userInfo } = this.state;

    const listaClientes = [];

    if ( clientList && clientList.length > 0 ) {
      clientList.map((client, index) => {
        const objClient = {};
        objClient.id = index + 1;
        objClient.rfc = client.rfc;
        objClient.razonSocial = client.razonSocial;
        objClient.option = ([
          <div className='iconGroupClientTable' key={index}>
            <i
              className={`fas fa-plus-circle ${userInfo && !userInfo.userInfo.referenceList && 'enableElement'}`}
              title='Agregar Referencia'
              onClick = { () => { this.props.history.push(`/reference?client=${client._id}`); } }>
            </i>
            <i
              className={`fas fa-pencil-alt ${userInfo && !userInfo.userInfo.clientUpdate && 'enableElement'}`}
              title='Editar Cliente'
              onClick = { () => { this.props.history.push(`/update-client?client=${client._id}`); } }
            >
            </i>
            <i
              className={`far fa-trash-alt ${userInfo && !userInfo.userInfo.clientDrop && 'enableElement'}`}
              title='Eliminar Cliente'
              onClick = { () => this.clientEvent('delete', client) }>
            </i>
          </div>]);
        listaClientes.push(objClient);
      });
    }
    const data = {
      columns: [
        {
          label: '#',
          field: 'id',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'RFC',
          field: 'rfc',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Razón Social',
          field: 'razonSocial',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'Opciones',
          field: 'options',
          sort: 'disabled',
          width: 200,
        },
      ],
      rows: listaClientes,
    };

    if (userInfo) {
      if (!userInfo.userInfo.referenceList && !userInfo.userInfo.clientUpdate && !userInfo.userInfo.clientDrop) {
        data.columns.splice((data.columns.length - 1), 1);
        data.rows.map( element => delete element.option);
      }
    }

    return (
      <MDBDataTable
        bordered={true}
        data={data}
        entriesLabel='Número de Clientes'
        hover={true}
        infoLabel={['Mostrando', 'a', 'de', 'clientes']}
        paginationLabel={['Anterior', 'Siguiente']}
        responsive={true}
        searchLabel='Buscar Cliente'
        small={true}
        striped={true}
      />
    );
  }
}

ListClients.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ListClients);
