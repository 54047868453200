import React from 'react';
import Header from '../components/global/header';

const AvisoPrivacidad = () => {
  return (
    <div>
      <Header/>
      <div className='aviso-privacidad bg-MWL'>
        <div className='aviso-privacidad-text'>
          <h1>
            Aviso de privacidad simplificado
          </h1>

          <p>
            MOVILITY WORLD LOGISTICS SAS DE CV, es la responsable del uso, tratamiento y protección de los datos personales recabados a través de los trámites o servicios que usted realice por medios electrónicos, observando íntegramente para ello lo previsto en la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados (LGPDPPSO) y demás normativa que resulte aplicable.
          </p>

          <h1>
            ¿Qué datos personales se recaban y con qué finalidad?
          </h1>

          <h4>
            Los datos personales que se recaben serán utilizados para las siguientes finalidades:
          </h4>

          <p>
            Verificar y confirmar su identidad, así como la autenticidad de la información que nos proporciona, para contar con un registro que permita identificar con precisión a quien solicita el trámite o servicio.
            Acreditar los requisitos necesarios para proveer los trámites o servicios que ofrece la MOVILITY WORLD LOGISTICS SAS DE CV.
            Realizar todos los movimientos con motivo del trámite o servicio que solicita desde su ingreso hasta su baja.
            Integrar expedientes y bases de datos necesarias para el otorgamiento y operación de los servicios que se contraten, así como las obligaciones que se deriven de los mismos.
            Mantener una base histórica con fines estadísticos.
            De conformidad con el trámite o servicio que se solicita, se utilizarán de manera enunciativa más no limitativa los siguientes datos personales:
          </p>

          <p>
            Datos de identificación personal: nombre completo, denominación o razón social, domicilio, teléfono (fijo y móvil), correo electrónico, firma autógrafa, clave del Registro Federal de Contribuyentes (RFC); Clave Única de Registro de Población (CURP), Registro Único de Personas Acreditadas (RUPA), certificado de firma electrónica, estado civil, régimen matrimonial, edad, fecha de nacimiento, nacionalidad.
            Información profesional y laboral.
            Información financiera y patrimonial.
            De manera adicional, los datos personales que se recaben, podrán ser utilizados en actividades complementarias necesarias para la realización de las finalidades que se señalan; el tratamiento de datos personales será el que resulte necesario, adecuado y relevante en relación con las finalidades previstas en este Aviso de Privacidad, así como con los fines distintos que resulten compatibles o complementarios relacionados con los trámites o servicios que se proporcionan.
          </p>

          <p>
            Si no desea que sus datos personales se utilicen para estas finalidades adicionales, al momento de su registro deberá manifestar su voluntad en sentido contrario. Esto no será motivo ni condicionante para resolver sobre el trámite o servicio que se está solicitando.
          </p>

          <h1>
            Fundamento para el tratamiento de los datos personales.
          </h1>

          <p>
            MOVILITY WORLD LOGISTICS SAS DE CV dará el trato a los datos personales que se recaben a través del sistema con fundamento en los artículos 16, 18, 21, 22, 25, 26 y 65 de la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados.
          </p>

          <h1>
            Transferencia de datos personales.
          </h1>

          <p>
            Se informa que los datos personales sensibles proporcionados o solicitados no seran transferidos ni compartidos asi como la información sensible generada en su nombre y representación por MOVILITY WORLD LOGISTICS SAS DE CV
          </p>

          <p>
            tampoco sera objeto de transferencia, difuncion ni distribución, salvo lo señalado en el artículo 22 de la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados o en el caso que exista consentimiento expreso de su parte por medio escrito o por un medio de autenticación similar. Sólo tendrán acceso a esta información el titular de los datos, sus representantes legales y las personas facultadas para ello.
          </p>

          <h1>
            ¿Dónde puedo ejercer mis derechos ARCO?
          </h1>

          <p>
            Usted podrá ejercer los derechos de acceso, rectificación, cancelación u oposición (Derechos ARCO), al tratamiento de sus datos personales, mediante un escrito libre dirigido a MOVILITY WORLD LOGISTICS SAS DE CV ubicado en CALLE EMILIANO ZAPATA No. 11 COL. PEÑON DE LOS BAÑOS CP. 15520 DELEGACIÓN VENUSTIANO CARRANZA, CIUDAD DE MEXICO; correo electronico: administracion@movilityworldlogistics.com
          </p>

          <p>
            Puede consultar nuestro aviso de privacidad integral en:&nbsp;
            <a target='_blank' href='https://mwl.com.mx/home/aviso-de-privacidad.html'>
              http://movilityworldlogistics.com/aviso-de-privacidad/
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AvisoPrivacidad;