import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AddClient from '../views/AddClient';
import AddDocs from '../views/AddDocs';
import AddReference from '../views/AddReference';
import AddUser from '../views/AddUser';
import ListExtension from '../views/ListExtensions';
import AddExtension from '../views/AddExtensions';
import App from '../views/App';
import AvisoPrivasidad from '../views/AvisoPrivacidad';
import Download from '../views/Download';
import ListClients from '../views/ListClients';
import ListReference from '../views/ListReference';
import ListReferenceAll from '../views/ListReferenceAll';
import ListUsers from '../views/ListUsers';
import Login from '../views/Login';
// import NotFound from '../views/NotFound';
import UpdateClient from '../views/UpdateClient';
import UpdateReference from '../views/UpdateReference';
import UpdateUser from '../views/UpdateUser';


const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact={true} path={ '/' }>
          { window.location.pathname == '/' && window.location.replace('/home') }
        </Route>
        <Route path={ '/login' } component= { Login } />
        <Route path={ '/add' } component= { AddClient } />
        <Route path={ '/add-user' } component= { AddUser } />
        <Route path={ '/list-extension/:ref' } component= { ListExtension } />
        <Route path={ '/add-extension' } component= { AddExtension } />
        <Route path={ '/update-user' } component= { UpdateUser } />
        <Route path={ '/list-users' } component= { ListUsers } />
        <Route path={ '/update-client' } component= { UpdateClient } />
        <Route path={ '/update-reference' } component= { UpdateReference } />
        <Route path={ '/user' } component= { App } />
        <Route path={ '/docs' } component= { AddDocs } />
        <Route path={ '/list' } component= { ListClients } />
        <Route path={ '/download' } component= { Download } />
        <Route path={ '/reference' } component= { ListReference } />
        <Route path={ '/referenceAll' } component= { ListReferenceAll } />
        <Route path={ '/add-reference' } component= { AddReference } />
        <Route path={ '/aviso-de-privacidad' } component= { AvisoPrivasidad } />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
