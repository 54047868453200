import React, { Component, Fragment } from 'react';
// import * as AduanasActions from '../../actions/aduanas-api/aduanas-api-action-creators';
import Logo from '../../img/logo.jpeg';
import hechoenMexico from '../../img/hecho-en-mexico.png';

import { Link } from 'react-router-dom';
import { getCookie } from '../../utilities/helpers';

class Header extends Component {
  constructor() {
    super();
    this.state = {
      isActive : false,
      userInfo: getCookie('userSession'),
    };
  }

  componentWillMount() {
    const { userInfo } = this.state;
    if (userInfo && !userInfo.userInfo.userClick) {
      document.oncontextmenu = () => { return false; };
    }
  }

  render() {
    const { isActive } = this.state;

    if (!this.readCookie('userSession')) {
      this.logout();
    }

    return (
      <nav className='navbar navbar-expand navbar-light bg-MWL topbar static-top'>
        {/* <div className='header'> */}
        <div className='header-logo'>
          <img
            alt='logo'
            className='logo'
            src={ Logo } />
        </div>
        <div className='header-text'>
          <img
            alt='logo'
            className='logoMexico'
            src={ hechoenMexico } />
          {
            this.readCookie('userSession') && location.pathname !== '/user' && location.pathname !== '/aviso-de-privacidad' &&
            <Fragment>
              <span className='mr-2 d-lg-inline text-white-600 small'>
                <strong className='d-none d-md-inline d-lg-inline'>
                  {
                    `Bienvenido ${ this.readCookie('userSession').userInfo.name }`
                  }
                </strong>
              </span>
              <div onClick={ () => this.menu() }
                className='menuLogo'>
                <i className='fa fa-bars'></i>
              </div>
            </Fragment>
          }
          {
            isActive &&
            <Fragment>
              <div className='arrow-menu'></div>
              <div className='dropdown-menu dropdown-menu-right2 shadow animated--grow-in show' aria-labelledby='userDropdown'>
                {
                  this.readCookie('userSession').userInfo.userList &&
                  <Link to='/list-users'>
                    <div className='dropdown-item'>
                      <i className='icons fas fa-users fa-sm fa-fw mr-2 text-gray-400'></i>
                    Usuarios
                    </div>
                  </Link>
                }
                <Link
                  to='/list'
                  className='dropdown-item'
                  onClick={ this.menu } >
                  <i className='icons fas fa-user fa-sm fa-fw mr-2 text-gray-400'>
                  </i>
                      Clientes
                </Link>
                {
                  this.readCookie('userSession').userInfo.referenceList &&
                  <Link
                    to='/referenceAll'
                    className='dropdown-item'>
                    <i className='icons fas fa-clipboard-list fa-sm fa-fw mr-2 text-gray-400'></i>
                    Referencias
                  </Link>
                }
                {/* <a className='dropdown-item' href='#'>
                  <i className='fas fa-file-alt fa-sm fa-fw mr-2 text-gray-400'></i>
                  Documentos
                </a> */}
                <div className='dropdown-divider'></div>
                <div
                  onClick={ () => this.logout() }
                  className='dropdown-item cursor'
                  href='#' data-toggle='modal'
                  data-target='#logoutModal'>
                  <i className='icons fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>
                    Cerrar sesion
                </div>
              </div>
            </Fragment>
          }
        </div>
      </nav>
    );
  }

  menu = () => {
    const { isActive } = this.state;
    this.setState({
      isActive : !isActive,
    });
  }

  readCookie = name => {
    const nameEQ = name + '='; 
    const ca = document.cookie.split(';');
    
    for (let i = 0;i < ca.length;i++) {
      const c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) {
        return JSON.parse( decodeURIComponent( c.substring(nameEQ.length, c.length) ));
      }
    }
    
    return null;
  }

  logout = () => {
    // const { dispatch } = this.props;
    document.cookie = 'userSession' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    location.pathname = '/login';
  }
}

export default Header;