import React, { Component } from 'react';
import { getCookie } from '../../utilities/helpers';
import cx from 'classnames';

class Modal extends Component {
  constructor() {
    super();
    this.state = {
      userInfo: getCookie('userSession'),
    };
  }

  componentWillMount() {
    document.body.classList.add('Modal-open--body');
  }

  componentWillUnmount() {
    document.body.classList.remove('Modal-open--body');
  }

  render() {
    const { component } = this.props;

    const { userInfo } = this.state;

    const classClick = cx({
      'model-protected': userInfo && !userInfo.userInfo.userClick,
    });

    return (
      <div className='modal-pdf'>
        <div className='modal-wrapper-pdf'>
          {
            userInfo && !userInfo.userInfo.userClick &&
              <div className={classClick}></div>
          }
          <div className='modal-icon-close'>
            <div onClick={ () => this.closeModal() } className='modal-close'>
              X
            </div>
          </div>
          <div className='modal-wrapper-pdf-component'>
            { component }
          </div>
        </div>
      </div>
    );
  }
  
  closeModal = () => {
    const { closePDF } = this.props;
    closePDF();
  }
}
export default Modal;