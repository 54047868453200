import aduanasActionTypes from '../actions/aduanas-api/aduanas-api-action-types';

const initialState = {
  addExtencion: false,
  dropExtencion: false,
  errorAddExtension: false,
  errorDeleteExtension: false,
  extencionList: [],
  loadingExtencion: false,
};

const actionsMap = {
  [aduanasActionTypes.ADUANAS_LISTA_EXTENCIONES.START]: (state) => {
    return {
      ...state,
      loadingExtencion: true,
    };
  },

  [aduanasActionTypes.ADUANAS_LISTA_EXTENCIONES.SUCCESS]: (state, action) => {
    return {
      ...state,
      loadingExtencion: false,
      extencionList: action.result.extensions,
    };
  },

  [aduanasActionTypes.ADUANAS_LISTA_EXTENCIONES.FAIL]: (state) => {
    return {
      ...state,
      loadingExtencion: false,
      extencionList: [],
    };
  },

  [aduanasActionTypes.ADUANAS_ELIMINA_EXTENSION.START]: (state) => {
    return {
      ...state,
      dropExtencion: false,
      errorDeleteExtension: false,
    };
  },

  [aduanasActionTypes.ADUANAS_ELIMINA_EXTENSION.SUCCESS]: (state) => {
    return {
      ...state,
      dropExtencion: true,
    };
  },

  [aduanasActionTypes.ADUANAS_ELIMINA_EXTENSION.FAIL]: (state) => {
    return {
      ...state,
      dropExtencion: false,
      errorDeleteExtension: true,
    };
  },

  [aduanasActionTypes.ADUANAS_REGISTRA_EXTENCION.START]: (state) => {
    return {
      ...state,
      addExtencion: false,
      errorAddExtension: false,
    };
  },

  [aduanasActionTypes.ADUANAS_REGISTRA_EXTENCION.SUCCESS]: (state) => {
    return {
      ...state,
      addExtencion: true,
    };
  },

  [aduanasActionTypes.ADUANAS_REGISTRA_EXTENCION.FAIL]: (state) => {
    return {
      ...state,
      addExtencion: false,
      errorAddExtension: true,
    };
  },

};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  if (!fn) {
    return state;
  }
  return fn(state, action);
}
