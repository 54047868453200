import aduanasActionTypes from '../actions/aduanas-api/aduanas-api-action-types';

const initialState = {
  err: undefined,
  msg: undefined,
  status: undefined,
};

const actionsMap = {
  [aduanasActionTypes.ADUANAS_ACTUALIZA_CLIENTE.START]: (state) => {
    return {
      ...state,
      status: 'start',
    };
  },

  [aduanasActionTypes.ADUANAS_ACTUALIZA_CLIENTE.SUCCESS]: (state, action) => {
    return {
      ...state,
      msg: action.result.msg,
      status: action,
    };
  },

  [aduanasActionTypes.ADUANAS_ACTUALIZA_CLIENTE.FAIL]: (state, action) => {
    return {
      ...state,
      msg: action.result.msg,
      err: action.result.err,
      status: 'fail',
    };
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  if (!fn) {
    return state;
  }
  return fn(state, action);
}
