import CryptoJS from 'crypto-js';
import key from './key';

export const encrypt = data => {
  const cryptKey = CryptoJS.enc.Latin1.parse(key);
  const encrypted = CryptoJS.AES.encrypt(data, cryptKey, { mode: CryptoJS.mode.ECB }).toString();
  // const decrypted = CryptoJS.AES.decrypt(encrypted, cryptKey, { mode: CryptoJS.mode.ECB }).toString(CryptoJS.enc.Utf8);
  
  return encrypted;
};
  
export const encodeURI = text => {
  return encodeURIComponent(text);
};

export const vercionBrowser = () => {
  const ua = navigator.userAgent;
  let tem = '';
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  let navegador = '';

  if (/trident/i.test(M[1])) {
    tem =  /\brv[ :]+(\d+)/g.exec(ua) || [];
    navegador = 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if ( tem != null) navegador = tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if (( tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  navegador = M.join(' ');

  const res = navegador.split(' ');

  return res;
};