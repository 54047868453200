import aduanasActionTypes from '../actions/aduanas-api/aduanas-api-action-types';

const initialState = {
  isLogin: false,
  msg: undefined,
  err: undefined,
  isUserCreated: false,
  loadingLogin: false,
  loadingSignup: false,
  token: null,
  userInfo: {},
};

const actionsMap = {
  [aduanasActionTypes.ADUANAS_LOGIN.START]: (state) => {
    return {
      ...state,
      loadingLogin: true,
    };
  },

  [aduanasActionTypes.ADUANAS_LOGIN.SUCCESS]: (state, action) => {
    return {
      isLogin: true,
      loadingLogin: false,
      token: action.result.token,
      userInfo: action.result.user,
    };
  },

  [aduanasActionTypes.ADUANAS_LOGIN.FAIL]: (state, action) => {
    return {
      ...state,
      err: action.result.err,
      msg: action.result.message,
      isLogin: false,
      loadingLogin: false,
      token: null,
      userInfo: {},
    };
  },

  [aduanasActionTypes.ADUANAS_REGISTRA_CLIENTE.START]: (state) => {
    return {
      ...state,
      loadingSignup: true,
      isUserCreated: false,
    };
  },

  [aduanasActionTypes.ADUANAS_REGISTRA_CLIENTE.SUCCESS]: (state, action) => {
    return {
      ...state,
      isUserCreated: action.result.success,
      loadingSignup: false,
      msg: action.result.message,
    };
  },

  [aduanasActionTypes.ADUANAS_REGISTRA_CLIENTE.FAIL]: (state, action) => {
    return {
      ...state,
      err: action.result.err,
      isUserCreated: false,
      loadingSignup: false,
      msg: action.result.message,
    };
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  if (!fn) {
    return state;
  }
  return fn(state, action);
}
