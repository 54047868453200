function getCookie(key) {
  const cookie = `; ${document.cookie}`;
  const value = cookie.split(`; ${key}=`);

  if (value.length == 2) {
    return JSON.parse(value.pop().split(';').shift());
  }

  return null;
}

function setCookie(key, val, expirationDays, expirationDate) {
  let expires = '';
  if (expirationDays) {
    const date = new Date();
    date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
    expires = `; expires="${date.toGMTString()}`;
  } else if (expirationDate) {
    expires = `; expires="${expirationDate.toGMTString()}`;
  }
  document.cookie = `${key}=${val}${expires};path=/`;
}

function deleteCookie(key) {
  const now = new Date();
  document.cookie = `${key}=ok; expires=${now.toGMTString()}; path=/`;
}

export {
  getCookie,
  setCookie,
  deleteCookie,
};