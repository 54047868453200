import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getCookie } from '../utilities/helpers';
import { encrypt, encodeURI } from '../utilities/commons';
import { validate as validateInput } from '../utilities/validates';
import * as AduanasActions from '../actions/aduanas-api/aduanas-api-action-creators';
import { eventBtn } from '../actions/actions';
import cx from 'classnames';
import Footer from '../components/global/footer';
import Header from '../components/global/header';
import PropTypes from 'prop-types';
import queryString from 'query-string';

const mapStateToProps = state => ({
  clientList: state.client.usersList,
  isOpen: state.modalReducer.isOpen,
  statusDelete: state.deleteClient.status,
});

class AddClient extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(props.location.search);

    const formValues = {
      codigoPostal: '',
      password: '',
      direccion: '',
      email: '',
      razonSocial: '',
      rfc: '',
      telefono: '',
    };

    this.state = {
      clientID: queryParams && queryParams.client ? queryParams.client : null,
      clientValue: '',
      codigoPostal: true,
      password: true,
      direccion: true,
      email: true,
      razonSocial: true,
      rfc: true,
      telefono: true,
      userInfo: getCookie('userSession'),
      validate: undefined,
      validates: formValues,
    };
  }

  componentWillMount() {
    const { history, dispatch } = this.props;
    const { userInfo } = this.state;
    if ( userInfo === null ) {
      history.push('/');
      return;
    }

    const userToken = userInfo.token;
    const userType = 2;

    dispatch(AduanasActions.listaClientes(userToken, userType));
  }

  componentWillReceiveProps(nextProps) {
    const { isUserCreated, history } = this.props;
    if (isUserCreated !== nextProps.isUserCreated && nextProps.isUserCreated) {
      history.push('/list');
    }
  }

  render() {
    const {
      props: {
        dispatch,
      },
      state: {
        validate,
      },
    } = this;

    const classBtn = cx({
      'buttonLogin' : true,
      'disabled' : !validate,
    });


    return (
      <Fragment>
        <Header dispatch={ dispatch }/>
        <div className='loginMainSection bg-MWL'>
          <div className='addSection border-left-red bg-white mb-5'>
            <div className='addHeader'>
              <h3 className='loginHeader text-red title'>
                Actualizar Cliente
              </h3>
            </div>
            <div className='addBody'>
              { this.renderUpdateClientForm() }
              <div className='addFooter'>
                <button
                  onClick = { this.cancelUpdateClient }
                  className='buttonLogin'>
                  Cancelar
                </button>
                <button
                  onClick = { this.updateUser }
                  className={ classBtn } >
                  Actualizar
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </Fragment>
    );
  }

  renderUpdateClientForm() {
    const { validates, clientID } = this.state;

    const { clientList } = this.props;

    const client = clientList.filter( client => client._id === clientID );

    const formObj = [
      {
        text: 'RFC',
        type: 'text',
        key:  'rfc',
        lengthField: 13,
        errorMessage: 'El RFC debe contener 13 dígitos',
      },
      {
        text: 'Razon Social',
        type: 'text',
        key:  'razonSocial',
        errorMessage: 'La razon Social no debe de estar bacio',
      },
      {
        text: 'Email',
        type: 'email',
        key:  'email',
        errorMessage: 'El correo debe tener el siguiente formato ejemplo@ejemplo',
      },
      {
        text: 'Contraseña',
        type: 'text',
        key:  'password',
        errorMessage: 'Este campo no debe estar vacio',
      },
      {
        text: 'Telefono',
        type: 'text',
        key:  'telefono',
        lengthField: 10,
        errorMessage: 'El telefono debe contar con 10 digitos',
      },
      {
        text: 'Dirección',
        type: 'text',
        key:  'direccion',
        errorMessage: 'Este campo no debe estar vacio',
      },
      {
        text: 'Codigo Postal',
        type: 'text',
        key:  'codigoPostal',
        lengthField: 5,
        errorMessage: 'El codigo postal debe contener 10 digitos',
      },
    ];

    return (
      <div>
        <form autoComplete='off'>
          {
            formObj.map((obj, index)=> {
              const classInput = cx({
                'addformGroup-error-add' : !validates[obj.key] && validates[obj.key] !== '' && validates[obj.key] !== undefined,
              });

              if (client[0]) {
                return (
                  <div className='addformGroupUser' key={index} >
                    <label className='text-dark'>
                      { obj.text }
                    </label>
                    {
                      obj.key === 'password' &&
                      <Fragment>
                        <p className='textPass'>* Por seguridad la contraseña actual no se muestra.</p>
                        <p className='textPass'>* Este campo puede estar vacio si no desea actualizarlo.</p>
                      </Fragment>
                    }
                    <input
                      ref={obj.key}
                      className={ classInput }
                      defaultValue={ client[0][obj.key] }
                      type={ obj.type }
                      placeholder={ obj.text }
                      // onBlur={ evt => this.validateField(evt, obj.key, obj.lengthField) }
                      onChange={ () => this.validateInputs(obj.key) }
                    />
                    {
                      !this.state[obj.key] && this.state[obj.key] !== '' && this.state[obj.key] !== undefined &&
                        <span className='formGroup-error'>
                          {
                            obj.errorMessage
                          }
                        </span>
                    }
                  </div>
                );
              }
            })
          }
        </form>
      </div>
    );
  }

  validateInputs = (type) => {
    const { clientList } = this.props;
    const { clientID } = this.state;
    const client = clientList.filter( client => client._id === clientID );
    let validate = false;
    let isValidInput = true;

    if ( this.refs['email'] && this.refs['razonSocial'] && this.refs['rfc'] && this.refs['telefono'] && this.refs['direccion'] && this.refs['codigoPostal'] && this.refs['password']) {
      if ( this.refs['email'].value !== '' && this.refs['razonSocial'].value !== '' && this.refs['rfc'].value !== '' && this.refs['telefono'] !== '' && this.refs['direccion'] !== '' && this.refs['codigoPostal'] !== '') {
        if (this.refs['email'].value !== client[0]['email'] || this.refs['razonSocial'].value !== client[0]['razonSocial'] || this.refs['rfc'].value !== client[0]['rfc'] || this.refs['telefono'].value !== client[0]['telefono'] || this.refs['direccion'].value !== client[0]['direccion'] || this.refs['codigoPostal'].value !== client[0]['codigoPostal'] || this.refs['password'].value !== client[0]['password']) {
          validate = true;
        }
      }
    }

    if (this.refs[type].value === '') {
      isValidInput = false;
    }

    if (type === 'rfc') {
      if (!validateInput(this.refs['rfc'].value, 13)) {
        isValidInput = validateInput(this.refs['rfc'].value, 13);
        validate = false;
      }
    }

    if (type === 'password') {
      validate = true;
      isValidInput = true;
    }

    this.setState({
      // clientValue: evt.target.value,
      validate,
      [type]: isValidInput,
    });
  }

  updateUser = () => {
    const { clientList, dispatch, history } = this.props;
    const { clientID, userInfo } = this.state;
    const client = clientList.filter( client => client._id === clientID );
    // const userType = 2;

    const obj = {
      clientID: clientID,
      email: this.refs['email'].value,
      razonSocial: this.refs['razonSocial'].value,
      rfc: this.refs['rfc'].value,
      telefono: this.refs['telefono'].value,
      direccion: this.refs['direccion'].value,
      codigoPostal: this.refs['codigoPostal'].value,
      password: encodeURI(encrypt(this.refs['password'].value)),
    };

    dispatch(AduanasActions.actualizaCliente( userInfo.token, client._id, obj));
    history.push('/list');
    dispatch(eventBtn('update'));
  }

  cancelUpdateClient = () => {
    const { history } = this.props;
    history.push('/list');
  }
}

AddClient.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AddClient);